var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Unidade de destino","label-for":"unidade_destino","label-class":"float-left"}},[_c('info-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
            'Informe o nome da unidade de destino do material'
          ),expression:"\n            'Informe o nome da unidade de destino do material'\n          ",modifiers:{"hover":true,"right":true}}],staticClass:"tooltip-icon"}),_c('GenericSelect',{directives:[{name:"validate",rawName:"v-validate",value:({ required: !_vm.allOptional }),expression:"{ required: !allOptional }"}],ref:"selectUnidadeDestino",attrs:{"name":"id_unidade_destino_procedimento","labelKey":"nome","value":_vm.currentMaterialModel.procedimento
            && _vm.currentMaterialModel.procedimento.id_unidade_destino,"route":"unidade","disabled":_vm.isBusy,"state":_vm.validateState('id_unidade_destino_procedimento')},on:{"input":function (v) { return _vm.handleChangeProcedimento('id_unidade_destino', v); }}})],1),_c('b-form-group',{attrs:{"label":"Data do procedimento","label-for":"data_procedimento","label-class":"float-left"}},[_c('info-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
            'Informe a data em que ocorrerá o procedimento'
          ),expression:"\n            'Informe a data em que ocorrerá o procedimento'\n          ",modifiers:{"hover":true,"right":true}}],staticClass:"tooltip-icon"}),_c('b-form-datepicker',{directives:[{name:"validate",rawName:"v-validate",value:({ required: !_vm.allOptional }),expression:"{ required: !allOptional }"}],staticClass:"invision-input",attrs:{"id":"data_procedimento","placeholder":"Data","value":_vm.currentMaterialModel.procedimento
            && _vm.currentMaterialModel.procedimento.data_procedimento,"name":"data_procedimento","date-format-options":{ year: 'numeric', month: '2-digit', day: '2-digit' },"locale":"pt","disabled":_vm.isBusy,"state":_vm.validateState('data_procedimento')},on:{"input":function (v) { return _vm.handleChangeProcedimento('data_procedimento', v); }}})],1),(_vm.currentMaterialModel.id_tipo_material === 4)?_c('b-form-group',{attrs:{"label":"Aviso*","label-for":"aviso","label-class":"float-left"}},[_c('info-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
            'Informe o aviso'
          ),expression:"\n            'Informe o aviso'\n          ",modifiers:{"hover":true,"right":true}}],staticClass:"tooltip-icon"}),_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],staticClass:"invision-input",attrs:{"id":"aviso_procedimento","value":_vm.currentMaterialModel.procedimento
            && _vm.currentMaterialModel.procedimento.aviso,"autocomplete":"off","name":"aviso_procedimento","disabled":_vm.isBusy,"state":_vm.validateState('aviso_procedimento')},on:{"input":function (v) { return _vm.handleChangeProcedimento('aviso', v); }}})],1):_vm._e()],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6","xl":"6"}},[(_vm.currentMaterialModel.id_tipo_material === 4)?_c('b-form-group',{attrs:{"label":"Paciente","label-for":"paciente","label-class":"float-left"}},[_c('info-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
            'Informe o nome do paciente'
          ),expression:"\n            'Informe o nome do paciente'\n          ",modifiers:{"hover":true,"right":true}}],staticClass:"tooltip-icon"}),_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],staticClass:"invision-input",attrs:{"id":"paciente","value":_vm.currentMaterialModel.procedimento
            && _vm.currentMaterialModel.procedimento.paciente,"autocomplete":"off","name":"paciente","disabled":_vm.isBusy,"state":_vm.validateState('paciente')},on:{"input":function (v) { return _vm.handleChangeProcedimento('paciente', v); }}})],1):_c('b-form-group',{attrs:{"label":"Número de peças*","label-for":"numero_pecas","label-class":"float-left"}},[_c('info-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
            'Se o valor for alterado, será modificado o cadastro da quantidade de peças do material'
          ),expression:"\n            'Se o valor for alterado, será modificado o cadastro da quantidade de peças do material'\n          ",modifiers:{"hover":true,"right":true}}],staticClass:"tooltip-icon"}),_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true, min_value: 1}),expression:"{ required: true, min_value: 1}"}],staticClass:"invision-input",attrs:{"id":"numero_pecas","type":"number","value":_vm.currentMaterialModel.quantidade_itens,"autocomplete":"off","name":"numero_pecas","min":"1","disabled":_vm.isBusy,"state":_vm.validateState('numero_pecas')},on:{"input":_vm.handleChangeQuantidadeItens}})],1),_c('b-form-group',{attrs:{"label":"Horário do procedimento","label-for":"horario_procedimento"}},[_c('HourInput',{directives:[{name:"validate",rawName:"v-validate",value:({ required: !_vm.allOptional }),expression:"{ required: !allOptional }"}],attrs:{"name":"hour_input","isBusy":_vm.isBusy,"value":_vm.currentMaterialModel.procedimento
            && _vm.currentMaterialModel.procedimento.horario_procedimento,"state":_vm.validateState('hour_input')},on:{"input":function (v) { return _vm.handleChangeProcedimento('horario_procedimento', v); }}})],1),(_vm.currentMaterialModel.id_tipo_material === 4)?_c('b-form-group',{attrs:{"label":"Protocolo*","label-for":"protocolo","label-class":"float-left"}},[_c('info-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
              'Informe o protocolo'
            ),expression:"\n              'Informe o protocolo'\n            ",modifiers:{"hover":true,"right":true}}],staticClass:"tooltip-icon"}),_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],staticClass:"invision-input",attrs:{"id":"protocolo","value":_vm.currentMaterialModel.procedimento
            && _vm.currentMaterialModel.procedimento.protocolo,"autocomplete":"off","name":"protocolo","disabled":_vm.isBusy,"state":_vm.validateState('protocolo')},on:{"input":function (v) { return _vm.handleChangeProcedimento('protocolo', v); }}})],1):_vm._e()],1),(_vm.canTakeProcedimento)?_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6","xl":"6"}},[_c('Button',{attrs:{"text":"Copiar último procedimento","size":"lg","variant":"primary-light"},on:{"click":_vm.handleCopyLastProcedimento}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }