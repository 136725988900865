var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.currentPage === 2)?_c('div',[_c('UnidadeSetorOrigem',{attrs:{"model":_vm.model,"isBusy":_vm.isBusy,"unidadeOrigemSelf":_vm.currentUnidade.unidadeFilho.length === 0,"needResponsavel":"","config":_vm.config}}),(_vm.model.id_unidade_origem && _vm.unidadeOrigemDifUnidade)?_c('InputProtocolos',{attrs:{"model":_vm.model,"currentComponent":{data: Object.assign({}, _vm.config)},"multi":false,"modelKeyProtocolo":"numProtocolosTransferencia"}}):_vm._e()],1):(_vm.currentPage === 3)?_c('div',[_c('LeituraMateriais',{attrs:{"model":_vm.model,"isBusy":_vm.isBusy,"sendToModel":"","withoutTitle":"","detalheMaterial":"","hasIntegridade":"","hasOptions":"","config":_vm.config,"enableReimprimirEtiqueta":"","disableInitFocus":false,"setIsIntegridade":_vm.setIsIntegridade,"setIsGeraProcedimento":_vm.setIsGeraProcedimento,"beforeFinish":[
        {
          condition: _vm.materialNeedItemVerification,
          fn: _vm.checkAllCheckboxesBeforeFinish,
        } ]},scopedSlots:_vm._u([(_vm.model.numProtocolosTransferencia && _vm.model.numProtocolosTransferencia.length)?{key:"tabela",fn:function(){return [_c('TabelaMateriaisProtocolo',{attrs:{"model":_vm.model,"currentMaterialModel":_vm.currentMaterialModel,"columns":['Descrição', 'ID', 'Peças', 'Qtd'],"columnsKeys":['descricao', 'shownId', 'pecas', 'qt_material_generico'],"isBusy":_vm.isBusy,"materiaisByProtocolos":_vm.materiaisByProtocolos,"enableReimprimirEtiqueta":"","enableEditInformacoes":false,"config":_vm.config,"enableIntegridade":true}})]},proxy:true}:null,{key:"modal",fn:function(){return [_c('ModalProcesso',{attrs:{"title":_vm.modalTitle,"currentMaterialModel":_vm.currentMaterialModel,"config":_vm.config,"validationFields":['justificativa'],"setIsIntegridade":_vm.setIsIntegridade,"setIsGeraProcedimento":_vm.setIsGeraProcedimento,"beforeConfirm":[
            { condition: _vm.currentMaterialModel.conferencia_pecas, fn: function () {
              var ref = _vm.verifyAllChecked();
              var current = ref.current;
              return current
            }},
            { condition: _vm.currentMaterialModel && _vm.geraProcedimento && !_vm.isIntegridade, fn: _vm.validateQtdeMaterialProcedimento}              
          ]},scopedSlots:_vm._u([{key:"processo",fn:function(){return [(_vm.currentMaterialModel && _vm.geraProcedimento && !_vm.isIntegridade)?_c('Procedimento',{attrs:{"model":_vm.model,"currentMaterialModel":_vm.currentMaterialModel,"isBusy":_vm.isBusy,"validateState":_vm.validateState}}):(_vm.currentMaterialModel
                &&
                Object.keys(_vm.currentMaterialModel).length
                &&
                !_vm.currentMaterialModel.hasOwnProperty('tipoMaterialGenerico')
                )?_c('Integridade',{attrs:{"currentMaterialModel":_vm.currentMaterialModel,"isBusy":_vm.isBusy,"validateState":_vm.validateState,"isFromTransferencia":Boolean(_vm.model.protocoloTransferencia),"hideDesincrustante":_vm.hideModalField,"hidePerfuroCortante":_vm.hideModalField,"hideQuantidadePecas":_vm.hideModalField},scopedSlots:_vm._u([(_vm.currentMaterialModel.pecas)?{key:"pecas",fn:function(){return [_c('ItemCheck',{attrs:{"marginBottom":"20","validateState":_vm.validateState,"currentMaterialModel":_vm.currentMaterialModel,"model":_vm.model}})]},proxy:true}:null],null,true)}):_c('IntegridadeMaterialGenerico',{attrs:{"isBusy":_vm.isBusy,"validateState":_vm.validateState,"currentMaterialModel":_vm.currentMaterialModel}})]},proxy:true}])})]},proxy:true}],null,true)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }