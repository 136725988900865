export default {
  customButtonPages: [2],
  showBannerPages: [],
  underListPages: [3],
  tabelaMateriaisPage: 4,
  modelProcesso: {
    id_unidade_destino: null,
    id_setor_destino: null,
  },
  currentMaterialModelProcesso: {
    comentario: '',
    lastComentario: '',
    justificativa: '',
    ids_defeitos: [],
    quantidade_pecas: 0,
  },
  pageList: [
    {
      title: 'Leitura dos materiais',
      subItems: [
        { title: 'Dados da transfêrencia' },
      ],
    },
  ],
};
