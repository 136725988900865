import { render, staticRenderFns } from "./EtiquetasCadastroPreparo.vue?vue&type=template&id=037edb19&scoped=true&"
import script from "./EtiquetasCadastroPreparo.vue?vue&type=script&lang=js&"
export * from "./EtiquetasCadastroPreparo.vue?vue&type=script&lang=js&"
import style0 from "./EtiquetasCadastroPreparo.vue?vue&type=style&index=0&id=037edb19&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../front-end/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "037edb19",
  null
  
)

export default component.exports