<template>
  <div>
    <div v-if="currentPage === 2">
      <UnidadeSetorDestino
        :model="model"
        destinoCentral
      />
      <b-row>
        <b-col md="8">
          <Button
            text="Inserir protocolo de recebimento"
            type="outline"
            :disabled="isBusy"
            class="mr-4"
            @click="showModalTransferencia"
          />
        </b-col>
      </b-row>
      <LeituraMateriais
        :materiaisByProtocolos="materiaisByProtocolos"
        :model="model"
        :isBusy="isBusy"
        hasOptions
        sendToModel
        enableReimprimirEtiqueta
      />
    </div>
    <b-modal
      id="modalTransferencia"
      ref="modalTransferencia"
      size="md"
      title="Inserir número de protocolo de recebimento"
      hide-footer
    >
      <b-row>
        <b-col sm="24" md="12" lg="12" xl="12">
          <b-form-group
            label="Número do protocolo de recebimento*"
            label-for="num_protocolo_recebimento"
            label-class="float-left"
          >
            <info-icon
              v-b-tooltip.hover.right="
                'Insira o número do protocolo descrito no relatório de recebimento do material'
              "
              class="tooltip-icon"
            />
            <b-form-input
              id="num_protocolo_recebimento"
              name="num_protocolo_recebimento"
              v-model="numeroProtocoloRecebimento"
              autocomplete="off"
              class="invision-input"
              :disabled="isBusy"
              v-validate="{ required: true }"
              :state="validateState('num_protocolo_recebimento')"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row align-h="between" class="pt-4">
        <b-col xl="6">
          <Button
            text="Voltar"
            variant="grayscale"
            type="text"
            :disabled="isBusy"
            @click="closeModalTransferencia"
          />
        </b-col>
        <b-col xl="4">
          <Button
            text="Confirmar"
            :disabled="isBusy || !numeroProtocoloRecebimento"
            @click="handleGetMateriaisRecebimento"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import GenericApi from '../../../../../../front-end/src/services/genericRequest';
import Button from '../../../../../../front-end/src/components/Utils/Button';
import UnidadeSetorDestino from '../../Components/UnidadeSetorDestino';
import LeituraMateriais from '../../Components/LeituraMateriais'
import TabelaMateriaisProtocolo from '../../Components/TabelaMateriaisProtocolo'
import config from './config'

export default {
  inject: [
    'parentValidator',
    'validateState',
    'validateMaterial',
    'updateModel',
    'changeDisplayMessage',
    'goToPage',
    'getMateriaisByProtocolo'
  ],
  created() {
    this.$validator = this.parentValidator;
  },
  components: {
    Button,
    UnidadeSetorDestino,
    LeituraMateriais,
    TabelaMateriaisProtocolo
  },
  props: {
    materiaisByProtocolos: {
      type: Array,
      required: false,
    },
    model: {
      type: Object,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    numberOfPages: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      config,
      voucher: '',
      currentMaterialDetectionPage: '',
      readMateriaisIds: [],
      id_tipo_processo: 1,
      numeroProtocoloRecebimento: '',
    };
  },

  computed: {
    ...mapState({
      currentUnidade: (state) => state.currentUnidade,
    }),
    materiaisLength() {
      return this.model.materiais.length;
    },
    setorSelectFilters() {
      if (this.model.id_unidade_origem) {
        return { id_unidade: this.model.id_unidade_origem };
      }

      return { id_unidade: 0 };
    },
  },

  methods: {
    showModalTransferencia() {
      this.$refs.modalTransferencia.show();
    },
    closeModalTransferencia() {
      this.numeroProtocoloRecebimento= '';
      this.$refs.modalTransferencia.hide();
    },
    async handleGetMateriaisRecebimento() {
      try {
        const result = await this.$validator.validate(
          'num_protocolo_recebimento',
        );
        if (result) {
          let materiais = await this.getMateriaisByProtRecebimento(
            this.numeroProtocoloRecebimento,
          );

          materiais = materiais.filter((material) => (
            material.id_material_generico
            || (
              this.validateMaterial(material)
              && material.id_material !== this.currentMaterialModel.id_material
            )
          ));
          if (materiais.length) {
            this.changeDisplayMessage({
              message: '',
              show: false,
              id_material: null,
            });
            const materiaisFilled = materiais.map(
              (material) => ({
                ...this.currentMaterialModelProcesso,
                ...material,
              }),
            );
            this.updateModel('materiais', [
              ...this.model.materiais,
              ...materiaisFilled,
            ]);
          } else {
            this.changeDisplayMessage({
              message: `Nenhum material válido no voucher informado (${this.numeroProtocoloRecebimento})`,
              show: true,
              id_material: null,
            });
          }
        }
      } catch (error) {
        let errorMessage;
        let id_material;
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
          id_material = error.response.data.error.id_material;
        } else {
          errorMessage = 'Ocorreu um problema buscar materiais por voucher de recebimento';
        }

        this.changeDisplayMessage({
          message: errorMessage,
          show: true,
          id_material,
        });
      } finally {
        this.$refs.modalTransferencia.hide();
        this.numeroProtocoloRecebimento= '';
      }
    },
    async getMateriaisByProtRecebimento(protRecebimento) {
      const route = `protocolo/materiais`;
      const filters = {
        sub_setor: 'expurgo',
        id_unidade: this.currentUnidade.id_unidade,
        tipo_processo: this.model.tipo_processo,
        validateFluxo: true,
        protocolo: JSON.stringify(protRecebimento)
      };
      const materiais = await GenericApi.getWithoutPagination(
        { filters },
        route,
      );

      return materiais;
    },
  },
};
</script>
