<template>
  <div class="pt-100 wh-100">
    <b-row cols="12" class="qrcode-align">
      <div class="qrcode-bg p-36">
        <qrcode-vue
          :value="QrCode"
          level="H"
          class=" sqr-code qr-visible"
          renderAs="svg"
          size="100"
        ></qrcode-vue>
      </div>
    </b-row>

    <b-row class="pt-24 px-3" align-h="center">
      <b-col>
        <div class="toast-qrcode" :class="classToast">
          <span>Qr code copiado com sucesso</span>
        </div>
        <div class="d-inline-flex col-qr-code" @click="copyQrCode">
          <h4 class=" text-qr-code">{{QrCode && QrCode.slice(0,22)}}</h4>
          <copy-icon class="icon-copy ml-12 align-self-center"/>
        </div>
      </b-col>
    </b-row>

    <b-row class="pt-24 px-3">
      <b-col>
        <h4 class='text-nome-material'>{{nomeMaterial}}</h4>
      </b-col>
    </b-row>

    <b-row class="pt-12 px-3">
      <b-col cols="7">
        <h4 class="label-info-material">ID</h4>
      </b-col>
      <b-col cols="5">
        <h4 class="label-info-material">Tipo</h4>
      </b-col>
    </b-row>

    <b-row class="px-3">
      <b-col cols="7">
        <span class='text-material id'>{{material.shownId}}</span>
      </b-col>
      <b-col cols="5">
        <span class='text-material'>{{material.tipoMaterial && material.tipoMaterial.nome}}</span>
      </b-col>
    </b-row>

    <b-row class="pt-21 px-3">
      <b-col cols="7">
        <h4 class="label-info-material">Unidade</h4>
      </b-col>
      <b-col cols="5">
        <h4 class="label-info-material ">Setor</h4>
      </b-col>
    </b-row>

    <b-row class="px-3">
      <b-col cols="7">
        <span class='text-material'>
          {{ nomeUnidade }}
        </span>
      </b-col>
      <b-col cols="5">
        <span class='text-material'>
          {{material.setor ? material.setor.nome : '-'}}
        </span>
      </b-col>
    </b-row>

    <b-row class="pt-21 px-3">
      <b-col cols="7">
        <h4 class="label-info-material">Peças cadastradas</h4>
      </b-col>
      <b-col cols="5">
        <h4 class="label-info-material">Cor</h4>
      </b-col>
    </b-row>
    <b-row class="px-3">
      <b-col cols="7">
        <span class='text-material'>
          {{ material.quantidade_itens }}
        </span>
      </b-col>
      <b-col cols="5">
        <span class='text-material'>
          {{material.cor ? material.cor : '-'}}
        </span>
      </b-col>
    </b-row>

    <Printer
      buttonName="Imprimir QR Code"
      class="printer text-right"
      :stringZebra="stringZebra"
      fixedContent
      :showCalibrateButton="true"
    >
      <template #printContent class="qrCode-printer">
        <div id="qrCode" class="text-center qrCode-div printOnly">
          <qrcode-vue
            :value="QrCode"
            level="H"
            class="qr-code"
            renderAs="svg"
            size="75"
          ></qrcode-vue>
          <label class="qr-code-label mb-4 descricao-material printOnly">
            {{ labelQr }}
          </label>
          <br />
          <qrcode-vue
            :value="QrCode"
            level="H"
            class="qr-code printOnly"
            renderAs="svg"
            size="75"
          ></qrcode-vue>
          <label class="qr-code-label mb-4 descricao-material printOnly">
            {{ labelQr }}
          </label>
          <qrcode-vue
            :value="QrCode"
            level="H"
            class="qr-code printOnly"
            renderAs="svg"
            size="75"
          ></qrcode-vue>
          <label class="qr-code-label mb-4 descricao-material printOnly">
            {{ labelQr }}
          </label>
        </div>
      </template>
    </Printer>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import Printer from '@/components/Utils/Printer';
import unidadeService from '@/services/unidade';

export default {
  components: {
    QrcodeVue,
    Printer,
  },
  props: {
    material: {
      type: Object,
    },
    currentCiclo: {
      type: Object,
    },
  },
  data() {
    return {
      showToast: false,
      nomeUnidade: '',
    };
  },

  computed: {
    nomeMedico() {
      if (this.material.id_medico) {
        return `- ${this.material.medico_nome}`;
      }
      return '';
    },

    stringZebra() {
      return this.material.stringZebra;
    },

    nomeFornecedor() {
      if (this.material.id_fornecedor) {
        return `- ${this.material.fornecedor_nome}`;
      }
      return '';
    },

    descricaoMaterial() {
      return this.material.descricao || '';
    },

    QrCode() {
      return this.material.code;
    },
    nomeMaterial() {
      const descricao = this.descricaoMaterial.length < 60
        ? this.descricaoMaterial
        : this.descricaoMaterial.substr(0, 57).concat('...');

      const nomeMedico = this.nomeMedico.length < 40
        ? this.nomeMedico
        : this.nomeMedico.substr(0, 37).concat('...');

      const nomeFornecedor = this.nomeFornecedor.length < 40
        ? this.nomeFornecedor
        : this.nomeFornecedor.substr(0, 37).concat('...');
      return `${descricao} ${nomeMedico}${nomeFornecedor}`;
    },
    labelQr() {
      if (!this.material.shownId) return this.nomeMaterial;
      return `${this.material.shownId} - ${this.nomeMaterial}`;
    },

    classToast() {
      if (this.showToast) return 'd-block';
      return 'd-none';
    },
  },

  methods: {
    copyQrCode() {
      const textField = document.createElement('textarea');
      textField.innerText = this.QrCode;
      document.body.appendChild(textField);
      if (window.navigator.platform === 'iPhone') {
        textField.setSelectionRange(0, 99999);
      } else {
        textField.select();
      }
      document.execCommand('copy');
      textField.remove();

      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 2000);
    },
  },

  watch: {
    async material(v) {
      this.nomeUnidade = await unidadeService.getNomeUnidade(v);
    },
  },
};
</script>

<style lang="scss" scoped>
$neutral-color-80: #35384d;
.pt-100 {
  padding-top: 25%;
}

.wh-100 {
  width: 100%;
  height: 100%;

}

.qrcode-align {
  display: flex;
  justify-content: center;
}

.p-36 {
  padding: 36px;
}

.qrcode-bg {
  background-image: url("../../../assets/img/qrcodebg.svg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.toast-qrcode {
  position: absolute;
  top: -40px;
  background-color: rgba(126, 130, 159, 0.8);
  color: white;
  padding: 5px 8px;
  border-radius: 5px;
  font-size: 12px;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.col-qr-code{
  text-align: justify;
  background-color: #F0F1FC;
  border-radius: 5px;
  padding: 10px 16px;
  cursor: pointer;
}

.col-qr-code h4 {
  margin: 0;
  max-width: 85%;

}

.text-qr-code {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  /* Neutral / neutral-color-60 */
  color: #7E829F;
}

.icon-copy {
  color: #7E829F;
}

.ml-12 {
  margin-left: 12px;
}

.pt-24 {
  padding-top: 24px;
}

.pt-12 {
  padding-top: 12px;
}

.text-nome-material {
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  letter-spacing: 0.01em;
  /* Neutral / neutral-color-80 */
  color: #35384D;
}

h4.label-info-material {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.04em;
  text-transform: uppercase;
  /* Neutral / neutral-color-60 */
  color: #7E829F;
  margin: 0;
}

.pt-21 {
  padding-top: 21px;
}

.text-material {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  /* Neutral / neutral-color-80 */
  color: #35384D;
}

.text-material.id {
  font-weight: 600;
  color: #21CCA9;
}

.printer {
  padding-top: 36px;
}

.info-ciclo-card .invision-btn-light {
  position: absolute;
  right: 36px;
  transform: translate(0, -15%);
}

.status-row {
  padding-top: 20px;
}

.status-tag {
  background-color: rgba(46, 155, 229, 0.3);
  color: #0093e5;
  font-weight: 500;
  border-radius: 5px;
  padding: 2px;
  margin-top: 2px;
  text-align: center;
}

.status-tag-warning {
  background-color: rgba(228, 13, 31, 0.719);
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
  width: 80%;
  padding: 2px;
  margin-top: 2px;
}

.font-style-medium {
  font-weight: 600;
  margin-top: 4px;
}

.p-card {
  padding: 36px;
  margin-bottom: 20px;
}

@media print {
  .qrCode-printer {
    height: 10.4cm !important;
    width: 2.85cm !important;
    padding: 0.2cm !important;
    padding-top: 0.5cm !important;
  }

  .qrCode-div {
    // display: grid;
    // grid-template-rows: repeat(3, 300px 1fr);
    margin-bottom: 2.3cm !important;
  }

  .qr-code {
    margin-left: 0.55cm !important;
    height: 2cm !important;
    width: 2.2cm !important;
  }

  .descricao-material {
    width: 100% !important;
    font-size: 0.3cm !important;
    height: 0.6cm !important;
    margin-top: 0cm !important;
    margin-left: 0.1cm !important;
    margin-bottom: 2.9cm !important;
  }

  .qrCode-INVCXCuuid {
    width: 100% !important;
    font-size: 0.26cm !important;
    height: 1.5cm !important;
    margin-left: 0.1cm !important;
  }

  .printOnly {
    display: inline-block !important;
  }
}

.qr-code-label {
  margin: auto;
  font-style: italic;
  color: #8f8f8f;
}

.printOnly {
  display: none;
}

.card.invision-card.invision-top {
  border-top: 0 !important;
}

h5 {
  // titulo cabeçalho modal
  font-size: 18px;
  color: $neutral-color-80;
}

button {
  margin: auto;
}

.w-52 {
  width: 95%;
}

.tabela {
  background-color: white;
}

@media print {
  .no-print {
    display: none;
  }
}
</style>
