<template>
  <div>
    <b-row>
      <b-col sm="12" md="5" lg="5" xl="5">
        <BannerMaterial
          ref="bannerMaterial"
          :selectedMaterial="currentMaterialModel"
        />
      </b-col>
      <b-col sm="12" md="7" lg="7" xl="7">
        <MaterialDetection
          ref="MaterialDetection"
          :model="model"
          v-model="currentQRCode"
          :isBusy="isBusy"
          name="qrCode"
          v-validate="{
            required: !(currentMaterialModel.id_material || currentMaterialModel.id_material_generico)
          }"
          :state="validateState('qrCode')"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6" md="6" lg="6" xl="4">
        <Button
          v-if="hasIntegridade && currentMaterialModel.id_material"
          text="Não conformidade"
          variant="secondary"
          type="outline"
          @click="$emit('go-to-integridade')"
        />
      </b-col>
    </b-row>
    <b-row align-h="between" class="pt-4">
      <b-col
        v-if="materiaisLength"
        sm="3" md="2" lg="2" xl="2"
      >
        <Button
          text="Cancelar"
          variant="grayscale"
          type="text"
          :loading="isBusy"
          @click="discardForm"
        />
      </b-col>
      <b-col
        v-else
        sm="3" md="2" lg="2" xl="2"
      >
        <Button
          text="Voltar"
          variant="grayscale"
          type="text"
          :loading="isBusy"
          @click="returnToPreviousPage"
        />
      </b-col>
      <b-col sm="9" md="10" lg="10" xl="10" class="text-right">
        <Button
          v-if="
            (currentMaterialModel.id_material || currentMaterialModel.id_material_generico)
            && !currentMaterialModel.needProcedimento
            && hasNovoMaterial
          "
          text="Novo material"
          type="outline"
          :loading="isBusy"
          class="mr-4"
          @click="addNewMaterial"
        />
        <Button
          v-if="hasInserirLoteEntrada"
          text="Inserir voucher entrada"
          type="outline"
          :disabled="isBusy"
          class="mr-4"
          @click="$emit('lote-entrada-click')"
        />
        <Button
          text="Confirmar"
          :loading="isBusy"
          :disabled="isBusy || (!currentMaterialModel.id_material && !currentMaterialModel.id_material_generico)"
          @click="confirmOnMaterialDetectionPage"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Button from '../../../../../front-end/src/components/Utils/Button';
import MaterialDetection from './MaterialDetection';
import BannerMaterial from './BannerMaterial';

export default {
  components: {
    MaterialDetection,
    BannerMaterial,
    Button,
  },
  inject: [
    'parentValidator',
    'validateState',
    'goToPage',
    'confirmPage',
    'discardForm',
    'returnToPreviousPage',
    'addMaterialToModel',
  ],
  created() {
    this.$validator = this.parentValidator;
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    nextPageAfterConfirm: {
      type: Number,
      required: false,
    },
    hasIntegridade: {
      type: Boolean,
      required: false,
    },
    hasNovoMaterial: {
      type: Boolean,
      required: false,
    },
    hasInserirLoteEntrada: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      currentQRCode: '',
    };
  },
  computed: {
    materiaisLength() {
      return this.model.materiais.length;
    },
  },
  methods: {
    addNewMaterial() {
      this.addMaterialToModel();
      this.$refs.MaterialDetection.autoFocus();
      this.$refs.MaterialDetection.hasDetectedMaterial = false;
    },
    confirmOnMaterialDetectionPage() {
      if (this.validateMaterialDetectionPage()) {
        this.goToPage(this.nextPageAfterConfirm || this.currentPage + 1);
      }
    },
    validateMaterialDetectionPage() {
      if (!this.currentMaterialModel.id_material && !this.currentMaterialModel.id_material_generico) {
        swal({
          title: 'Erro',
          text: 'Nenhum material selecionado',
          icon: 'warning',
          buttons: { cancel: 'Fechar' },
        });
        return false;
      }
      return true;
    },
  },
};
</script>
