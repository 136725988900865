<template>
  <div>
    <div v-if="currentPage === 2">
      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label="Motivo do retorno*" label-for="motivo_retorno">
            <GenericSelect
              name="motivo_retorno"
              labelKey="motivo"
              route="motivoRetorno"
              ref="motivoRetorno"
              :value="model.id_motivo_retorno"
              @input="updateMotivoRetorno($event)"
              v-validate="{ required: true }"
              :state="validateState('motivo_retorno')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label="Unidade de destino*" label-for="unidade_destino">
            <GenericSelect
              name="unidade_destino"
              labelKey="nome"
              route="unidade"
              :value="model.id_unidade_destino"
              disabled
              v-validate="{ required: true }"
              :state="validateState('unidade_destino')"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <LeituraMateriais
        :isBusy="isBusy"
        :model="model"
        hideMateriaisGenerico
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import GenericSelect from '../../../../../../front-end/src/components/Form/GenericSelect';
import LeituraMateriais from '../../Components/LeituraMateriais';

export default {
  name: 'DevolucaoOpmeConsignado',
  components: {
    GenericSelect,
    LeituraMateriais,
  },
  inject: ['parentValidator', 'validateState', 'updateModel'],
  created() {
    this.$validator = this.parentValidator;
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    numberOfPages: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState(['currentUnidade']),
  },
  mounted() {
    this.updateModel('id_unidade_destino', this.currentUnidade.id_unidade);
  },
  methods: {
    updateMotivoRetorno(v) {
      const motivoRetorno = this.$refs.motivoRetorno.apiResponse.find(
        (el) => el.id_motivo_retorno === v,
      );
      this.updateModel('motivo_retorno', motivoRetorno ? motivoRetorno.motivo : null);
      this.updateModel('id_motivo_retorno', v);
    },
  },
};
</script>
