<template>
  <EtiquetaPreparo
    :relatorioData="relatorioData"
    :indexMaterial="indexMaterial"
  />
</template>

<script>
import EtiquetaPreparo from '../EntradaPreparo/Etiqueta';

export default {
  props: {
    relatorioData: {
      type: Object,
      default() {
        return {};
      },
    },
    indexMaterial: {
      type: Number,
      default: 0,
    },
  },
  components: {
    EtiquetaPreparo,
  },
};
</script>
