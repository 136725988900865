export default {
  enableMaterialRede: true,
  needProtocoloRecebimento: true,
  tipoProtocolo: 'recebimento',
  customButtonPages: [2],
  showBannerPages: [],
  underListPages: [],
  tabelaMateriaisPage: null,
  allowFetchMateriaisWithoutProtocolo: true,
  modelProcesso: {
    id_unidade_destino: null,
    id_setor_destino: null,
    numProtocolosRecebimento: [],
  },
  currentMaterialModelProcesso: {
    observacoes: '',
    lavagem_automatizada: null,
    id_tipo_lavagem_automatizada: null,
    lote: '',
    integrador_quimico: null,
    colaborador_lavagem_automatizada: '',
    id_equipamento_lavagem_automatizada: null,
    lote_detergente_lavagem_automatizada: '',
    naoFazerAutomatizada: null,
  },
  pageList: [
    {
      title: 'Leitura do material',
      subItems: [{ title: 'Formulário de Transferência' }],
    },
  ],
};
