<template>
  <div class="etiqueta pt-3">
    <b-row >
      <b-col sm="5" md="5" lg="5" xl="5">
        <div>
          <img class="pb-3" src="/logo.png" alt="" width="150px"/>
        </div>
        <div id="qrCode" class="text-center pb-4">
          <qrcode-vue :value="qrCode" level="H" class="qr-code" renderAs="svg" />
        </div>
        </b-col>
      <b-col class="pt-5" sm="7" md="7" lg="7" xl="7">
      <div v-if="etiquetaData">
        Tipo de Processamento: <b>{{ etiquetaData.tipoEsterilizacao }}</b>
        <br />
        Descrição: <b>{{ etiquetaData.descricao }}</b>
        <br />
        <span v-if="etiquetaData.lote">
          Lote do Equipamento da Esterilização: <b>{{ etiquetaData.lote }}</b>
          <br />
        </span>
        Data/Hora: {{ getDateTime(relatorioData.dateTime) }}
        <br />
        Validade: <b>{{ etiquetaData.validade }}</b>
        <br />
        Responsável: {{ etiquetaData.usuario }}
        <br />
        <div v-if="(dataHoraProcedimento || paciente || aviso)">
          --------------
          <br />
          <span v-if="dataHoraProcedimento">
            Data/Hora procedimento:
            <br />
            {{ dataHoraProcedimento }}
            <br />
          </span>
          <span v-if="paciente">
            Paciente: {{ paciente }}
            <br />
          </span>
          <span v-if="aviso">
            Aviso: {{ aviso }}
          </span>
        </div>
      </div>
      <div v-else>
        Ocorreu um problema ao gerar a etiqueta
      </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment';
import QrcodeVue from 'qrcode.vue';

export default {
  props: {
    relatorioData: {
      type: Object,
      default() {
        return {};
      },
    },
    indexMaterial: {
      type: Number,
      default: 0,
    },
  },

  components: {
    QrcodeVue,
  },

  methods: {
    getDateTime(dateTime) {
      return moment(dateTime).local().format('DD/MM/YY HH:mm');
    },
  },
  computed: {
    etiquetaData() {
      return this.relatorioData.materiais[this.indexMaterial];
    },
    qrCode() {
      return this.etiquetaData && this.etiquetaData.code;
    },
    dataHoraProcedimento() {
      if (!this.etiquetaData.procedimento || !this.etiquetaData.procedimento.data_procedimento) return '';
      const data = moment(this.etiquetaData.procedimento.data_procedimento).format('DD/MM/YYYY');
      return `${data} ${this.etiquetaData.procedimento.horario_procedimento}h`;
    },
    paciente() {
      if (!this.etiquetaData.procedimento || !this.etiquetaData.procedimento.paciente) return '';
      return this.etiquetaData.procedimento.paciente;
    },
    aviso() {
      if (!this.etiquetaData.procedimento || !this.etiquetaData.procedimento.aviso) return '';
      return this.etiquetaData.procedimento.aviso;
    },
  },
};
</script>

<style scoped>
.etiqueta {
  border-top: 10px solid #209F85;
  margin-left: 1cm !important;
}
</style>
