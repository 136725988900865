var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("pecas"),_c('div',[_c('b-row',{staticClass:"input-p"},[(_vm.hasQuantidadeItens && !_vm.hideQuantidadePecas)?_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label":"Quantidade de peças*","label-for":"quantidade_pecas"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({betweenFiftyPorcentLessAndGreater:[_vm.currentMaterialModel.quantidade_itens]}),expression:"{betweenFiftyPorcentLessAndGreater:[currentMaterialModel.quantidade_itens]}"}],staticClass:"invision-input",attrs:{"id":"quantidade_pecas","type":"number","value":_vm.currentMaterialModel.quantidade_pecas,"autocomplete":"off","name":"quantidade_pecas","min":"1","disabled":_vm.isBusy,"state":_vm.validateState('quantidade_pecas')},on:{"input":_vm.hadleChangeQntPecas}})],1)],1):_vm._e()],1),(
        _vm.currentMaterialModel.quantidade_pecas != _vm.currentMaterialModel.quantidade_atual_itens
        && _vm.currentMaterialModel.quantidade_pecas != null 
      )?_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label":"Justificativa*","label-for":"justificativa"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],staticClass:"invision-input",attrs:{"name":"justificativa","value":_vm.currentMaterialModel.justificativa,"autocomplete":"off","disabled":_vm.isBusy,"state":_vm.validateState('justificativa')},on:{"input":function (v) { return _vm.updateCurrentMaterialModel('justificativa', v); }}})],1)],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('YesOrNoRadio',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"name":"materiais_defeituosos","id":"materiais_defeituosos","label":"Presença de materiais danificados","value":_vm.currentMaterialModel.materiais_defeituosos,"disabled":_vm.isBusy,"state":_vm.validateState('materiais_defeituosos')},on:{"input":_vm.updateMateriaisDefeituosos}})],1)],1),(_vm.currentMaterialModel.materiais_defeituosos)?_c('div',{staticClass:"sub-form"},[_c('b-row',[(
          _vm.currentMaterialModel.materiais_defeituosos && _vm.hasQuantidadeItens
        )?_c('b-col',{attrs:{"sm":"24","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label":"Quantidade de peças danificadas*","label-for":"qtd_materiais_defeituosos"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:(
              ("required|between:1," + (_vm.currentMaterialModel.quantidade_itens))
            ),expression:"\n              `required|between:1,${currentMaterialModel.quantidade_itens}`\n            "}],staticClass:"invision-input",attrs:{"id":"qtd_materiais_defeituosos","type":"number","value":_vm.currentMaterialModel.quantidade_materiais_defeituosos,"autocomplete":"off","name":"qtd_materiais_defeituosos","disabled":_vm.isBusy,"min":"1","state":_vm.validateState('qtd_materiais_defeituosos')},on:{"input":_vm.handleChangeQtdDefeituoso}})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"sm":"24","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label":_vm.currentMaterialModel.quantidade_materiais_defeituosos > 1
              ? 'Descrição*'
              : 'Descrição*',"label-for":"descricao_materiais_defeituosos"}},[_vm._l((_vm.currentMaterialModel.ids_defeitos),function(item,index){return _c('GenericSelect',{directives:[{name:"validate",rawName:"v-validate",value:({
              required: _vm.currentMaterialModel.materiais_defeituosos
                ? true
                : false,
            }),expression:"{\n              required: currentMaterialModel.materiais_defeituosos\n                ? true\n                : false,\n            }"}],key:index,staticClass:"invision-select mt-1",attrs:{"name":("descricao_materiais_defeituosos-" + index),"autocomplete":"off","maxlength":"500","labelKey":"nome","value":_vm.currentMaterialModel.ids_defeitos[index],"route":"tipoDefeitoMaterial","disabled":_vm.isBusy,"state":_vm.validateState(("descricao_materiais_defeituosos-" + index))},on:{"input":function (v) { return _vm.inputCurrentMaterialArray('ids_defeitos', index, v); }}})}),(
              _vm.currentMaterialModel.quantidade_materiais_defeituosos > 1 &&
              _vm.currentMaterialModel.ids_defeitos.length <
                _vm.currentMaterialModel.quantidade_materiais_defeituosos
            )?_c('Button',{staticClass:"mt-3",attrs:{"text":"Adicionar Descrição","variant":"secondary","disabled":_vm.isBusy},on:{"click":_vm.addDescricaoDefeitos}}):_vm._e()],2)],1)],1)],1):_vm._e(),(!_vm.isFromTransferencia && !_vm.hideDesincrustante)?_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('YesOrNoRadio',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"name":"desincrustante","id":"desincrustante","label":"Presença de desincrustante","value":_vm.currentMaterialModel.desincrustante,"disabled":_vm.isBusy,"state":_vm.validateState('desincrustante')},on:{"input":function (v) { return _vm.updateCurrentMaterialModel('desincrustante', v); }}})],1)],1):_vm._e(),(!_vm.isFromTransferencia && !_vm.hidePerfuroCortante)?_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('YesOrNoRadio',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"name":"perfuro_cortante","id":"perfuro_cortante","label":"Presença de perfuro cortante","value":_vm.currentMaterialModel.perfuro_cortante,"disabled":_vm.isBusy,"state":_vm.validateState('perfuro_cortante')},on:{"input":_vm.updatePerfuroCortante}})],1)],1):_vm._e(),(!_vm.isFromTransferencia && _vm.currentMaterialModel.perfuro_cortante)?_c('div',{staticClass:"sub-form"},[_c('b-row',[(_vm.hasQuantidadeItens)?_c('b-col',{attrs:{"sm":"24","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label":"Quantidade de perfuro cortantes*","label-for":"qtd_perfuro_cortantes"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:(
              ("required|between:1," + (_vm.currentMaterialModel.quantidade_itens))
            ),expression:"\n              `required|between:1,${currentMaterialModel.quantidade_itens}`\n            "}],staticClass:"invision-input",attrs:{"id":"qtd_perfuro_cortantes","type":"number","value":_vm.currentMaterialModel.quantidade_perfuros_cortantes,"autocomplete":"off","name":"qtd_perfuro_cortantes","disabled":_vm.isBusy,"state":_vm.validateState('qtd_perfuro_cortantes')},on:{"input":_vm.handleChangeQtdPerfuro}})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label":_vm.currentMaterialModel.quantidade_perfuros_cortantes > 1
              ? 'Descrição dos perfuros cortantes*'
              : 'Descrição do perfuro cortante*',"label-for":"descricao_perfuro_cortante"}},_vm._l((_vm.currentMaterialModel.ids_perfuros_cortantes),function(item,index){return _c('GenericSelect',{directives:[{name:"validate",rawName:"v-validate",value:({
              required: true,
            }),expression:"{\n              required: true,\n            }"}],key:index,staticClass:"invision-select mt-1",attrs:{"name":("descricao_perfuro_cortante-" + index),"labelKey":"nome","value":_vm.currentMaterialModel.ids_perfuros_cortantes[index],"route":"tipoPerfuroCortante","disabled":_vm.isBusy,"state":_vm.validateState(("descricao_perfuro_cortante-" + index))},on:{"input":function (v) { return _vm.inputCurrentMaterialArray('ids_perfuros_cortantes', index, v); }}})}),1)],1)],1)],1):_vm._e(),_c('div',{staticClass:"div-foto"},[(_vm.hasNonCompliance)?_c('Button',{staticClass:"ml-4",attrs:{"text":("Tirar foto " + (_vm.numberPhotos > 0 ? ("(" + _vm.numberPhotos + ")") : '')),"variant":"grayscale","disabled":_vm.isBusy},on:{"click":_vm.openModalFoto}}):_vm._e()],1),_c('Modal',{ref:"modalPhoto",attrs:{"id":"modalPhoto","nome":"modalPhoto","title":"Editar fotos do material","hide-footer":"","no-close-on-esc":"","no-close-on-backdrop":"","size":"md","centered":""}},[_c('PhotoForm',{attrs:{"photos":_vm.photos,"buttonConfirmar":""},on:{"newPhoto":_vm.updateFoto,"confirmar":_vm.closeModalFoto}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }