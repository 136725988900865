<template>
  <Modal
    ref="modalUnidadeEmprestimo"
    title="Alterar a Unidade de Emprestimo"
    id="modalUnidadeEmprestimo"
    name="modalUnidadeEmprestimo"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    size="md"
  >
    <b-row class="p-2">
      <GenericSelect
        ref="selectUnidadeEmprestimo"
        name="selectUnidadeEmprestimo"
        id="selectUnidadeEmprestimo"
        labelKey="nome"
        v-model="id_unidade"
        :state="validateState('selectUnidadeEmprestimo')"
        v-validate="{ required: true }"
        route="unidade"
      ></GenericSelect>
    </b-row>
    <b-row lign-h="between" class="mt-4">
      <b-col>
        <CustomButton
          text="Cancelar"
          variant="grayscale"
          @click="hideModal"> </CustomButton>
      </b-col>
      <b-col>
        <CustomButton text="Confirmar" @click="updateUnidadeEmprestimo"> </CustomButton>
      </b-col>
    </b-row>
  </Modal>
</template>

<script>
import GenericApi from '@/services/genericRequest';

import CustomButton from '@/components/Utils/Button';
import Modal from '@/components/Utils/Modal';
import GenericSelect from '../../../components/Form/GenericSelect';

export default {
  components: {
    GenericSelect,
    Modal,
    CustomButton,
  },
  props: {
    id_unidade_emprestimo: {
      type: Number,
    },
    currentCiclo: {
      type: Object,
    },
    currentProcessoCiclo: {
      type: Object,
    },
  },

  inject: ['parentValidator'],
  created() {
    this.$validator = this.parentValidator;
  },

  data() {
    return {
      id_unidade: null,
    };
  },
  watch: {
    id_unidade_emprestimo(v) {
      this.id_unidade = v;
    },
  },

  methods: {
    showModal() {
      this.$refs.modalUnidadeEmprestimo.show();
    },

    hideModal() {
      this.id_unidade = this.id_unidade_emprestimo;
      this.$refs.modalUnidadeEmprestimo.hide();
    },
    async updateUnidadeEmprestimo() {
      const validateUnidade = await this.$validator.validate('selectUnidadeEmprestimo');
      if (!validateUnidade) return;
      if (this.id_unidade === this.id_unidade_emprestimo) {
        this.hideModal();
        return;
      }
      try {
        const model = {
          id_ciclo: this.currentCiclo.id_ciclo,
          tipoProcesso: 'emprestimo',
          dataToUpdate: {
            id_unidade_destino: this.id_unidade,
          },
        };
        const { id_processo_ciclo } = this.currentProcessoCiclo;
        await GenericApi.update(
          id_processo_ciclo,
          model,
          'formularios',
        );
        this.$emit('update-unidade-emprestimo', this.id_unidade);
        await swal({
          title: 'Bom trabalho!',
          text: 'A unidade de empréstimo foi alterada com sucesso',
          icon: 'success',
          button: 'Continuar',
        });
        this.hideModal();
      } catch (error) {
        let errorMessage;
        if (
          error.response
          && error.response.data
          && error.response.data.error
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        swal({
          title: 'Oops!',
          text:
            errorMessage
            || 'Ocorreu um problema ao trocar a unidade de empréstimo do material',
          icon: 'error',
          button: 'Continuar',
        });
      }
    },
    validateState(ref) {
      if (!this.$refs[ref]) return null;
      if (this.$refs[ref] && this.$refs[ref].value) return true;
      return false;
    },
  },
};
</script>
