<template>
  <div>
    <b-row>
      <b-col class="input-p" sm="12" md="6" lg="6" xl="6">
        <b-form-group
          label="Unidade de Destino*"
          label-for="unidade_destino"
        >
          <GenericSelect
            ref="selectUnidadeDestino"
            name="unidade_destino"
            labelKey="nome"
            :value="model.id_unidade_destino"
            route="unidade"
            :disabled="unidadeDisabled || isBusy"
            v-validate="{ required: true }"
            :state="validateState('unidade_destino')"
            @input="handleChangeUnidadeDestino"
          ></GenericSelect>
        </b-form-group>
      </b-col>
      <b-col class="input-p" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="Setor de Destino*" label-for="setor_destino">
          <GenericSelect
            ref="selectSetorDestino"
            name="setor_destino"
            labelKey="nome"
            :value="model.id_setor_destino"
            :customFilters="setorFilters"
            route="setor"
            :disabled="setorDisabled || isBusy"
            v-validate="{ required: true }"
            :state="validateState('setor_destino')"
            @input="(v) => updateModel('id_setor_destino', v)"
          ></GenericSelect>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="needResponsavel">
      <b-col sm="12" md="12" lg="12" xl="12">
        <b-form-group
          label="Responsável pela Retirada"
          label-for="responsavelRetirada"
          label-class="float-left"
        >
          <info-icon
            v-b-tooltip.hover.right="
              'Informe o nome do responsável pela retirada dos materiais'
            "
            class="tooltip-icon"
          />
          <b-form-input
            name="responsavelRetirada"
            :value="model.responsavelRetirada"
            autocomplete="off"
            class="invision-input input-icon"
            :disabled="isBusy"
            v-validate="{ 
              required: true, 
              ...this.getStringValidationRules(config)}"
            :state="validateState('responsavelRetirada')"
            @input="(v) => updateModel('responsavelRetirada', v)"
            @blur="() => this.config && this.config.validLengthInputText && validateInputText('responsavelRetirada')"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import unidadeService from '../../../../../front-end/src/services/unidade';
import GenericSelect from '../../../../../front-end/src/components/Form/GenericSelect';

export default {
  inject: ['getStringValidationRules','validateInputText' , 'parentValidator', 'validateState', 'updateModel', 'changeBusy'],
  created() {
    this.$validator = this.parentValidator;
  },
  components: {
    GenericSelect,
  },
  props: {
    isBusy: {
      type: Boolean,
      required: false,
      default: false
    },
    model: {
      type: Object,
      required: true,
    },
    destinoCentral: {
      type: Boolean,
      default: false,
    },
    unidadeDestinoSelf: {
      type: Boolean,
      default: false,
    },
    destinoSetorCme: {
      type: Boolean,
      default: false,
    },
    destinoSetorBloco: {
      type: Boolean,
      default: false,
    },
    needResponsavel: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
      unidadeDisabled: false,
      setorDisabled: false,
    };
  },
  computed: {
    ...mapState(['currentUnidade']),
    validationRules() {
      return this.validLengthInputText
    },
    setorFilters() {
      if (!this.model.id_unidade_destino) {
        return { id_unidade: 0 };
      }
      return { id_unidade: this.model.id_unidade_destino };
    },
  },
  mounted() {
    if (this.destinoCentral) {
      this.getUnidadeSetorDestino();
    }
    if (this.destinoSetorCme || this.destinoSetorBloco) {
      this.setorDisabled = true;
    }
    if (this.unidadeDestinoSelf) {
      this.unidadeDisabled = true;
      this.handleChangeUnidadeDestino(this.currentUnidade.id_unidade);
    }
  },
  methods: {
    async getUnidadeSetorDestino() {
      try {
        this.changeBusy();
        if (!this.currentUnidade.id_unidade_central) {
          swal({
            title: 'Erro na Unidade',
            text:
              'Você não pode realizar transferência da sua unidade selecionada',
            icon: 'warning',
            button: 'Continuar',
          });
          return;
        }
        this.updateModel('id_unidade_destino', this.currentUnidade.id_unidade_central);
        this.unidadeDisabled = true;
        this.setorDisabled = true;
        const { id_setor } = await unidadeService.getCmeByIdUnidade(this.currentUnidade.id_unidade_central);

        this.updateModel('id_setor_destino', id_setor);
      } catch (err) {
      } finally {
        this.changeBusy();
      }
    },
    async handleChangeUnidadeDestino(id_unidade_destino) {
      this.changeBusy();
      this.updateModel('id_unidade_destino', id_unidade_destino);
      if (this.destinoSetorCme) {
        const cme = await unidadeService.getCmeByIdUnidade(id_unidade_destino);
        this.updateModel('id_setor_destino', cme.id_setor);
      } else if (this.destinoSetorBloco) {
        const bloco = await unidadeService.getBlocoByIdUnidade(id_unidade_destino);
        this.updateModel('id_setor_destino', bloco.id_setor);
      }
      this.changeBusy();
    },
  },
};
</script>
