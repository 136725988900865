<template>
<div>
  <b-row>
    <b-col class="input-p" sm="12" md="6" lg="6" xl="6">
      <b-form-group label="Unidade de origem*" label-for="unidade_origem" label-class="float-left">
        <info-icon
          v-b-tooltip.hover.right="
            'Informe o nome da unidade de onde o material veio'
          "
          class="tooltip-icon"
        />
        <GenericSelect
          ref="selectUnidadeOrigem"
          name="unidade_origem"
          labelKey="nome"
          :value="model.id_unidade_origem"
          route="unidade"
          :disabled="isBusy || unidadeDisabled"
          v-validate="{ required: true }"
          :state="validateState('unidade_origem')"
          @input="handleChangeUnidadeOrigem"
        />
      </b-form-group>
    </b-col>
    <b-col sm="12" md="6" lg="6" xl="612">
      <b-form-group label="Setor de origem*" label-for="setor_origem" label-class="float-left">
        <info-icon
          v-b-tooltip.hover.right="
            'Informe o nome do setor de onde o material veio'
          "
          class="tooltip-icon"
        />
        <GenericSelect
          ref="selectSetorOrigem"
          name="setor_origem"
          labelKey="nome"
          :value="model.id_setor_origem"
          route="setor"
          :customFilters="setorSelectFilters"
          :disabled="isBusy || setorDisabled"
          v-validate="{ required: true }"
          :state="validateState('setor_origem')"
          @input="(v) => updateModel('id_setor_origem', v)"
        ></GenericSelect>
      </b-form-group>
    </b-col>
  </b-row>
  <b-row v-if="needResponsavel">
    <b-col sm="12" md="12" lg="12" xl="12">
      <b-form-group
        label="Identificação responsável setor"
        label-for="responsavelSetor"
        label-class="float-left"
      >
        <info-icon
          v-b-tooltip.hover.right="
            'Informe o nome do responsável pela realização da entrega dos materiais'
          "
          class="tooltip-icon"
        />
        <b-form-input
          name="responsavelSetor"
          :value="model.responsavel_setor"
          autocomplete="off"
          class="invision-input input-icon"
          :disabled="isBusy"
          v-validate="{ 
            required: true, 
            ...this.getStringValidationRules(config)}"
          :state="validateState('responsavelSetor')"
          @input="(v) => updateModel('responsavel_setor', v)"
          @blur="() => this.config && this.config.validLengthInputText && validateInputText('responsavelSetor')"
        ></b-form-input>
      </b-form-group>
    </b-col>
  </b-row>
</div>
</template>

<script>
import { mapState } from 'vuex';
import unidadeService from '../../../../../front-end/src/services/unidade';
import GenericSelect from '../../../../../front-end/src/components/Form/GenericSelect';

export default {
  inject: ['validateInputText', 'parentValidator', 'validateState', 'updateModel', 'changeBusy', 'getStringValidationRules'],
  created() {
    this.$validator = this.parentValidator;
  },
  components: {
    GenericSelect,
  },
  data() {
    return {
      unidadeDisabled: false,
      setorDisabled: false,
    };
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
    },
    unidadeOrigemSelf: {
      type: Boolean,
      default: false,
    },
    origemSetorCme: {
      type: Boolean,
      default: false,
    },
    needResponsavel: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      required: false,
    }

  },
  computed: {
    ...mapState(['currentUnidade']),
    validationRules() {
      return this.validLengthInputText
    },
    setorSelectFilters() {
      if (this.model.id_unidade_origem) {
        return { id_unidade: this.model.id_unidade_origem };
      }

      return { id_unidade: 0 };
    },
  },
  async mounted() {
    if (this.unidadeOrigemSelf) {
      this.updateModel('id_unidade_origem', this.currentUnidade.id_unidade);
      this.unidadeDisabled = true;
    }
    if (this.origemSetorCme) {
      if (this.currentUnidade.id_unidade_central) {
        this.handleChangeUnidadeOrigem(this.currentUnidade.id_unidade_central);
      }
      this.setorDisabled = true;
    }
  },
  methods: {
    async handleChangeUnidadeOrigem(id_unidade_origem) {
      this.changeBusy();
      this.updateModel('id_unidade_origem', id_unidade_origem);
      if (this.origemSetorCme || this.currentUnidade.id_unidade !== id_unidade_origem) {
        const cme = await unidadeService.getCmeByIdUnidade(id_unidade_origem);
        this.updateModel('id_setor_origem', cme.id_setor);
      } else if (this.model.id_setor_origem) {
        this.updateModel('id_setor_origem', null);
      }
      this.changeBusy();
    },
  },
};
</script>
