<template>
  <div>
    <b-modal
      id="modal-processo-protocolo"
      ref="modalProcesso"
      size="md"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      @show="onShow"
      @hide="$emit('hide')"
    >
      <template #modal-header>
        <x-icon class="close-icon" @click="cancelProcesso" />
      </template>
      <b-container>
        <b-row class="mb-2">
          <b-col>
            <h3 id="title">{{ title }}</h3>
          </b-col>
        </b-row>
        <div>
          <b-row v-if="VerificaJustificativa()">
            <b-col class="justificativa_alerta">
              <alert-circle-icon class="warning-text mr-4 circle-icon1" />
              <div class="justificativa_alerta_card">
                <span class="warning-text"
                  >Material registrado no Expurgo com
                  {{
                    currentMaterialModel.quantidade_pecas_recebimento_anterior ||
                    currentMaterialModel.quantidade_pecas
                  }}
                  peça(s).
                </span>
                <span class="warning-text"
                  >Justificativa:
                  {{
                    currentMaterialModel.justificativa_alerta ||
                    currentMaterialModel.justificativa
                  }}</span
                >
                <div v-if="currentMaterialModel.quantidade_pecas_repostas">
                  <span class="warning-text"
                    >Foi feita a reposição de
                    {{ currentMaterialModel.quantidade_pecas_repostas }}
                    {{
                      currentMaterialModel.quantidade_pecas_repostas > 1
                        ? "peças"
                        : "peça"
                    }}.
                  </span>
                  <span class="warning-text"
                    >Observação:
                    {{ currentMaterialModel.observacao_reposicao_pecas }}</span
                  >
                </div>
              </div>
              <x-icon class="close-x" @click="closeTopAlert" />
            </b-col>
          </b-row>
        </div>
        <slot name="processo" />
        <b-row>
          <b-col class="d-flex justify-content-end">
            <Button text="Confirmar" @click="handleFinishProcesso" />
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import Button from "../../../../../front-end/src/components/Utils/Button";

export default {
  name: "ModalProcesso",
  components: {
    Button,
  },
  inject: [
    "validatePageChange",
    "preserveCurrentMaterialModel",
    "resetCurrentMaterialModel",
    "addMaterialToModel",
    "hideModal",
    "parentValidator",
  ],
  created() {
    this.$validator = this.parentValidator;
  },
  data() {
    return {
      varCheck: false,
    };
  },

  props: {
    onShow: {
      type: Function,
      required: false,
      default: () => {},
    },
    beforeConfirm: {
      // Array of objects [{...}, {...}]
      // Every object has the following shape: {condition: Boolean, fn: Function}
      type: Array,
      required: false,
    },
    setIsIntegridade: {
      type: Function || undefined,
      required: false,
      default: () => {},
    },
    title: {
      type: String,
      required: false,
    },
    preserveData: {
      type: Boolean,
      required: false,
    },
    config: {
      type: Object,
      required: false,
    },
    currentMaterialModel: {
      type: Object,
      required: false,
    },
    validationFields: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    async handleFinishProcesso() {
      const validation = await this.validateModal();

      if (!validation) return;
      if (this.preserveData && this.config) {
        this.preserveCurrentMaterialModel(
          Object.keys(this.config.currentMaterialModelProcesso).reduce(
            (acum, key) => ({ ...acum, [key]: this.currentMaterialModel[key] }),
            {}
          )
        );
      }

      const needBeforeConfirm = this.beforeConfirm && this.beforeConfirm.length;
      if (needBeforeConfirm) {
        const functionsToExecute = this.beforeConfirm
          .filter((obj) => obj.condition)
          .map((obj) => obj.fn());

        const promises = await Promise.all(functionsToExecute);
        const result = promises.every((boolean) => boolean);

        if (!result) return;
      }

      this.addMaterialToModel();
      this.hideModal();
      this.setIsIntegridade(false);
    },
    async cancelProcesso() {
      const confirmation = await swal({
        title: "Cancelamento",
        text: "Tem certeza que deseja cancelar?",
        icon: "warning",
        buttons: { cancel: "Não", confirm: "Sim" },
      });

      if (!confirmation) return;

      this.resetCurrentMaterialModel();
      this.hideModal();
      this.setIsIntegridade(false);
      this.varCheck = false;
    },
    async validateModal() {
      if (!this.validationFields.length) return true;
      const validations = await Promise.all(
        this.validationFields.map(
          (field) => !this.veeFields[field] || this.$validator.validate(field)
        )
      );
      if (validations.some((el) => !el)) return false;
      return true;
    },
    VerificaJustificativa() {
      if (!this.varCheck) {
        if (this.currentMaterialModel.justificativa_alerta) {
          return true;
        }
      }
      return false;
    },
    closeTopAlert() {
      this.varCheck = true;
    },
  },
};
</script>

<style scoped>
.close-icon {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(-100%, -50%);

  color: #00daae;
  background-color: #edfefa;
  border-radius: 50%;
  padding: 2px;
}
#title {
  color: #35384d;
}
.close-icon {
  cursor: pointer;
}

.justificativa_alerta {
  position: relative;
  width: 544px;
  height: 81px;
  left: 0px;
  top: 0px;

  background: #ffffff;
  /* Neutral/color-neutral-40 */
  color: #e52e41;

  border: 1px solid #cfd1e6;
  border-radius: 10px;
}

.justificativa_alerta_card {
  position: absolute;
  left: 9.93%;
  right: 12.87%;
  top: 25.93%;
  bottom: 24.69%;

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* or 20px */
  /* Interactive/color-interactive-danger-40 */

  color: #e52e41;
}

.circle-icon1 {
  position: absolute;
  left: 3.5%;
  right: 12.87%;
  top: 25.93%;
  bottom: 24.69%;

  /* Interactive/color-interactive-danger-40 */
}

.close-x {
  position: absolute;
  left: 90%;
  right: 0%;
  top: 25%;
  bottom: 25%;

  color: #afb4d6;
  /* Neutral/color-neutral-50 */
}
</style>
