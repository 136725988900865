<template>
  <b-row>
    <b-col>
      <b-form-group
        class="mt-4"
        :label="`Número do protocolo de ${currentComponent.data.tipoProtocolo.toLowerCase()}${protocoloRecebimentoObrigatorio ? '*' : ''}`"
        v-if="!multi"
      >
        <b-form-input
          class="invision-input"
          id="protocolo"
          :value="model[modelKeyProtocolo] && model[modelKeyProtocolo][0]"
          @input="v => updateModel(modelKeyProtocolo, [v])"
          name="protocolo"
          :state="validateState('protocolo')"
          v-validate="{ required: protocoloRecebimentoObrigatorio }"
        />
      </b-form-group>
      <InputTags
        className="mt-4"
        v-else
        :label="`Número do(s) protocolo(s) de ${currentComponent.data.tipoProtocolo.toLowerCase()} ${protocoloRecebimentoObrigatorio ? '*' : ''}`"
        id="inputTagsProtocolosRecebimento"
        ref="inputTagsProtocolosRecebimento"
        name="inputTagsProtocolosRecebimento"
        placeholder="Inserir protocolo..."
        :value="model[modelKeyProtocolo]"
        @input="v => inputHandler(v)"
        v-validate="{ required: protocoloRecebimentoObrigatorio }"
        :state="validateState('inputTagsProtocolosRecebimento')"
      >
        <template #description>
          <div>
            Insira os números de protocolos de {{currentComponent.data.tipoProtocolo.toLowerCase()}} que deseja registrar no processo.
          </div>
        </template>
      </InputTags>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex';
import InputTags from '../../../../../front-end/src/components/Form/InputTags';
export default {
  name: 'InputProtocolos',
  inject: ['validateState', 'updateModel', 'parentValidator'],
  created() {
    this.$validator = this.parentValidator;
  },
  components: {
    InputTags,
  },
  props: {
    modelKeyProtocolo: {
      type: String,
      required: false,
      default: 'numProtocolosRecebimento'
    },
    currentComponent: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    multi: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    inputHandler(v) {
      const allUperCase = v.filter(e => typeof e === 'string').map(e => e.toUpperCase())
      this.updateModel(this.modelKeyProtocolo, allUperCase)
    }
  },
  computed: {
    ...mapState({
      protocoloRecebimentoObrigatorio(state) {
        return (
          state.configuracao
          && state.configuracao.protocoloRecebimentoObrigatorio
          && state.configuracao.protocoloRecebimentoObrigatorio === 'true'
        )
      }
    }),
  },
}
</script>
