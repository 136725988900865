export default {
  enableMaterialRede: true,
  customButtonPages: [2, 3],
  showBannerPages: [3],
  underListPages: [],
  tabelaMateriaisPage: null,
  allowFetchMateriaisWithoutProtocolo: true,
  beforeConfirmModalProcesso: true,
  ensureCurrentItemQuantity: true,
  modelProcesso: {
    numProtocolosRecebimento: [],
  },
  needProtocoloRecebimento: true,
  tipoProtocolo: 'recebimento',
  manyProtocolosRecebimento: false,
  showPicsAfterRead: true,
  currentMaterialModelProcesso: {
    observacoes: '',
    lavagem_automatizada: null,
    id_tipo_lavagem_automatizada: null,
    lote: '',
    integrador_quimico: null,
    colaborador_lavagem_automatizada: '',
    id_equipamento_lavagem_automatizada: null,
    lote_detergente_lavagem_automatizada: '',
    naoFazerAutomatizada: null,
    usuarioDuplaChecagem: null
  },
  pageList: [
    {
      title: 'Preparo',
      subItems: [{ title: 'Informações do Preparo' }],
    },
  ],
};
