<template>
  <div>
    <b-row class="input-p">
      <b-col sm="12" md="6" lg="6" xl="6" v-if="hasQuantidadeItens">
        <b-form-group label="Quantidade de peças*" label-for="quantidade_pecas">
          <b-form-input
            id="quantidade_pecas"
            type="number"
            :value="dataModel.quantidade_pecas"
            autocomplete="off"
            class="invision-input"
            name="quantidade_pecas"
            :disabled="isBusy"
            :readonly="readOnlyItemsQuantityInput"
            min="1"
            v-validate={required:!saveDataInModel,betweenFiftyPorcentLessAndGreater:[dataModel.quantidade_itens]}
            :state="validateState('quantidade_pecas')"
            @input="v => handleChangeField('quantidade_pecas', v)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" lg="6" xl="6">
        <b-form-group label="Tipo de embalagem*" label-for="tipo_embalagem">
          <GenericSelect
            name="tipo_embalagem"
            labelKey="nome"
            :value="dataModel.id_tipo_embalagem"
            route="tipoEmbalagem"
            :disabled="isBusy"
            v-validate="{ required: true }"
            :state="validateState('tipo_embalagem')"
            @input="v => handleChangeField('id_tipo_embalagem', v)"
          ></GenericSelect>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="hasJustificativa"
    >
      <b-col sm="12" md="12" lg="12" xl="12">
        <b-form-group label="Justificativa*" label-for="justificativa">
          <b-form-input
            name="justificativa"
            :value="dataModel.justificativa"
            autocomplete="off"
            class="invision-input"
            :disabled="isBusy"
            v-validate={required:true,maxLengthOfJustAndObsConcat:[dataModel.observacoes]}
            :state="validateState('justificativa')"
            @input="v => handleChangeField('justificativa', v)"
          >
          </b-form-input>
          <b-form-invalid-feedback>
            {{justError}}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="input-p">
      <b-col sm="12" md="6" lg="6" xl="12">
        <YesOrNoRadio
          name="integrador_quimico"
          id="integrador_quimico"
          label="Presença de integrador químico*"
          :value="dataModel.integrador_quimico"
          :disabled="isBusy"
          v-validate="{ required: true }"
          :state="validateState('integrador_quimico')"
          @input="v => handleChangeField('integrador_quimico', v)"
        />
      </b-col>
    </b-row>
    <div v-if="!saveDataInModel">
      <hr />
      <br />
      <b-row>
        <b-col cols="12">
          <b-form-group label="Observações" label-for="observacoes">
            <b-form-input
              name="observacoes"
              :value="dataModel.observacoes"
              autocomplete="off"
              class="invision-input"
              v-validate="{ required: false, isHigherThanMaxZplChars:[observacoes] }"
              :state="validateState('observacoes')"
              :disabled="isBusy"
              @input="v => handleChangeField('observacoes', v)"
            />
            <b-form-invalid-feedback>
              A quantidade de caracteres da justificativa superou o limite.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import GenericSelect from '../../../../../front-end/src/components/Form/GenericSelect';
import YesOrNoRadio from '../../../../../front-end/src/components/Utils/YesOrNoRadio';

export default {
  components: {
    GenericSelect,
    YesOrNoRadio,
  },
  inject: ['parentValidator', 'validateState', 'updateCurrentMaterialModel', 'updateModel'],
  created() {
    this.$validator = this.parentValidator;
  },

  props: {
    readOnlyItemsQuantityInput: {
      type: Boolean,
      required: false,
      default: () => false
    },
    currentMaterialModel: {
      type: Object,
    },
    model: {
      type: Object,
    },
    isBusy: {
      type: Boolean,
    },
    forceHasQtdItens: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    saveDataInModel() {
      if (this.model) return true;
      return false;
    },
    hasQuantidadeItens() {
      if (this.forceHasQtdItens) return true;
      if (this.dataModel.id_material_generico) return true;
      if (this.saveDataInModel) {
        if (!this.model.materiais || !this.model.materiais.length) return true;
        return Boolean(this.model.materiais[0].quantidade_itens);
      }
      return this.dataModel.camposMaterial
        && this.dataModel.camposMaterial.quantidade_itens
        && this.dataModel.camposMaterial.quantidade_itens.necessario;
    },
    hasJustificativa() {
      if (this.dataModel.id_material) {
        return (
          this.dataModel.quantidade_pecas != this.dataModel.quantidade_itens
          && this.dataModel.quantidade_pecas != null
          && this.dataModel.quantidade_itens != null
        )
      }
      if (this.dataModel.id_material_generico) {
        return (
          this.dataModel.quantidade_pecas != this.dataModel.pecas
          && this.dataModel.quantidade_pecas != null
        )
      }
      return false;
    },
    dataModel() {
      if (this.saveDataInModel) {
        return this.model;
      }
      return this.currentMaterialModel;
    },
    justError() {
      if (this.veeErrors.first('justificativa') && this.veeErrors.first('justificativa').match('is required')) {
        return 'Campo obrigatório';
      }
      return `A quantidade de caracteres da justificativa superou o limite.`;
    },
  },
  methods: {
    handleChangeField(field, value) {
      if (this.saveDataInModel) {
        this.updateModel(field, value);
      } else {
        this.updateCurrentMaterialModel(field, value);
      }
    },
  },
  watch: {
    dataModel: {
      deep: true,
      handler(dataModel) {
        if(!this.hasJustificativa && dataModel.justificativa) {
          this.handleChangeField('justificativa', '')
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.btn.secondary-contained-button {
  margin-left: 12px;
}

form .input-p {
  padding-bottom: 24px; /* define o espaçamento entre os inputs */
}

form .input-p .form-group {
  margin-bottom: 0; /* tira margin de 16 px já existente no gupo correspondente ao primeiro input do formulario */
}
</style>
