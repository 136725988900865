<template>
  <div>
    <slot name="pecas" />
    <div>
      <!-- Input Quantidade de peças-->
      <b-row class="input-p">
        <b-col sm="12" md="12" lg="12" xl="12" v-if="hasQuantidadeItens && !hideQuantidadePecas">
          <b-form-group
            label="Quantidade de peças*"
            label-for="quantidade_pecas"
          >
            <b-form-input
              id="quantidade_pecas"
              type="number"
              :value="currentMaterialModel.quantidade_pecas"
              autocomplete="off"
              class="invision-input"
              name="quantidade_pecas"
              min="1"
              :disabled="isBusy"
              v-validate={betweenFiftyPorcentLessAndGreater:[currentMaterialModel.quantidade_itens]}
              :state="validateState('quantidade_pecas')"
              @input="hadleChangeQntPecas"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- FIM - Input Quantidade de peças-->
      <b-row
        v-if="
          currentMaterialModel.quantidade_pecas != currentMaterialModel.quantidade_atual_itens
          && currentMaterialModel.quantidade_pecas != null 
        "
      >
        <b-col sm="12" md="12" lg="12" xl="12">
          <!-- nivela o tamanho dos inputs para que sejam iguais-->
          <b-form-group label="Justificativa*" label-for="justificativa">
            <b-form-input
              name="justificativa"
              :value="currentMaterialModel.justificativa"
              autocomplete="off"
              class="invision-input"
              :disabled="isBusy"
              v-validate="{ required: true }"
              :state="validateState('justificativa')"
              @input="
                (v) => updateCurrentMaterialModel('justificativa', v)
              "
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <b-row>
      <!--presença de materiais defeituosos checkbox-->
      <b-col sm="12" md="12" lg="12" xl="12">
        <YesOrNoRadio
          name="materiais_defeituosos"
          id="materiais_defeituosos"
          label="Presença de materiais danificados"
          :value="currentMaterialModel.materiais_defeituosos"
          :disabled="isBusy"
          v-validate="{ required: true }"
          :state="validateState('materiais_defeituosos')"
          @input="updateMateriaisDefeituosos"
        />
      </b-col>
    </b-row>
    <!--form interno - materiais defeituosos-->
    <div class="sub-form" v-if="currentMaterialModel.materiais_defeituosos">
      <b-row>
        <b-col
          v-if="
            currentMaterialModel.materiais_defeituosos && hasQuantidadeItens
          "
          sm="24"
          md="12"
          lg="12"
          xl="12"
        >
          <b-form-group
            label="Quantidade de peças danificadas*"
            label-for="qtd_materiais_defeituosos"
          >
            <b-form-input
              id="qtd_materiais_defeituosos"
              type="number"
              :value="currentMaterialModel.quantidade_materiais_defeituosos"
              autocomplete="off"
              class="invision-input"
              name="qtd_materiais_defeituosos"
              :disabled="isBusy"
              min="1"
              v-validate="
                `required|between:1,${currentMaterialModel.quantidade_itens}`
              "
              :state="validateState('qtd_materiais_defeituosos')"
              @input="handleChangeQtdDefeituoso"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="24" md="12" lg="12" xl="12">
          <!-- nivela o tamanho dos inputs para que sejam iguais-->
          <b-form-group
            :label="
              currentMaterialModel.quantidade_materiais_defeituosos > 1
                ? 'Descrição*'
                : 'Descrição*'
            "
            label-for="descricao_materiais_defeituosos"
          >
            <GenericSelect
              v-for="(item, index) in currentMaterialModel.ids_defeitos"
              :key="index"
              class="invision-select mt-1"
              :name="`descricao_materiais_defeituosos-${index}`"
              autocomplete="off"
              maxlength="500"
              labelKey="nome"
              :value="currentMaterialModel.ids_defeitos[index]"
              route="tipoDefeitoMaterial"
              :disabled="isBusy"
              v-validate="{
                required: currentMaterialModel.materiais_defeituosos
                  ? true
                  : false,
              }"
              :state="validateState(`descricao_materiais_defeituosos-${index}`)"
              @input="
                (v) => inputCurrentMaterialArray('ids_defeitos', index, v)
              "
            ></GenericSelect>
            <Button
              v-if="
                currentMaterialModel.quantidade_materiais_defeituosos > 1 &&
                currentMaterialModel.ids_defeitos.length <
                  currentMaterialModel.quantidade_materiais_defeituosos
              "
              text="Adicionar Descrição"
              variant="secondary"
              class="mt-3"
              @click="addDescricaoDefeitos"
              :disabled="isBusy"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <b-row v-if="!isFromTransferencia && !hideDesincrustante">
      <!--presença de desincrustante checkbox-->
      <b-col sm="12" md="12" lg="12" xl="12">
        <YesOrNoRadio
          name="desincrustante"
          id="desincrustante"
          label="Presença de desincrustante"
          :value="currentMaterialModel.desincrustante"
          :disabled="isBusy"
          v-validate="{ required: true }"
          :state="validateState('desincrustante')"
          @input="(v) => updateCurrentMaterialModel('desincrustante', v)"
        />
      </b-col>
    </b-row>
    <b-row v-if="!isFromTransferencia && !hidePerfuroCortante" >
      <!--presença de perfuro cortante checkbox-->
      <b-col sm="12" md="12" lg="12" xl="12">
        <!-- nivela o tamanho dos inputs para que sejam iguais-->
        <YesOrNoRadio
          name="perfuro_cortante"
          id="perfuro_cortante"
          label="Presença de perfuro cortante"
          :value="currentMaterialModel.perfuro_cortante"
          :disabled="isBusy"
          v-validate="{ required: true }"
          :state="validateState('perfuro_cortante')"
          @input="updatePerfuroCortante"
        />
      </b-col>
    </b-row>

    <!--form interno - perfuro cortante-->
    <div
      class="sub-form"
      v-if="!isFromTransferencia && currentMaterialModel.perfuro_cortante"
    >
      <b-row>
        <b-col sm="24" md="12" lg="12" xl="12" v-if="hasQuantidadeItens">
          <b-form-group
            label="Quantidade de perfuro cortantes*"
            label-for="qtd_perfuro_cortantes"
          >
            <b-form-input
              id="qtd_perfuro_cortantes"
              type="number"
              :value="currentMaterialModel.quantidade_perfuros_cortantes"
              autocomplete="off"
              class="invision-input"
              name="qtd_perfuro_cortantes"
              :disabled="isBusy"
              v-validate="
                `required|between:1,${currentMaterialModel.quantidade_itens}`
              "
              :state="validateState('qtd_perfuro_cortantes')"
              @input="handleChangeQtdPerfuro"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12">
          <b-form-group
            :label="
              currentMaterialModel.quantidade_perfuros_cortantes > 1
                ? 'Descrição dos perfuros cortantes*'
                : 'Descrição do perfuro cortante*'
            "
            label-for="descricao_perfuro_cortante"
          >
            <GenericSelect
              v-for="(
                item, index
              ) in currentMaterialModel.ids_perfuros_cortantes"
              :key="index"
              class="invision-select mt-1"
              :name="`descricao_perfuro_cortante-${index}`"
              labelKey="nome"
              :value="currentMaterialModel.ids_perfuros_cortantes[index]"
              route="tipoPerfuroCortante"
              :disabled="isBusy"
              v-validate="{
                required: true,
              }"
              :state="validateState(`descricao_perfuro_cortante-${index}`)"
              @input="
                (v) =>
                  inputCurrentMaterialArray('ids_perfuros_cortantes', index, v)
              "
            ></GenericSelect>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div class="div-foto">
      <Button
        v-if="hasNonCompliance"
        :text="`Tirar foto ${numberPhotos > 0 ? `(${numberPhotos})` : ''}`"
        variant="grayscale"
        class="ml-4"
        @click="openModalFoto"
        :disabled="isBusy"
      />
    </div>
    <Modal
      id="modalPhoto"
      ref="modalPhoto"
      nome="modalPhoto"
      title="Editar fotos do material"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      size="md"
      centered
    >
      <PhotoForm
        @newPhoto="updateFoto"
        :photos="photos"
        @confirmar="closeModalFoto"
        buttonConfirmar
      />
    </Modal>
  </div>
</template>

<script>
import GenericSelect from '../../../../../front-end/src/components/Form/GenericSelect';
import YesOrNoRadio from '../../../../../front-end/src/components/Utils/YesOrNoRadio';
import PhotoForm from '../../../../../front-end/src/components/WebCam/PhotoForm';
import Button from '../../../../../front-end/src/components/Utils/Button';
import Modal from '../../../../../front-end/src/components/Utils/Modal';

export default {
  components: {
    GenericSelect,
    YesOrNoRadio,
    PhotoForm,
    Button,
    Modal,
  },
  inject: ['parentValidator', 'updateCurrentMaterialModel'],
  created() {
    this.$validator = this.parentValidator;
  },
  props: {
    hideQuantidadePecas: {
      type: Boolean,
      required: false,
      default: false
    },
    hideDesincrustante: {
      type: Boolean,
      required: false,
      default: false
    },
    hidePerfuroCortante: {
      type: Boolean,
      required: false,
      default: false
    },
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    validateState: {
      type: Function,
      required: true,
    },
    isFromTransferencia: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    hasQuantidadeItens() {
      return (
        this.currentMaterialModel.camposMaterial.quantidade_itens
        && this.currentMaterialModel.camposMaterial.quantidade_itens.necessario
        && this.currentMaterialModel.camposMaterial.quantidade_itens.editavel
      );
    },
    hasNonCompliance() {
      const {
        quantidade_pecas,
        quantidade_itens,
        materiais_defeituosos,
        perfuro_cortante,
        desincrustante,
      } = this.currentMaterialModel;

      const DifferentNumberOfPecas = Number(quantidade_pecas) !== Number(quantidade_itens)
        && quantidade_pecas !== null;

      const hasMaterialDefeituoso = materiais_defeituosos && materiais_defeituosos != null;

      const hasPerfuroCortante = perfuro_cortante && perfuro_cortante != null;

      const dontHaveDesincrustante = !desincrustante && desincrustante !== null;

      const result = Boolean(
        DifferentNumberOfPecas
        || hasMaterialDefeituoso
        || hasPerfuroCortante
        || dontHaveDesincrustante,
      );

      this.$store.commit('setMsgModalAuthentication', this.msgContentModalAuthentication);
      this.$store.commit('setMultipleStepFormRequiresRelease', result);

      if(result && !this.currentMaterialModel.naoConformidade) {
        this.updateCurrentMaterialModel('naoConformidade', result)
      }

      return (
        DifferentNumberOfPecas
        || hasMaterialDefeituoso
        || hasPerfuroCortante
        || dontHaveDesincrustante
      );
    },
    numberPhotos() {
      return this.photos.length;
    },
  },

  data() {
    return {
      photos: [],
      msgContentModalAuthentication:
        'Deseja confirmar processo registrado com não conformidade?',
    };
  },

  methods: {
    handleChangeQtdDefeituoso(value) {
      if (value < this.currentMaterialModel.quantidade_materiais_defeituosos) {
        this.updateCurrentMaterialModel(
          'ids_defeitos',
          value <= 1
            ? [null]
            : this.currentMaterialModel.ids_defeitos.slice(0, value),
        );
      }
      this.updateCurrentMaterialModel(
        'quantidade_materiais_defeituosos',
        value,
      );
    },
    handleChangeQtdPerfuro(value) {
      this.updateCurrentMaterialModel(
        'quantidade_perfuros_cortantes',
        value,
      );
      const idsPerfurosLength = this.currentMaterialModel.ids_perfuros_cortantes
        .length;
      let newArray;
      if (value === idsPerfurosLength) return;
      if (value > idsPerfurosLength) {
        const newItems = new Array(value - idsPerfurosLength).fill(null);
        newArray = this.currentMaterialModel.ids_perfuros_cortantes.concat(
          newItems,
        );
      } else if (value <= 1) {
        newArray = [null];
      } else {
        newArray = this.currentMaterialModel.ids_perfuros_cortantes.slice(
          0,
          value,
        );
      }
      this.updateCurrentMaterialModel('ids_perfuros_cortantes', newArray);
    },
    addDescricaoDefeitos() {
      this.updateCurrentMaterialModel('ids_defeitos', [
        ...this.currentMaterialModel.ids_defeitos,
        null,
      ]);
      // this.currentMaterialModel.ids_defeitos.push(null);
      // this.$forceUpdate();
    },
    inputCurrentMaterialArray(prop, index, value) {
      this.updateCurrentMaterialModel(prop, [
        ...this.currentMaterialModel[prop].slice(0, index),
        value,
        ...this.currentMaterialModel[prop].slice(index + 1),
      ]);
    },

    updateMateriaisDefeituosos(hasMaterialDefeituoso) {
      if (!this.hasQuantidadeItens) {
        const n_defeitos = hasMaterialDefeituoso ? 1 : null;
        this.handleChangeQtdDefeituoso(n_defeitos);
      }
      this.updateCurrentMaterialModel(
        'materiais_defeituosos',
        hasMaterialDefeituoso,
      );
    },

    updatePerfuroCortante(hasPerfuroCortante) {
      if (!this.hasQuantidadeItens) {
        const n_perfuro = hasPerfuroCortante ? 1 : null;
        this.handleChangeQtdPerfuro(n_perfuro);
      }
      this.updateCurrentMaterialModel(
        'perfuro_cortante',
        hasPerfuroCortante,
      );
    },

    openModalFoto() {
      this.$refs.modalPhoto.show();
    },

    closeModalFoto() {
      this.$refs.modalPhoto.hide();
    },

    updateFoto(images) {
      this.photos = images;
      this.updateCurrentMaterialModel('fotos', images);
    },

    hadleChangeQntPecas(v) {
      this.updateCurrentMaterialModel('quantidade_pecas', v);
      if (this.currentMaterialModel.quantidade_itens === +v) {
        this.updateCurrentMaterialModel('justificativa', '');
      }
    },
  },
};
</script>

<style scoped>
.div-foto {
  position: absolute;
  bottom: 24px;
  left: 130px;
  z-index: 2;
}
</style>
