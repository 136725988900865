<template>
<div
    class="info-ciclo-card card invision-card borderless p-card div-container p-4"
    v-if="currentCiclo && currentCiclo.id_ciclo"
  >
    <b-table
      v-if="currentCiclo && currentCiclo.id_ciclo"
      name="tabela_ciclo"
      striped
      responsive
      :items="currentCiclo.processoCiclo"
      :fields="fields"
      ref="processos-table"
    >
      <template #cell(ordem)="{ index }"> {{ index + 1 }}</template>
      <template #cell(unidade)="form">
        {{ getUnidade(form) }}
      </template>
      <template #cell(area)="form">
        {{ form.item.subSetor ? form.item.subSetor.nome : '-'}}
      </template>
      <template #cell(processo)="form">
        {{ form.item.tipoProcesso ? form.item.tipoProcesso.nome : '-' }}
      </template>
      <template #cell(updatedAt)="form">
        {{ moment(form.item.updatedAt).format('DD/MM/YYYY HH:mm:ss') }}
      </template>
      <template #cell(acoes)="form">
        <div class="acoes">
          <GenericPrint
            v-b-tooltip.hover.top="'Imprimir'"
            class="w20px mouse align-middle mr-1"
            tipo="processo"
            :data="{ id_processo_ciclo: form.item.id_processo_ciclo, id_material }"
          />
          <PhotoView
            v-if="form.item.formulario[0].respostas.fotosProcesso"
            class="w20px mouse align-middle ml-1 mr-1"
            :options="{ ...form.item.formulario[0].respostas, tooltip: 'Fotos' }"
            color="#000"
            position="relative"
          />
          <edit-icon
            v-if="form.item.subSetor && processoHasEdit(form.item)"
            v-b-tooltip.hover.top="'Editar'"
            class="w20px mouse align-middle ml-1 mr-1"
            @click="editItem(form.item)"

          />
          <clipboard-icon
            v-if="form.item.formulario[0].respostas && form.item.formulario[0].respostas.observacoes"
            v-b-tooltip.hover.top="'Observações'"
            class="w20px mouse align-middle ml-1"
            @click="form.toggleDetails"
          />
        </div>
      </template>
      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col>
              <b>Observações: &nbsp;</b>
              {{ row.item.formulario[0].respostas.observacoes }}
            </b-col>
          </b-row>
        </b-card>
      </template>
    </b-table>
  <div class="info-ciclo-card card invision-card borderless p-card" v-else>
    <h5 class="d-flex">Este material ainda não iniciou nenhum ciclo</h5>
  </div>
</div>
</template>

<script>
import moment from 'moment';

import GenericPrint from '@/components/Utils/GenericPrint';
import PhotoView from '@/components/Utils/PhotoView';

const unidades = {
  1: 'Contorno',
  2: 'Santo Agostinho',
  3: 'Betim-Contagem',
};

export default {
  name: 'TabelaProcessos',

  components: { GenericPrint, PhotoView },

  props: {
    currentCiclo: {
      type: Object,
    },
    idProcessoCicloBusy: {
      type: Number,
    },
    id_material: {
      type: Number,
    },
  },

  data() {
    return {
      fields: [
        { key: 'ordem', label: '#' },
        { key: 'unidade', label: 'Unidade' },
        { key: 'area', label: 'Área' },
        { key: 'processo', label: 'Processo' },
        { key: 'updatedAt', label: 'Data' },
        { key: 'acoes', label: 'Ações' },
      ],
      lote: '',
      id_tipo_esterilizacao: null,
    };
  },

  methods: {
    moment(date) {
      return moment(date);
    },
    printForm(item) {
      if (!this.idProcessoCicloBusy) {
        this.$emit('print-relatorio', item);
      }
    },
    editItem(item) {
      if (
        item.subSetor.nome === 'Esterilização'
        || item.tipoProcesso.chave === 'preparoInox'
      ) this.editEsterilizacaoDesinf(item);
      else if (item.tipoProcesso.chave === 'emprestimo') this.editEmprestimo(item);
    },
    testPrint(item) {
      this.$emit('test-print', item);
    },
    editEsterilizacaoDesinf(item) {
      this.$emit('esterilizacaoDesinf', item);
    },
    editEmprestimo(item) {
      this.$emit('emprestimo', item);
    },
    getUnidade(form) {
      if (
        form.item.formulario[0]
        && form.item.formulario[0].respostas.id_unidade_destino
        && form.item.unidade
        && form.item.unidade.id_unidade !== form.item.formulario[0].respostas.id_unidade_destino
      ) {
        return `${form.item.unidade.nome} -> ${unidades[form.item.formulario[0].respostas.id_unidade_destino]}`;
      }
      return form.item.unidade ? form.item.unidade.nome : '-';
    },
    processoHasEdit(item) {
      if (item.subSetor.nome === 'Esterilização') return true;
      if (item.tipoProcesso && ['emprestimo', 'esterilizacao', 'preparoInox'].includes(item.tipoProcesso.chave)) {
        if (!this.currentCiclo.id_lote_saida) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
$neutral-color-80: #35384d;
.mouse{
  cursor: pointer;
}

.info-ciclo-card .invision-btn-light {
  position: absolute;
  right: 36px;
  transform: translate(0, -15%);
}

.font-style-medium {
  font-weight: 600;
  margin-top: 4px;
}

.p-card {
  padding: 36px;
  margin-bottom: 20px;
}

@media print {
  .qrCode-printer {
    height: 10.4cm !important;
    width: 2.85cm !important;
    padding: 0.2cm !important;
    padding-top: 0.5cm !important;
  }

  .qrCode-div {
    // display: grid;
    // grid-template-rows: repeat(3, 300px 1fr);
    margin-bottom: 2.3cm !important;
  }

  .qr-code {
    margin-left: 0.55cm !important;
    height: 2cm !important;
    width: 2.2cm !important;
  }

  .descricao-material {
    width: 100% !important;
    font-size: 0.3cm !important;
    height: 0.6cm !important;
    margin-top: 0cm !important;
    margin-left: 0.1cm !important;
    margin-bottom: 2.9cm !important;
  }

  .qrCode-INVCXCuuid {
    width: 100% !important;
    font-size: 0.26cm !important;
    height: 1.5cm !important;
    margin-left: 0.1cm !important;
  }

  .printOnly {
    display: inline-block !important;
  }
}

.qr-code-label {
  margin: auto;
  font-style: italic;
  color: #8f8f8f;
}

.printOnly {
  display: none;
}

.card.invision-card.invision-top {
  border-top: 0 !important;
}

h5 {
  // titulo cabeçalho modal
  font-size: 18px;
  color: $neutral-color-80;
}

button {
  margin: auto;
}

.w-52 {
  width: 95%;
}

.acoes {
  display: flex;
  width: 6.5rem;
  align-items: center;

}

@media screen and (max-width: 991px) {
  .tabela {
    margin-top: 20px;
  }
}

 @media print {
          .no-print {
              display: none;
            }
        }
</style>
