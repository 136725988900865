<template>
  <div>
    <div v-if="currentPage === 2">
      <b-row class="mb-2">
        <b-col>
          <div>
            Solicite um documento de identificação com foto do responsável pela
            retirada de materiais OPME e preencha os campos a seguir.
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Nome*">
            <b-form-input
              id="inputNomeRetirada"
              name="nomeRetiradaMaterialMedico"
              class="invision-input"
              :value="model.nomeRetiradaMaterialMedico"
              autocomplete="off"
              v-validate="{ required: true }"
              :state="validateState('nomeRetiradaMaterialMedico')"
              @input="(v) => updateModel('nomeRetiradaMaterialMedico', v)"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-row>
          <b-col>
            <b-form-group label="RG*">
              <b-form-input
                id="inputRG"
                name="rgRetiradaMaterialMedico"
                class="invision-input"
                :value="model.rgRetiradaMaterialMedico"
                autocomplete="off"
                v-validate="{ required: true }"
                :state="validateState('rgRetiradaMaterialMedico')"
                @input="(v) => updateModel('rgRetiradaMaterialMedico', v)"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-row>
      <LeituraMateriais
        :model="model"
        :isBusy="isBusy"
        :currentMaterialModel="currentMaterialModel"
        :config="config"
        :sendToModel="false"
      >
        <template #modal>
          <ModalProcesso
            title="Quantidade de peças"
            :currentMaterialModel="currentMaterialModel"
            :config="config"
            :validationFields="['quantidade_pecas']"
            :onShow="setQuantidadePecas"
          >
            <template #processo>
              <b-form-group
                label="Quantidade de peças*"
                label-for="quantidade_pecas"
              >
                <b-form-input
                  id="quantidade_pecas"
                  ref="quantidade_pecas"
                  type="number"
                  min="1"
                  :value="currentMaterialModel.quantidade_pecas"
                  autocomplete="off"
                  class="invision-input"
                  name="quantidade_pecas"
                  :disabled="isBusy"
                  v-validate="{
                    betweenFiftyPorcentLessAndGreater: [
                      currentMaterialModel.quantidade_itens,
                    ],
                  }"
                  :state="validateState('quantidade_pecas')"
                  @input="
                    (v) => updateCurrentMaterialModel('quantidade_pecas', v)
                  "
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Justificativa*"
                label-for="justificativa"
                v-if="
                  currentMaterialModel.quantidade_pecas != null &&
                  currentMaterialModel.quantidade_pecas !=
                    currentMaterialModel.quantidade_itens
                "
              >
                <b-form-input
                  name="justificativa"
                  :value="currentMaterialModel.justificativa"
                  autocomplete="off"
                  class="invision-input"
                  :disabled="isBusy"
                  v-validate="{ required: true }"
                  :state="validateState('justificativa')"
                  @input="(v) => updateCurrentMaterialModel('justificativa', v)"
                >
                </b-form-input>
              </b-form-group>
            </template>
          </ModalProcesso>
        </template>
      </LeituraMateriais>
    </div>
  </div>
</template>

<script>
import LeituraMateriais from "../../Components/LeituraMateriais";
import ModalProcesso from "../../Components/ModalProcesso";
import config from "./config";

export default {
  name: "DevolucaoMedico",
  components: {
    LeituraMateriais,
    ModalProcesso,
  },
  inject: [
    "parentValidator",
    "validateState",
    "updateModel",
    "updateCurrentMaterialModel",
  ],
  created() {
    this.$validator = this.parentValidator;
  },
  data() {
    return {
      config,
    };
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    numberOfPages: {
      type: Number,
      required: true,
    },
  },
  methods: {
    setQuantidadePecas() {
      this.updateCurrentMaterialModel('quantidade_pecas', this.currentMaterialModel.quantidade_itens)
    }
  }
};
</script>
