<template>
<div>
  <div class="etiqueta pt-3">
    <b-row>
      <b-col sm="5" md="5" lg="5" xl="5">
        <div>
          <img class="pb-3" src="/logo.png" alt="" width="150px"/>
        </div>
        <div id="qrCode" class="text-center pb-4">
          <qrcode-vue
            :value="etiquetaData.code"
            level="H"
            class="qr-code"
            renderAs="svg"
          ></qrcode-vue>
        </div>
      </b-col>
      <b-col class="pt-1" id="box-bloco-circulante" sm="7" md="7" lg="7" xl="7">
        <div v-if="etiquetaData">
          Descrição do material:
          <b>{{ etiquetaData.descricao }}
          </b>
          <br />
          <span v-if="etiquetaData.shownId">
            ID:
            <b>{{ etiquetaData.shownId }}</b>
            <br />
          </span>
          <span v-if="etiquetaData.tipoProcessamento">
            Processamento:
            <b>{{ etiquetaData.tipoProcessamento }}</b>
            <br />
          </span>
          <span v-if="etiquetaData.quantidade_itens">
            Quantidade de peças:
            <b>{{ etiquetaData.quantidade_itens }}</b>
            <br />
            Data/Hora:
            {{ getDateTime(relatorioData.dateTime) }}
          </span>
        </div>
        <div v-else>
          Ocorreu um problema ao gerar a etiqueta
        </div>
      </b-col>
    </b-row>
    <b-row id="box-bloco-informacoes">
      <div id="div-title-bloco-informacoes">
          <span v-if="etiquetaData.justificativa && etiquetaData.observacoes">
            Just / Obs :
            <b>{{ `${etiquetaData.justificativa} / ${etiquetaData.observacoes}` }}</b>
            <br />
          </span>
          <span v-else-if="etiquetaData.justificativa">
            Justificativa:
            <b>{{ etiquetaData.justificativa }}</b>
            <br />
          </span>
          <span v-else-if="etiquetaData.observacoes">
            Observações:
            <b>{{ etiquetaData.observacoes }}</b>
            <br />
          </span>
          <span v-if="etiquetaData.fornecedor">
            Fornecedor:
            <b>{{ etiquetaData.fornecedor }}</b>
            <br />
          </span>
          <span v-if="etiquetaData.setor && etiquetaData.unidade">
            Pertence a:
            <b>{{ `${etiquetaData.unidade} - ${etiquetaData.setor}` }}</b>
            <br />
          </span>
          <span v-if="etiquetaData.setorOrigem && etiquetaData.unidadeOrigem">
            Entregue por:
            <b>{{ `${etiquetaData.unidadeOrigem} - ${etiquetaData.setorOrigem}` }}</b>
            <br />
          </span>
          Responsável :
          {{ etiquetaData.usuario }}
          <br />
        </div>
    </b-row>
  </div>
</div>
</template>

<script>
import moment from 'moment';
import QrcodeVue from 'qrcode.vue';

export default {
  props: {
    relatorioData: {
      type: Object,
      default() {
        return {};
      },
    },
    indexMaterial: {
      type: Number,
      default: 0,
    },
  },

  components: {
    QrcodeVue,
  },

  computed: {
    etiquetaData() {
      return this.relatorioData.materiais[this.indexMaterial];
    },
  },

  methods: {
    getDateTime(dateTime) {
      return moment(dateTime).local().format('DD/MM/YY HH:mm');
    },
  },
};
</script>

<style scoped>
.etiqueta {
  border-top: 10px solid #209f85;
  margin-left: 1cm !important;
}
#box-bloco-informacoes {
  display: flex;
  width: 100%;
  justify-content: left;
  margin-bottom: 20px;
}

#div-title-bloco-informacoes {
  width: 100%;
  justify-content: left;
  margin-top: 10px;
  margin-bottom: 20px;
}

.sub-item-l {
  display: flex;
  width: 50%;
}
.l-BC {
  display: flex;
  margin-bottom: 10px;
}
</style>
