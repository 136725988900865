<template>
  <div>
    <div v-if="currentPage === 2">
      <UnidadeSetorDestino
        :model="model"
        :unidadeDestinoSelf="currentUnidade.unidadeFilho.length === 0"
        needResponsavel
        :config="config"
        :isBusy="isBusy"
      />
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12">
          <b-form-checkbox
            v-model="usingVoucherRecebimento"
            @change="updateHandleKeyUpEnter"
            :disabled="isBusy || !model.id_setor_destino"
          >
            Liberar materiais utilizando o protocolo de recebimento entregue
            para o setor no recebimento do expurgo (voucher).
          </b-form-checkbox>
          <b-form-checkbox 
            v-if="usingVoucherRecebimento"
            v-model="enableInputTags"
            class="manually-checkbox"
          >
            Habilitar campo para preenchimento manual.
          </b-form-checkbox>
        </b-col>
      </b-row>
      <br />
      <b-row v-if="usingVoucherRecebimento">
        <b-spinner class="spinner" v-if="isBusy" />
        <b-col sm="12" md="12" lg="12" xl="12" v-else>
          <div v-if="model.numProtocolosPendentes && enableSelectInput">
            <h6>Lista de vouchers de recebimento</h6>
            <b-form-select
              :value="selected"
              @input="handleSelectChange"
              :options="
                model.numProtocolosPendentes.map((voucher) => ({
                  value: voucher,
                  text: voucher,
                }))
              "
            >
            </b-form-select>
          </div>
          <div v-else-if="!enableSelectInput && enableInputTags">
            <InputTags
              label="Número do(s) voucher(s) de recebimento"
              id="inputTagsVouchers"
              ref="inputTagsVouchers"
              name="inputTagsVouchers"
              placeholder="Inserir voucher..."
              :value="model.numProtocolosRecebimento"
              @input="handleVouchersChange"
              :isBusy="isBusy"
              :state="validateState('inputTagsVouchers')"
              v-validate="'required'"
            >
              <template #description>
                <div>
                  O voucher inserido deve começar com 'ER', que sinaliza
                  recebimento. As teclas ENTER, vírgula e ESPAÇO inserem um novo
                  voucher. Você pode inserir quantos quiser, desde que sejam
                  válidos.
                </div>
              </template>
            </InputTags>
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-else-if="currentPage === 3">
      <LeituraMateriais
        :model="model"
        :isBusy="isBusy"
        withoutTitle
        detalheMaterial
        :config="config"
        :disableInitFocus="false"
        enableItemTooltip
      >
        <template
          #tabela
          v-if="
            this.usingVoucherRecebimento &&
            this.model.numProtocolosRecebimento &&
            this.model.numProtocolosRecebimento.length
          "
        >
          <TabelaMateriaisProtocolo
            :model="model"
            :currentMaterialModel="currentMaterialModel"
            :columns="['Descrição', 'ID', 'Peças', 'Qtd']"
            :columnsKeys="[
              'descricao',
              'shownId',
              'pecas',
              'qt_material_generico',
            ]"
            :isBusy="isBusy"
            :materiaisByProtocolos="materiaisByProtocolos"
            :config="config"
            typeProt="Voucher"
            enableItemTooltip
          />
        </template>
      </LeituraMateriais>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import UnidadeSetorDestino from "../../Components/UnidadeSetorDestino";
import InputTags from "../../../../../../front-end/src/components/Form/InputTags";
import MaterialDetection from "../../Components/MaterialDetection";
import LeituraMateriais from "../../Components/LeituraMateriais";
import TabelaMateriaisProtocolo from "../../Components/TabelaMateriaisProtocolo";
import config from "./config";

export default {
  name: "SaidaSetor",
  components: {
    UnidadeSetorDestino,
    InputTags,
    MaterialDetection,
    LeituraMateriais,
    TabelaMateriaisProtocolo,
  },
  inject: [
    "parentValidator",
    "validateState",
    "goToPage",
    "updateModel",
    "addMaterialToModel",
    "getMateriaisByProtocolo",
    "getProtocolosPendentes",
    "setMateriaisByProtocolos",
    "setNumProtocolosRecebimento",
    "setNumProtocolosPendentes",
  ],
  created() {
    this.$validator = this.parentValidator;
  },
  props: {
    temporaryData: {
      type: Object || null,
      required: false,
    },
    model: {
      type: Object,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    materiaisByProtocolos: {
      type: Array,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    numberOfPages: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selected: null,
      config,
      usingVoucherRecebimento: false,
      enableSelectInput: false,
      enableInputTags: false
    };
  },
  computed: {
    ...mapState({
      currentUnidade: (state) => state.currentUnidade,
    }),
  },
  watch: {
    enableInputTags(bool) {
      if(bool) {
        this.enableSelectInput = false
        return this.setNumProtocolosRecebimento({removeState: true})
      } 
      this.enableSelectInput = true
      this.handleSelectChange(this.model.numProtocolosPendentes[0])
    },
    async usingVoucherRecebimento(checked) {
      if (checked) {
        const filters = {
          tipoProtocolo: "recebimento",
          id_unidade: this.model.id_unidade,
          id_unidade_destino: this.model.id_unidade_destino,
          id_setor_destino: this.model.id_setor_destino,
          tipo_processo: this.model.tipo_processo,
          sub_setor: this.model.sub_setor,
          id_setor_destino: this.model.id_setor_destino,
        };
        await this.getProtocolosPendentes(filters);

        if (
          this.model.numProtocolosPendentes && 
          this.model.numProtocolosPendentes.length
        ) {
          this.enableSelectInput = true
          this.handleSelectChange(this.model.numProtocolosPendentes[0])
        } else {
          this.enableSelectInput = false
          this.enableInputTags = true
        }
      } else {
        this.setNumProtocolosRecebimento({ removeState: true });
        this.setNumProtocolosPendentes({ removeState: true });
        this.enableInputTags = false
        this.selected = null
      }
    },
    async currentPage(val, oldVal) {
      if (val === 2) {
        this.updateModel("id_unidade_destino", this.currentUnidade.id_unidade);
      } else if (
        val === 3 &&
        this.usingVoucherRecebimento &&
        this.model.numProtocolosRecebimento &&
        this.model.numProtocolosRecebimento.length
      ) {
        const filters = {
          onlySaidaVoucher: true,
          processoCheck: "saidaSetor",
          id_unidade: this.model.id_unidade,
          includeRespostasSaidaSetor: true,
          validateFluxo: true,
          ignoreValidationProtocolo: true,
          sub_setor: this.model.sub_setor,
          ignoreValidationResults: true,   
        };

        this.getMateriaisByProtocolo(
          this.model.numProtocolosRecebimento, 
          filters
        );
      }
    },
  },

  methods: {
    handleSelectChange(v) {
      this.selected = v
      this.setNumProtocolosRecebimento({
        value: this.selected,
        removeState: false
      })
    },
    handleVouchersChange(v) {
      if (v.length) {
        this.updateModel("numProtocolosRecebimento", v);
      } else {
        this.setNumProtocolosRecebimento({ removeState: true });
      }
    },
    updateHandleKeyUpEnter(val) {
      this.$store.commit("setNoSubmitOnEnter", val);
    },
  },
};
</script>
<style scoped>
.manually-checkbox {
  margin-left: 15px;
  margin-top: 10px;
}
.vouchers-message {
  background-color: red;
  text-align: center;
  color: rgb(68, 68, 68);
}
.spinner {
  width: 50px;
  height: 50px;
  color: #209f85;
  margin: 0 auto;
}
</style>