<template>
  <div>
    <div v-if="currentPage === 2">
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Instrumentador Responsável"
            label-for="instrumentador_input"
          >
            <b-form-input
              name="instrumentador_input"
              id="instrumentador_input"
              v-model="model.instrumentador"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <Separator />
      <LeituraMateriais
        :model="model"
        :isBusy="isBusy"
        :sendToModel="false"
        withoutTitle
        detalheMaterial
        hasIntegridade
        hasOptions
        :config="config"
        :materiaisByProtocolos="materiaisByProtocolos"
        hideMateriaisGenerico
      >
        <template v-if="materiaisByProtocolos.length" #tabela>
          <TabelaMateriaisProtocolo
            :isBusy="isBusy"
            :currentMaterialModel="currentMaterialModel"
            :materiaisByProtocolos="materiaisByProtocolos"
            :columns="['Descrição', 'ID', 'Peças']"
            :columnsKeys="['descricao', 'shownId', 'pecas']"
            :model="model"
            :config="config"
            modalTitle="Informações do Material"
            preserveData
          />
        </template>
        <template #modal>
          <ModalProcesso
            title="Manutenção material"
            preserveData
            :currentMaterialModel="currentMaterialModel"
            :config="config"
            :validationFields="['observacoes', 'quantidade_pecas']"
          >
            <template #processo>
              <b-container>
                <b-row>
                  <b-col>
                    <InformacoesMaterial
                      :currentMaterialModel="currentMaterialModel"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <InformacoesManutencao
                      :isBusy="isBusy"
                      :currentMaterialModel="currentMaterialModel"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </template>
          </ModalProcesso>
        </template>
      </LeituraMateriais>
    </div>
  </div>
</template>

<script>
import TabelaMateriaisProtocolo from "../../Components/TabelaMateriaisProtocolo";
import MaterialDetection from "../../Components/MaterialDetection";
import TermoLavagemAuto from "../../Components/TermoLavagemAuto";
import InformacoesManutencao from "../../Components/InformacoesManutencao";
import InformacoesMaterial from "../../Components/InformacoesMaterial";
import Separator from "../../../../../../front-end/src/components/Utils/Separator";
import LeituraMateriais from "../../Components/LeituraMateriais";
import ModalProcesso from "../../Components/ModalProcesso";
import config from "./config";

export default {
  name: "preparoManutencaoPreventiva",
  components: {
    MaterialDetection,
    TabelaMateriaisProtocolo,
    InformacoesManutencao,
    TermoLavagemAuto,
    InformacoesMaterial,
    Separator,
    LeituraMateriais,
    ModalProcesso,
  },
  inject: [
    "getMateriaisByProtocolo",
    "preserveCurrentMaterialModel",
    "updateModel",
  ],
  props: {
    materiaisByProtocolos: {
      type: Array,
      required: false,
    },
    model: {
      type: Object,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    isBusy: {
      type: Boolean,
    },
  },
  data() {
    return {
      config,
    };
  },
  mounted() {
    this.preserveCurrentMaterialModel({
      ...config.currentMaterialModelProcesso,
    });
  },
  computed: {
    hasProtocoloRecebimento() {
      return (
        this.model.numProtocolosRecebimento &&
        this.model.numProtocolosRecebimento[0]
      );
    },
  },
  watch: {
    currentPage: {
      immediate: true,
      async handler(newPage, oldPage) {
        if (newPage === 2 && this.hasProtocoloRecebimento) {
          const { numProtocolosRecebimento } = this.model;
          await this.getMateriaisByProtocolo(numProtocolosRecebimento, {
            processoCheck: "preparo",
            ignoreValidationProtocolo: true,
            validateFluxo: true,
            sub_setor: this.model.sub_setor,
            ignoreValidationResults: true,  
            id_unidade: this.model.id_unidade,             
          });
        }
      },
    },
  },
  methods: {
    setIsIntegridade(value) {
      this.isIntegridade = value;
    },
    customOnDetection(newMaterial) {
      if (newMaterial.id_tipo_material === 2) {
        newMaterial.needProcedimento = true;
      }
      newMaterial.quantidade_pecas = newMaterial.quantidade_itens;
    },
    goToIntegridade(index) {
      if (typeof index === "number") {
        this.setCurrentMaterialFromModel(index);
      }
    },
  },
};
</script>
