<template>
  <div>
    <div v-if="currentPage === 2">
      <UnidadeSetorDestino
        :model="model"
        destinoSetorCme
      />
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12">
          <b-form-group label="Justificativa*" label-for="justificativa">
            <b-form-input
              name="justificativa"
              :value="model.justificativa"
              autocomplete="off"
              class="invision-input"
              :disabled="isBusy"
              v-validate={required:true}
              :state="validateState('justificativa')"
              @input="v => updateModel('justificativa', v)"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <LeituraMateriais
        :isBusy="isBusy"
        :model="model"
        hideMateriaisGenerico
      />
    </div>
  </div>
</template>

<script>
import UnidadeSetorDestino from '../../Components/UnidadeSetorDestino';
import LeituraMateriais from '../../Components/LeituraMateriais';

export default {
  name: 'Emprestimo',
  inject: ['updateModel', 'parentValidator', 'validateState'],
  created() {
    this.$validator = this.parentValidator;
  },
  components: {
    UnidadeSetorDestino,
    LeituraMateriais,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    numberOfPages: {
      type: Number,
      required: true,
    },
  },
};
</script>
