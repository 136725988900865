var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.currentPage === 2)?_c('div',[_c('UnidadeSetorDestino',{attrs:{"model":_vm.model,"destinoCentral":""}}),_c('LeituraMateriais',{attrs:{"materiaisByProtocolos":_vm.materiaisByProtocolos,"sendToModel":false,"model":_vm.model,"isBusy":_vm.isBusy,"hasOptions":"","enableReimprimirEtiqueta":"","hideMateriaisGenerico":"","hideCamera":false},scopedSlots:_vm._u([(_vm.hasProtocoloRecebimento)?{key:"tabela",fn:function(){return [_c('TabelaMateriaisProtocolo',{attrs:{"isBusy":_vm.isBusy,"currentMaterialModel":_vm.currentMaterialModel,"materiaisByProtocolos":_vm.materiaisByProtocolos,"columns":['Descrição', 'ID', 'Peças'],"columnsKeys":['descricao', 'shownId', 'pecas'],"model":_vm.model,"config":_vm.config,"modalTitle":"Informações do Preparo"}})]},proxy:true}:null,{key:"modal",fn:function(){return [_c('ModalProcesso',{attrs:{"title":"Informações do Preparo","currentMaterialModel":_vm.currentMaterialModel,"preserveData":"","config":_vm.config,"validationFields":[
            'equipamento_lavagem_automatizada',
            'colaborador_lavagem_automatizada',
            'lote_detergente_lavagem_automatizada',
            'naoFazerAutomatizada',
            'integrador_quimico',
            'observacoes',
            'justificativa',
            'quantidade_pecas',
            'tipo_embalagem',
            'lote',
            'limpeza_automatizada' ]},scopedSlots:_vm._u([{key:"processo",fn:function(){return [_c('b-container',[_c('b-row',[_c('b-col',[_c('InformacoesMaterial',{attrs:{"currentMaterialModel":_vm.currentMaterialModel}})],1)],1),_c('Separator'),_c('b-row',[_c('b-col',[_c('LavagemAutomatizada',{attrs:{"isBusy":_vm.isBusy,"currentMaterialModel":_vm.currentMaterialModel}})],1)],1),_c('Separator'),_c('b-row',[_c('b-col',[_c('InformacoesPreparo',{attrs:{"isBusy":_vm.isBusy,"currentMaterialModel":_vm.currentMaterialModel}})],1)],1)],1)]},proxy:true}],null,false,1404895485)})]},proxy:true}],null,true)})],1):_vm._e(),_c('b-modal',{ref:"modalEtiquetaPreparo",attrs:{"id":"modalEtiquetaPreparo","title":"Imprimir Etiqueta","hide-footer":"","no-close-on-esc":"","no-close-on-backdrop":"","size":"md"},on:{"hidden":function($event){return _vm.goToPage(_vm.currentPage + 1, true)}}},[_c('PrinterEtiqueta',{attrs:{"resultData":_vm.etiquetaData},scopedSlots:_vm._u([{key:"printContent",fn:function(){return [_c('EtiquetaEntradaPreparo',{attrs:{"relatorioData":_vm.etiquetaData}})]},proxy:true}])})],1),_c('Modal',{ref:"modalRelatorioTransfPreparo",attrs:{"id":"modalRelatorioTransfPreparo","hide-footer":"","no-close-on-esc":"","no-close-on-backdrop":"","size":"lg"},on:{"hidden":_vm.restartProcessamento}},[_c('PrinterRelatorio',{attrs:{"buttonName":"IMPRIMIR","hideSection":"section","hideContent":"content"},scopedSlots:_vm._u([{key:"printContent",fn:function(){return [_c('RelatorioTransferenciaPreparo',{attrs:{"relatorioData":_vm.relatorioData}})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }