export default {
  enableMaterialRede: true,
  customButtonPages: [3],
  showBannerPages: [],
  underListPages: [],
  tabelaMateriaisPage: null,
  enableMaterialRede: true,
  sendProtocolosForValidation: true,
  checkMaterialMedicoSaidaBloco: true,
  preventProtocolosFromFetching: false,
  validLengthInputText: (function () {
    const min = 3
    return {
      min,
      typeValidation: 'alpha_spaces',
      message: `Deve inserir o nome do responsável pela retirada dos materiais (deve ter mínimo ${min} letras, não deve começar com caracteres especiais nem espaços ou números)`
    }
  })(),
  modelProcesso: {
    id_unidade_destino: null,
    id_setor_destino: null,
    responsavelRetirada: '',
  },
  currentMaterialModelProcesso: {},
  pageList: [
    {
      title: 'Informações da saída',
      subItems: [
        { title: 'Destino dos materiais' },
      ],
    },
    {
      title: 'Leitura dos materiais',
      subItems: [
        { title: 'Leitura de materiais' },
      ],
    },
  ],
};
