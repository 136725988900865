<template>
  <div>
    <div v-if="currentPage === 2">
      <InformacoesEsterilizacaoMaterialGenerico
        :model="model"
        :currentMaterialModel="currentMaterialModel"
        :isBusy="isBusy"
        :equipamentoEsterilizacaoFilters="equipamentoEsterilizacaoFilters"
        tipoMaterialGenerico="enxoval"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import InformacoesEsterilizacaoMaterialGenerico from '../../Components/InformacoesEsterilizacaoMaterialGenerico';

export default {
  name: 'EsterilizacaoEnxoval',
  components: {
    InformacoesEsterilizacaoMaterialGenerico,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    isBusy: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState(['currentUnidade']),
    equipamentoEsterilizacaoFilters() {
      let idRetorno = 0;
      if (this.currentUnidade.id_unidade) {
        idRetorno = this.currentUnidade.id_unidade;
      }

      const filters = {
        id_unidade: idRetorno,
        esterilizacao: true,
      };

      if (this.currentMaterialModel.id_tipo_esterilizacao) {
        filters.id_tipo_esterilizacao = this.currentMaterialModel.id_tipo_esterilizacao;
      }

      return filters;
    },
  },
};
</script>
