<template>
  <div>
    <div v-if="currentPage === 2">
      <InformacoesEsterilizacaoLote
        :model="model"
        :isBusy="isBusy"
      />
      <Separator />
      <LeituraMateriais
        :model="model"
        :isBusy="isBusy"
        withoutTitle
        :config="config"
        :materiaisByProtocolos="materiaisByProtocolos"
      >
        <template #tabela v-if="materiaisByProtocolos.length">
          <TabelaMateriaisProtocolo
            :isBusy="isBusy"
            :materiaisByProtocolos="materiaisByProtocolos"
            :columns="['Descrição', 'ID', 'Peças', 'Qtd']"
            :columnsKeys="['descricao', 'shownId', 'pecas', 'qt_material_generico']"
            :model="model"
            :config="config"
          />
        </template>
        <template #modal>
          <ModalProcesso
            title="Seleção de embalagem"
            preserveData
            :currentMaterialModel="currentMaterialModel"
            :config="config"
            :validationFields="['tipo_embalagem']"
          >
            <template #processo>
              <b-container>
                <b-row>
                  <b-col>
                    <span id="aviso-embalagem-required">Para cálculo da validade, é obrigatória a seleção de embalagem</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <GenericSelect
                      class="mb-4"
                      ref="tipoEmbalagemm"
                      name="tipo_embalagem"
                      labelKey="nome"
                      :value="currentMaterialModel.id_tipo_embalagem"
                      route="tipoEmbalagem"
                      :disabled="isBusy"
                      v-validate="{ required: true }"
                      @input="v => updateCurrentMaterialModel('id_tipo_embalagem', v)"
                      :state="validateState('tipo_embalagem')"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </template>
          </ModalProcesso>
        </template>
      </LeituraMateriais>
    </div>
  </div>
</template>

<script>
import Separator from '../../../../../../front-end/src/components/Utils/Separator';
import GenericSelect from '../../../../../../front-end/src/components/Form/GenericSelect';

import MaterialDetection from '../../Components/MaterialDetection';
import TabelaMateriaisProtocolo from '../../Components/TabelaMateriaisProtocolo';
import InformacoesEsterilizacaoLote from '../../Components/InformacoesEsterilizacaoLote';
import LeituraMateriais from '../../Components/LeituraMateriais';
import config from './config';
import ModalProcesso from '../../Components/ModalProcesso';

export default {
  name: 'EntradaEsterilizacao',
  components: {
    MaterialDetection,
    TabelaMateriaisProtocolo,
    Separator,
    InformacoesEsterilizacaoLote,
    LeituraMateriais,
    ModalProcesso,
    GenericSelect,
  },
  inject: ['parentValidator', 'getMateriaisByProtocolo', 'validateState', 'updateCurrentMaterialModel'],
  created() {
    this.$validator = this.parentValidator;
  },
  props: {
    materiaisByProtocolos: {
      type: Array,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    isBusy: {
      type: Boolean,
    },
  },
  data() {
    return {
      config,
      idEmbalagemRead: 0,
    };
  },
  computed: {
    hasProtocoloRecebimento() {
      return this.model.numProtocolosRecebimento && this.model.numProtocolosRecebimento[0];
    },
  },
  watch: {
    currentPage: {
      immediate: true,
      async handler(newPage, oldPage) {
        if (newPage === 2 && this.hasProtocoloRecebimento) {
          const { numProtocolosRecebimento } = this.model;

          await this.getMateriaisByProtocolo(numProtocolosRecebimento, {
            processoCheck: 'esterilizacao', 
            sendProcessoCicloRecebimento: config.sendProcessoCicloRecebimento,
            validateFluxo: true,
            ignoreValidationProtocolo: true,
            sub_setor: this.model.sub_setor,
            ignoreValidationResults: true,
            id_unidade: this.model.id_unidade,
          });
        }
      },
    },
  },
  methods: {
    customOnDetection(currentMaterial) {
      if (currentMaterial.isMaterialGenerico && currentMaterial.id_tipo_embalagem) {
        this.idEmbalagemRead = currentMaterial.id_tipo_embalagem;
      } else {
        this.idEmbalagemRead = 0;
      }
    },
    customChooseMaterialGenerico(currentMaterial) {
      if (this.idEmbalagemRead) {
        currentMaterial.id_tipo_embalagem = this.idEmbalagemRead;
      }
      this.idEmbalagemRead = 0;
      currentMaterial.needModalProcesso = true;
    },
  },
};
</script>
<style scoped>
#aviso-embalagem-required {
  color: #35384D;
  font-size: 14px;
  line-height: 21px;
}
</style>
