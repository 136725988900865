import Recebimento from './Recebimento/Recebimento';
import RelatorioRecebimento from './Recebimento/Relatorio';
import dataRecebimento from './Recebimento/config';

import TransferenciaExpurgo from './TransferenciaExpurgo/TransferenciaExpurgo';
import RelatorioTransferenciaExpurgo from './TransferenciaExpurgo/Relatorio';
import dataTransferenciaExpurgo from './TransferenciaExpurgo/config';

import TransferenciaExpurgoOpmeConsignado from
  './TransferenciaExpurgoOpmeConsignado/TransferenciaExpurgoOpmeConsignado';
import RelatorioTransferenciaExpurgoOpmeConsignado from './TransferenciaExpurgoOpmeConsignado/Relatorio';
import dataTransferenciaExpurgoOpmeConsignado from './TransferenciaExpurgoOpmeConsignado/config';

import DevolucaoOpmeConsignado from './DevolucaoOpmeConsignado/DevolucaoOpmeConsignado';
import RelatorioDevolucaoOpmeConsignado from './DevolucaoOpmeConsignado/Relatorio';
import dataDevolucaoOpmeConsignado from './DevolucaoOpmeConsignado/config';

import DevolucaoMedico from './DevolucaoMedico/DevolucaoMedico';
import RelatorioDevolucaoMedico from './DevolucaoMedico/Relatorio';
import dataDevolucaoMedico from './DevolucaoMedico/config';

import EntradaPreparo from './EntradaPreparo/EntradaPreparo';
import EtiquetaEntradaPreparo from './EntradaPreparo/Etiqueta';
import dataEntradaPreparo from './EntradaPreparo/config';

import CadastroPreparoSPR from './CadastroPreparoSPR/CadastroPreparoSPR';
import EtiquetaCadastroPreparoSPR from './CadastroPreparoSPR/EtiquetasCadastroPreparo';
import dataCadastroPreparoSPR from './CadastroPreparoSPR/config';

import TransferenciaPreparo from './TransferenciaPreparo/TransferenciaPreparo';
import RelatorioTransferenciaPreparo from './TransferenciaPreparo/Relatorio';
import dataTransferenciaPreparo from './TransferenciaPreparo/config';

import EntradaTransferenciaPreparo from './EntradaTransferenciaPreparo/EntradaTransferenciaPreparo';
import dataEntradaTransferenciaPreparo from './EntradaTransferenciaPreparo/config';

import EntradaEsterilizacao from './EntradaEsterilizacao/EntradaEsterilizacao';
import EtiquetaEntradaEsterilizacao from './EntradaEsterilizacao/Etiqueta';
import dataEntradaEsterilizacao from './EntradaEsterilizacao/config';

import EsterilizacaoManufatura from './EsterilizacaoManufatura/EsterilizacaoManufatura';
import EtiquetaEsterilizacaoManufatura from './EsterilizacaoManufatura/Etiqueta';
import dataEsterilizacaoManufatura from './EsterilizacaoManufatura/config';

import EsterilizacaoEnxoval from './EsterilizacaoEnxoval/EsterilizacaoEnxoval';
import EtiquetaEsterilizacaoEnxoval from './EsterilizacaoEnxoval/Etiqueta';
import dataEsterilizacaoEnxoval from './EsterilizacaoEnxoval/config';

import RetornoExpurgo from './RetornoExpurgo/RetornoExpurgo';
import RelatorioRetornoExpurgo from './RetornoExpurgo/Relatorio';
import dataRetornoExpurgo from './RetornoExpurgo/config';

import SaidaSetor from './SaidaSetor/SaidaSetor';
import RelatorioSaidaSetor from './SaidaSetor/Relatorio';
import dataSaidaSetor from './SaidaSetor/config';

import SaidaPaciente from './SaidaPaciente/SaidaPaciente';
import RelatorioSaidaPaciente from './SaidaPaciente/Relatorio';
import dataSaidaPaciente from './SaidaPaciente/config';

import Emprestimo from './Emprestimo/Emprestimo';
import RelatorioEmprestimo from './Emprestimo/Relatorio';
import dataEmprestimo from './Emprestimo/config';

import EmprestimoSaidaSetor from './EmprestimoSaidaSetor/EmprestimoSaidaSetor';
import RelatorioEmprestimoSaidaSetor from './EmprestimoSaidaSetor/Relatorio';
import dataEmprestimoSaidaSetor from './EmprestimoSaidaSetor/config';

import TransferenciaDevolucaoEmprestimo from './TransferenciaDevolucaoEmprestimo/TransferenciaDevolucaoEmprestimo';
import RelatorioTransferenciaDevolucaoEmprestimo from './TransferenciaDevolucaoEmprestimo/Relatorio';
import dataTransferenciaDevolucaoEmprestimo from './TransferenciaDevolucaoEmprestimo/config';

import RetornoRecebimento from './RetornoRecebimento/RetornoRecebimento';
import RelatorioRetornoRecebimento from './RetornoRecebimento/Relatorio';
import dataRetornoRecebimento from './RetornoRecebimento/config';

import TransferenciaOpmeConsignado from './TransferenciaOpmeConsignado/TransferenciaOpmeConsignado';
import RelatorioTransferenciaOpmeConsignado from './TransferenciaOpmeConsignado/Relatorio';
import dataTransferenciaOpmeConsignado from './TransferenciaOpmeConsignado/config';

import TransferenciaMedico from './TransferenciaMedico/TransferenciaMedico';
import RelatorioTransferenciaMedico from './TransferenciaMedico/Relatorio';
import dataTransferenciaMedico from './TransferenciaMedico/config';

import TransferenciaRede from './TransferenciaRede/TransferenciaRede';
import RelatorioTransferenciaRede from './TransferenciaRede/Relatorio';
import dataTransferenciaRede from './TransferenciaRede/config';

import TransferenciaEmprestimo from './TransferenciaEmprestimo/TransferenciaEmprestimo';
import RelatorioTransferenciaEmprestimo from './TransferenciaEmprestimo/Relatorio';
import dataTransferenciaEmprestimo from './TransferenciaEmprestimo/config';

import RecebimentoArsenal from './RecebimentoArsenal/RecebimentoArsenal';
import RelatorioRecebimentoArsenal from './RecebimentoArsenal/Relatorio';
import dataRecebimentoArsenal from './RecebimentoArsenal/config';

import RetornoArsenalBloco from './RetornoArsenalBloco/RetornoArsenalBloco';
import RelatorioRetornoArsenalBloco from './RetornoArsenalBloco/Relatorio';
import dataRetornoArsenalBloco from './RetornoArsenalBloco/config';

import PreparoInox from './PreparoInox/PreparoInox';
import EtiquetaPreparoInox from './PreparoInox/Etiqueta';
import dataPreparoInox from './PreparoInox/config';

import PreparoLote from './PreparoLote/PreparoLote';
import EtiquetaPreparoLote from './PreparoLote/Etiqueta';
import dataPreparoLote from './PreparoLote/config';

import RecebimentoTransferencia from './RecebimentoTransferencia/RecebimentoTransferencia';
import RelatorioRecebimentoTransferencia from './RecebimentoTransferencia/Relatorio';
import dataRecebimentoTransferencia from './RecebimentoTransferencia/config';

import PreparoManutencaoPreventiva from './PreparoManutencao/PreparoManutencao';
import PreparoManutencaoCorretiva from './PreparoManutencao/PreparoManutencao';
import PreparoReposicao from './PreparoManutencao/PreparoManutencao';
import PreparoBaixa from './PreparoManutencao/PreparoManutencao';
import EtiquetaManutencao from './PreparoManutencao/Etiqueta';
import dataManutencao from './PreparoManutencao/config';

export default {
  Recebimento: {
    Component: Recebimento,
    data: dataRecebimento,
    Relatorio: RelatorioRecebimento,
  },
  TransferenciaExpurgo: {
    Component: TransferenciaExpurgo,
    data: dataTransferenciaExpurgo,
    Relatorio: RelatorioTransferenciaExpurgo,
  },
  TransferenciaExpurgoOpmeConsignado: {
    Component: TransferenciaExpurgoOpmeConsignado,
    data: dataTransferenciaExpurgoOpmeConsignado,
    Relatorio: RelatorioTransferenciaExpurgoOpmeConsignado,
  },
  DevolucaoOpmeConsignado: {
    Component: DevolucaoOpmeConsignado,
    data: dataDevolucaoOpmeConsignado,
    Relatorio: RelatorioDevolucaoOpmeConsignado,
  },
  DevolucaoMedico: {
    Component: DevolucaoMedico,
    data: dataDevolucaoMedico,
    Relatorio: RelatorioDevolucaoMedico,
  },
  EntradaPreparo: {
    Component: EntradaPreparo,
    data: dataEntradaPreparo,
    Etiqueta: EtiquetaEntradaPreparo,
  },
  CadastroPreparoSPR: {
    Component: CadastroPreparoSPR,
    data: dataCadastroPreparoSPR,
    Etiqueta: EtiquetaCadastroPreparoSPR,
  },
  PreparoInox: {
    Component: PreparoInox,
    data: dataPreparoInox,
    Etiqueta: EtiquetaPreparoInox,
  },
  PreparoLote: {
    Component: PreparoLote,
    data: dataPreparoLote,
    Etiqueta: EtiquetaPreparoLote,
  },
  TransferenciaPreparo: {
    Component: TransferenciaPreparo,
    data: dataTransferenciaPreparo,
    Relatorio: RelatorioTransferenciaPreparo,
  },
  EntradaTransferenciaPreparo: {
    Component: EntradaTransferenciaPreparo,
    data: dataEntradaTransferenciaPreparo,
    Etiqueta: EtiquetaEntradaPreparo,
    Relatorio: RelatorioTransferenciaPreparo,
  },
  EntradaEsterilizacao: {
    Component: EntradaEsterilizacao,
    data: dataEntradaEsterilizacao,
    Etiqueta: EtiquetaEntradaEsterilizacao,
  },
  EsterilizacaoManufatura: {
    Component: EsterilizacaoManufatura,
    data: dataEsterilizacaoManufatura,
    Etiqueta: EtiquetaEsterilizacaoManufatura,
  },
  EsterilizacaoEnxoval: {
    Component: EsterilizacaoEnxoval,
    data: dataEsterilizacaoEnxoval,
    Etiqueta: EtiquetaEsterilizacaoEnxoval,
  },
  RetornoExpurgo: {
    Component: RetornoExpurgo,
    data: dataRetornoExpurgo,
    Relatorio: RelatorioRetornoExpurgo,
  },
  SaidaSetor: {
    Component: SaidaSetor,
    data: dataSaidaSetor,
    Relatorio: RelatorioSaidaSetor,
  },
  SaidaPaciente: {
    Component: SaidaPaciente,
    data: dataSaidaPaciente,
    Relatorio: RelatorioSaidaPaciente,
  },
  Emprestimo: {
    Component: Emprestimo,
    data: dataEmprestimo,
    Relatorio: RelatorioEmprestimo,
  },
  EmprestimoSaidaSetor: {
    Component: EmprestimoSaidaSetor,
    data: dataEmprestimoSaidaSetor,
    Relatorio: RelatorioEmprestimoSaidaSetor,
  },
  TransferenciaDevolucaoEmprestimo: {
    Component: TransferenciaDevolucaoEmprestimo,
    data: dataTransferenciaDevolucaoEmprestimo,
    Relatorio: RelatorioTransferenciaDevolucaoEmprestimo,
  },
  RetornoRecebimento: {
    Component: RetornoRecebimento,
    data: dataRetornoRecebimento,
    Relatorio: RelatorioRetornoRecebimento,
  },
  TransferenciaOpmeConsignado: {
    Component: TransferenciaOpmeConsignado,
    data: dataTransferenciaOpmeConsignado,
    Relatorio: RelatorioTransferenciaOpmeConsignado,
  },
  TransferenciaMedico: {
    Component: TransferenciaMedico,
    data: dataTransferenciaMedico,
    Relatorio: RelatorioTransferenciaMedico,
  },
  TransferenciaRede: {
    Component: TransferenciaRede,
    data: dataTransferenciaRede,
    Relatorio: RelatorioTransferenciaRede,
  },
  TransferenciaEmprestimo: {
    Component: TransferenciaEmprestimo,
    data: dataTransferenciaEmprestimo,
    Relatorio: RelatorioTransferenciaEmprestimo,
  },
  RecebimentoArsenal: {
    Component: RecebimentoArsenal,
    data: dataRecebimentoArsenal,
    Relatorio: RelatorioRecebimentoArsenal,
  },
  RetornoArsenalBloco: {
    Component: RetornoArsenalBloco,
    data: dataRetornoArsenalBloco,
    Relatorio: RelatorioRetornoArsenalBloco,
  },
  RecebimentoTransferencia: {
    Component: RecebimentoTransferencia,
    data: dataRecebimentoTransferencia,
    Relatorio: RelatorioRecebimentoTransferencia,
  },
  PreparoManutencaoPreventiva: {
    Component: PreparoManutencaoPreventiva,
    data: dataManutencao,
    Etiqueta: EtiquetaManutencao,
  },
  PreparoManutencaoCorretiva: {
    Component: PreparoManutencaoCorretiva,
    data: dataManutencao,
    Etiqueta: EtiquetaManutencao,
  },  
  PreparoReposicao: {
    Component: PreparoReposicao,
    data: dataManutencao,
    Etiqueta: EtiquetaManutencao,
  },
  PreparoBaixa: {
    Component: PreparoBaixa,
    data: dataManutencao,
    Etiqueta: EtiquetaManutencao,
  },
};
