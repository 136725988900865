<template>
<b-container id="full-wrapper">
  <div v-if="isBusy" class="spinner-tabela-materiais-protocolos">
    <b-spinner class="spinner" />
  </div>
  <b-container id="table-wrapper">
    <b-row>
      <b-col>
        <div
          class="table-protocolos"
          :class="isBusy ? 'loading-tabela-protocolos' : ''"
          v-for="protocolo in materiaisByProtocolos"
          :key="protocolo.numero || '-'"
        >
          <div class="table-header">
            <b-row>
              <b-col cols="10">
                <span v-if="protocolo.numero">{{ typeProt }} </span>
                <span>{{ protocolo.numero || 'Materiais de rede'}} </span>
                <span v-if="protocolo.numero">({{ protocolo.setorOrigem }} {{ protocolo.unidadeOrigem }})</span>
              </b-col>
            </b-row>
          </div>

          <div class="table-body">
            <b-row class="table-keys">
              <b-col md="1">
                <b-form-checkbox
                  :indeterminate="indeterminated"
                  :checked="allChecked"
                  :disabled="!model.materiais.length"
                  @change="v => handleCheckAllBoxes(v)"
                />
                <trash2-icon
                  id="tkeys-icon-trash"
                  :class="haveBoxesChecked ? 'visible' : 'invisible'"
                  @click="removeSelectedMateriais"
                />
              </b-col>
              <b-col v-for="(col, index) in columns" :key="col + index" :md="index === 0 ? 3 : columnSize">
                <span>
                  {{ col }}
                </span>
              </b-col>
              <b-col />
              <b-col />
            </b-row>
            <b-row
              class="table-row"
              v-for="materialProt in protocolo.materiais"
              :class="materialProt.read && 'current-read'"
              :key="materialProt.readId"
              :id="materialProt.id_material || materialProt.id_material_generico"
              @mouseleave="mouseLeave"
            >
              <b-col md="1">
                <b-tooltip 
                  v-if="enableItemTooltip && materialProt.check && materialProt.check.material_saida && materialProt.check.material_saida.hasOwnProperty('id_material')"
                  :target="String(materialProt.id_material || materialProt.id_material_generico)" 
                  triggers="hover"
                >
                  O material <b>{{materialProt.check.material_saida.descricao}} (ID: {{ materialProt.check.material_saida.shownId }})</b> foi liberado no lugar deste material
                </b-tooltip>
                <b-form-checkbox-group
                  name="checkboxes"
                  v-model="selected"
                >
                  <b-form-checkbox
                    :name="materialProt.descricao"
                    :disabled="!materialProt.read"
                    :value="materialProt.readId"
                    ref="checkboxes"
                  />
                </b-form-checkbox-group>
              </b-col>

              <b-col
                v-for="(key, index) in columnsKeys"
                :key="key"
                :class="materialProt.read ? 'read-material' : 'not-read-material'"
                :md="index === 0 ? 3 : columnSize"
              >
                <span>
                  {{ materialProt[key] }}
                </span>
                <b-tooltip v-if="materialProt.check && materialProt.check.msg"
                  :target="String(materialProt.id_material || materialProt.id_material_generico)" triggers="hover"
                  placement="left">
                  {{materialProt.check.msg}}
                </b-tooltip>
              </b-col>

              <b-col md="1">
                <span
                  id="status"
                  v-if="materialProt.check && materialProt.check.msg && enableMaterialStatus"
                >Indisponível</span>

                <div class="icons-container" 
                :class="(
                    openMenuFor === (materialProt.readId)
                    || !materialProt.read
                  )
                  && 'invisible'"
                v-else
                >
                  <alert-triangle-icon
                    v-if="enableIntegridade && materialProt.readId"
                    class="icons-color alert-triangle"
                    @click="handleIntegridade(parseMaterialProtocoloToModel(materialProt))"
                    :class="parseMaterialProtocoloToModel(materialProt).naoConformidade && 'nao-conformidade'"
                  />
                  <trash-2-icon
                    @click="removeReadMaterial(materialProt.readId)"
                    class="trash-can icons-color"
                  />
                </div>

              </b-col>

              <b-col md="1" v-if="hasOptions">
                <more-horizontal-icon
                  @click="handleOpenOptionsMenu(materialProt)"
                  class="h4 three-dots icons-color"
                  :class="!materialProt.read && 'invisible'"
                />
              </b-col>

              <div id="item-menu" v-if="openMenuFor === (materialProt.readId)">
                <span
                  v-if="materialProt.id_material && enableReimprimirEtiqueta"
                  class="item-menu-option"
                  @click="handleReimprimirEtiqueta(materialProt)"
                >
                  Reimprimir etiqueta do material
                </span>
                <span
                  v-if="enableEditInformacoes"
                  class="item-menu-option"
                  @click="handleEditInformacoes(materialProt.readId)"
                >
                  Editar informações
                </span>
              </div>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</b-container>
</template>

<script>
import Button from '../../../../../front-end/src/components/Utils/Button';

export default {
  name: 'TabelaMateriaisProtocolo',
  components: {
    Button,
  },
  inject: [
    'confirmPage',
    'validatePageChange',
    'addMaterialToModel',
    'removeMaterial',
    'removeManyMateriais',
    'resetCurrentMaterialModel',
    'setCurrentMaterialFromModel',
    'handleReimprimirEtiqueta',
    'handleIntegridade'
  ],
  props: {
    enableItemTooltip: {
      type: Boolean,
      required: false
    },
    enableIntegridade: {
      type: Boolean,
      required: false,
    },
    enableEditInformacoes: {
      type: Boolean,
      required: false,
      default: () => true
    },
    materiaisByProtocolos: {
      type: Array,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    columnsKeys: {
      type: Array,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: false,
    },
    config: {
      type: Object,
      required: false,
    },
    enableReimprimirEtiqueta: {
      type: Boolean,
      default: false,
    },
    typeProt: {
      type: String,
      default: 'Protocolo'
    },
    enableMaterialStatus: {
      type: Boolean,
      required: false,
      default: () => true
    }
  },
  data() {
    return {
      openMenuFor: null,
      selected: [],
      currentIndexes: [],
    };
  },
  methods: {
    parseMaterialProtocoloToModel(material) {
      return this.model.materiais.find(m => 
        material.id_material ? m.id_material === material.id_material
        :
        material.id_material_generico === m.id_material_generico
      ) || {}
    },
    handleEditInformacoes(readId) {
      const materialIndexToEdit = this.model.materiais.findIndex(
        (material) => material.readId === readId,
      );
      if(materialIndexToEdit || materialIndexToEdit === 0) {
        this.setCurrentMaterialFromModel(materialIndexToEdit);
        this.$bvModal.show('modal-processo-protocolo')
      }
      this.openMenuFor = null;
    },
    async removeSelectedMateriais() {
      const removed = await this.removeManyMateriais(this.selected);
      if (removed) {
        this.selected = [];
      }
    },
    handleCheckAllBoxes(v) {
      const { checkboxes } = this.$refs;
      const enabledBoxes = checkboxes.filter((checkbox) => !checkbox.disabled);

      const selected = [];
      if (v) {
        enabledBoxes.forEach((box) => {
          if (!enabledBoxes.includes(box.value)) {
            selected.push(box.value);
          }
        });
      }
      this.selected = selected;
    },
    handleOpenOptionsMenu(materialProt) {
      if(!this.model.materiais.some(material => (
        (material.id_material && material.id_material === materialProt.id_material)
        || (material.id_material_generico && material.id_material_generico === materialProt.id_material_generico)
      ))) return;

      if (this.openMenuFor !== (materialProt.readId)) {
        this.openMenuFor = materialProt.readId;
        return;
      }
      this.openMenuFor = null;
    },
    async removeReadMaterial(readId) {
      const indexMaterial = this.model.materiais.findIndex((m) => m.readId === readId);
      const removed = await this.removeMaterial(indexMaterial);
      if (removed) {
        this.selected = this.selected.filter((sId) => sId !== readId);
      }
    },
    mouseLeave() {
      this.openMenuFor = null;
    },
  },
  computed: {
    haveBoxesChecked() {
      return Boolean(this.selected.length);
    },
    allChecked() {
      return this.selected.length && this.selected.length === this.model.materiais.length
    },
    indeterminated() {
      if (this.allChecked) return false;
      return this.haveBoxesChecked;
    },
    columnSize() {
      const bootstrapGrid = 12;
      const iconSlots = 2;
      const remainingSlots = bootstrapGrid - iconSlots;
      const value = Math.floor(remainingSlots / this.columns.length);

      return value;
    },
    hasOptions() {
      return (
        (this.currentMaterialModel && Object.keys(this.currentMaterialModel).length)
        || this.enableReimprimirEtiqueta
        || this.enableEditInformacoes
      );
    }
  },
};
</script>

<style scoped>
.nao-conformidade {
  color:#209F85 !important;
}
.alert-triangle {
  position: absolute;
  bottom: -2px;
  left: -40px;
}
.icons-container {
  position: relative;
}
.table-protocolos {
  padding: 24px;
  border: 1px solid #F0F1FC;
  border-radius: 10px;
  border-collapse: collapse;
}
.table-body {
  border: 1px solid #F0F1FC;
  padding: 15px 15px 0px 15px;
  border-radius: 10px;
}
.table-header {
  margin-bottom: 21px;
  color: #35384D;
}
.table-keys{
  padding-bottom: 15px;
  border-bottom: 1px solid #F0F1FC;
  color: #5E627A;
}
.table-row {
  padding: 15px 0 15px 0;
  color:#35384D;
  position: relative;
  background: #FFFFFF;
}
.table-row:nth-child(odd) {
  border-top: 1px solid #F0F1FC;
  border-bottom: 1px solid #F0F1FC;
}
.table-row:hover {
  background: #f7f7fa;
}
.table-row:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.invisible {
  opacity: 0;
}
.visible {
  opacity: 1
}
.table-row:hover .trash-can {
  opacity: 1;
}
.table-row:hover .alert-triangle {
  opacity: 1;
}
.trash-can, .alert-triangle {
  opacity: 0;
}
.trash-can:hover, .alert-triangle:hover {
  cursor: pointer;
  color: rgb(105, 105, 105);
}
.three-dots:hover {
  cursor: pointer;
  color: #00a885;
}
#status {
  position: absolute;
  right: 10px;
  font-size: 12px;
  background: #e9e7f5;
  color: #7E829F;
  padding: 6px 9px 6px 9px;
  border-radius: 5px;
}
#material-dectection {
  margin-bottom: 50px;
}
#item-menu {
  position: absolute;
  right: 20px;
  top: 40px;
  z-index: 2;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid rgb(238, 238, 238);
}
.item-menu-option {
  display: block;
  font-size: 0.9rem;
  padding: 15px;
}
.item-menu-option:hover {
  background: #f7f7fa;
  cursor: pointer;
}
.not-read-material {
  color: #999ba5;
}
.read-material {
  color: #35384d;
}
#tkeys-icon-trash {
  position: absolute;
  top: -1px;
  right: -4px;
  width: 1.3rem;
  color: #7e829f;
  cursor: pointer;
}
.current-read{
  background:#f5f7ff;
}

.w-52 {
  width: 95%;
}
.loading-tabela-protocolos {
  opacity: 0.3;
}
.spinner-tabela-materiais-protocolos {
  position: absolute;
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 9;
}
.spinner {
  color: #209f85;
  width: 100px;
  height: 100px;
}
</style>
