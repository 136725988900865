var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{staticClass:"mt-3",attrs:{"fields":_vm.tableFields,"items":_vm.model.materiais,"responsive":"","show-empty":"","empty-text":"Não existem materiais selecionados"},scopedSlots:_vm._u([{key:"cell(state)",fn:function(ref){
var index = ref.index;
return [_c('b-button',{staticClass:"btn btn-sm text--black",attrs:{"variant":"outline-dark"},on:{"click":function($event){return _vm.removeMaterial(index)}}},[_c('trash-2-icon',{staticClass:"w20px"})],1)]}},{key:"cell(hasNaoConformidade)",fn:function(ref){
var index = ref.index;
var value = ref.value;
return [_c('div',{staticClass:"text-center"},[_c('alert-triangle-icon',{staticClass:"w20px pointer",attrs:{"color":value ? '#209F85' : '#5E627A'},on:{"click":function($event){return _vm.$emit('go-to-integridade', index)}}})],1)]}},{key:"cell(repo)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('edit-icon',{class:_vm.editIconClass(item),on:{"click":function($event){return _vm.handleEditBut(item, index)}}})]}}])}),(!_vm.preventDefaultButtons)?_c('b-row',{staticClass:"pt-4",attrs:{"align-h":"between"}},[_c('b-col',{attrs:{"sm":"3","md":"2","lg":"2","xl":"2"}},[_c('Button',{attrs:{"text":"Cancelar","variant":"grayscale","loading":_vm.isBusy},on:{"click":_vm.discardForm}})],1),_c('b-col',{staticClass:"text-right",attrs:{"sm":"9","md":"10","lg":"10","xl":"10"}},[_c('Button',{staticClass:"mr-4",attrs:{"text":"Novo material","type":"outline","loading":_vm.isBusy},on:{"click":function($event){return _vm.goToPage(_vm.materialDetectionPage || _vm.currentPage - 1)}}}),_c('Button',{attrs:{"text":_vm.confirmButtonText,"loading":_vm.isBusy},on:{"click":_vm.confirmPage}})],1)],1):_vm._e(),_c('b-modal',{ref:"modalEditIntegridade",attrs:{"id":"modalEditIntegridade","title":"Reposição","hide-footer":"","size":"lg"},on:{"hidden":_vm.closingEditModal}},[_c('Reposicao',{attrs:{"model":_vm.model,"editingMaterial":_vm.editingMaterial,"allDefeitos":_vm.allDefeitos,"isBusy":_vm.isBusy},on:{"hide-modal":_vm.hideModal,"update-editing-material":_vm.updateEditingMaterial}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }