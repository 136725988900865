<template>
  <div>
    <b-row>
      <!--presença de não conformidade checkbox-->
      <b-col sm="12" md="12" lg="12" xl="12">
        <YesOrNoRadio
          name="nao_conformidade_inox"
          id="nao_conformidade_inox"
          label="Presença de não conformidade"
          :value="currentMaterialModel.nao_conformidade_inox"
          :disabled="isBusy"
          v-validate="{ required: true }"
          :state="validateState('nao_conformidade_inox')"
          @input="inputPresencaNaoConformidade"
        />
      </b-col>
    </b-row>
    <div
      class="sub-form"
      v-if="currentMaterialModel.nao_conformidade_inox"
    >
      <b-form-checkbox
        v-for="(item, index) in options"
        :id="`checkbox-${item.key}`"
        :key="index"
        :name="`checkbox-${item.key}`"
        @change="v => checkedOption(item, v)"
        v-validate="`${state ? '' : 'required:true'}`"
        :state="Boolean(state)"
      >
        {{ item.value }}
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
import YesOrNoRadio from '../../../../../front-end/src/components/Utils/YesOrNoRadio';

export default {
  inject: ['parentValidator', 'updateCurrentMaterialModel'],
  created() {
    this.$validator = this.parentValidator;
  },
  components: {
    YesOrNoRadio,
  },
  props: {
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    validateState: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      options: [
        { key: 'excrementos', value: 'Presença de excrementos' },
        { key: 'fezes', value: 'Fezes' },
        { key: 'urina', value: 'Urina' },
        { key: 'secrecao', value: 'Secreção/Sangue' },
      ],
      excrementos: false,
      fezes: false,
      urina: false,
      secrecao: false,
    };
  },
  computed: {
    state() {
      return this.excrementos || this.fezes || this.urina || this.secrecao;
    },
  },
  mounted() {
    this.updateCurrentMaterialModel('nao_conformidade_inox', false);
  },
  methods: {
    inputPresencaNaoConformidade(v) {
      this.updateCurrentMaterialModel('nao_conformidade_inox', v);
      if (v) {
        this.updateCurrentMaterialModel('nao_conformidades_inox', []);
      } else {
        this.updateCurrentMaterialModel('nao_conformidades_inox', null);
      }
    },
    checkedOption(option, v) {
      const { key, value } = option;
      this[key] = v;
      let newNaoConformidades;
      if (v) {
        newNaoConformidades = [...this.currentMaterialModel.nao_conformidades_inox, value];
      } else {
        newNaoConformidades = this.currentMaterialModel.nao_conformidades_inox.reduce(
          (Acum, curr) => {
            if (curr === value) return Acum;
            return [...Acum, curr];
          },
          [],
        );
      }
      this.updateCurrentMaterialModel('nao_conformidades_inox', newNaoConformidades);
    },
  },
};

</script>
