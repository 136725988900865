import { render, staticRenderFns } from "./EntradaEsterilizacao.vue?vue&type=template&id=540d0918&scoped=true&"
import script from "./EntradaEsterilizacao.vue?vue&type=script&lang=js&"
export * from "./EntradaEsterilizacao.vue?vue&type=script&lang=js&"
import style0 from "./EntradaEsterilizacao.vue?vue&type=style&index=0&id=540d0918&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../front-end/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "540d0918",
  null
  
)

export default component.exports