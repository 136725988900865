<template>
  <div>
    <div v-if="currentPage === 2">
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Instrumentador Responsável"
            label-for="instrumentador_input"
          >
            <b-form-input
              name="instrumentador_input"
              id="instrumentador_input"
              v-model="model.instrumentador"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <Separator />
      <LeituraMateriais
        :model="model"
        :isBusy="isBusy"
        :sendToModel="false"
        :config="config"
        withoutTitle
        :materiaisByProtocolos="materiaisByProtocolos"
        :beforeFinish="[
          { condition: materialNeedItemVerification, fn: checkAllCheckboxesBeforeFinish }
        ]"
      >
        <template v-if="materiaisByProtocolos.length" #tabela>
          <TabelaMateriaisProtocolo
            :isBusy="isBusy"
            :currentMaterialModel="currentMaterialModel"
            :materiaisByProtocolos="materiaisByProtocolos"
            :columns="['Descrição', 'ID', 'Peças', 'Qtd']"
            :columnsKeys="[
              'descricao',
              'shownId',
              'pecas',
              'qt_material_generico',
            ]"
            :model="model"
            :config="config"
            modalTitle="Informações do Preparo"
            preserveData
          />
        </template>
        <template #modal>
          <ModalProcesso
            title="Preparo do Material"
            preserveData
            :currentMaterialModel="currentMaterialModel"
            :config="config"
            :validationFields="[
              'equipamento_lavagem_automatizada',
              'colaborador_lavagem_automatizada',
              'lote_detergente_lavagem_automatizada',
              'naoFazerAutomatizada',
              'integrador_quimico',
              'observacoes',
              'justificativa',
              'quantidade_pecas',
              'tipo_embalagem',
              'lote',
              'usuario_checagem',
              'senha_checagem',
            ]"
            :beforeConfirm="[
              { condition: true, fn: confirmDuplaChecagem },
              { condition: currentMaterialModel.conferencia_pecas, fn: () => {
                const { current } = verifyAllChecked()
                return current
              }},
            ]"
          >
            <template #processo>
              <b-container>
                <b-row>
                  <b-col>
                    <InformacoesMaterial
                      :currentMaterialModel="currentMaterialModel"
                    />
                  </b-col>
                </b-row>
                <Separator />
                <b-row>
                  <b-col>
                    <TermoLavagemAuto
                      :isBusy="isBusy"
                      :currentMaterialModel="currentMaterialModel"
                    />
                  </b-col>
                </b-row>
                <Separator />
                <b-row>
                  <b-col>
                    <ItemCheck 
                      v-if="currentMaterialModel.conferencia_pecas"
                      :validateState="validateState"
                      :model="model"
                      :currentMaterialModel="currentMaterialModel"
                    />
                    <InformacoesPreparo
                      :isBusy="isBusy"
                      :currentMaterialModel="currentMaterialModel"
                      :readOnlyItemsQuantityInput="currentMaterialModel.conferencia_pecas"
                    />
                  </b-col>
                </b-row>
                <Separator v-if="currentMaterialModel.dupla_checagem" />
                <b-row>
                  <b-col>
                    <DuplaChecagem
                      :isBusy="isBusy"
                      :currentMaterialModel="currentMaterialModel"
                      v-if="currentMaterialModel.dupla_checagem"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </template>
          </ModalProcesso>
        </template>
      </LeituraMateriais>
    </div>
  </div>
</template>

<script>
import TabelaMateriaisProtocolo from "../../Components/TabelaMateriaisProtocolo";
import MaterialDetection from "../../Components/MaterialDetection";
import TermoLavagemAuto from "../../Components/TermoLavagemAuto";
import InformacoesPreparo from "../../Components/InformacoesPreparo";
import InformacoesMaterial from "../../Components/InformacoesMaterial";
import Separator from "../../../../../../front-end/src/components/Utils/Separator";
import LeituraMateriais from "../../Components/LeituraMateriais";
import ModalProcesso from "../../Components/ModalProcesso";
import DuplaChecagem from "../../Components/DuplaChecagem";
import ItemCheck from "../../Components/ItemCheck";
import config from "./config";

export default {
  name: "EntradaPreparo",
  components: {
    MaterialDetection,
    TabelaMateriaisProtocolo,
    InformacoesPreparo,
    TermoLavagemAuto,
    InformacoesMaterial,
    Separator,
    LeituraMateriais,
    ModalProcesso,
    DuplaChecagem,
    ItemCheck,
  },
  inject: [
    "materialNeedItemVerification",
    "verifyAllChecked",
    "getMateriaisByProtocolo",
    "preserveCurrentMaterialModel",
    "updateModel",
    "verifyUserCredentials",
    "validateState",
  ],
  props: {
    materiaisByProtocolos: {
      type: Array,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    isBusy: {
      type: Boolean,
    },
  },
  data() {
    return {
      config,
    };
  },
  mounted() {
    this.preserveCurrentMaterialModel({
      ...config.currentMaterialModelProcesso,
    });
  },
  computed: {
    conferenciaPecas() {
      return this.currentMaterialModel.conferencia_pecas
    },
    anyMaterialWithItemVerification() {
      return this.model.materiais.some(material => material.conferencia_pecas)
    },
    hasProtocoloRecebimento() {
      return (
        this.model.numProtocolosRecebimento &&
        this.model.numProtocolosRecebimento[0]
      );
    },
  },
  methods: {
    checkAllCheckboxesBeforeFinish() {
      const { all } = this.verifyAllChecked()
      if (all) {
        return true;
      }
      swal({
        title: 'Checagem de peças pendente',
        icon: "error",
        text: "Por favor faça a checagem de peças para poder continuar.",
        button: { confirm: "Ok..." },
      });
      return false
    },
    async confirmDuplaChecagem() {
      const error = (message) =>
        swal({
          title: "Error",
          text: message,
          icon: "error",
        });
      if (this.currentMaterialModel.dupla_checagem) {
        try {
          if (!this.currentMaterialModel.usuarioDuplaChecagem) {
            const {
              currentMaterialModel: {
                usuario_dupla_checagem,
                senha_dupla_checagem,
              },
            } = this;
            let user = await this.verifyUserCredentials({
              user: usuario_dupla_checagem,
              password: senha_dupla_checagem,
              permission: "rw_dupla_checagem",
            });

            if (!user) {
              throw error("Houve um problema ao obter os dados do usuario");
            } else if (user && !user.length) {
              throw error(
                "O usuário informado existe ou não possui permissões suficientes"
              );
            }
            this.currentMaterialModel.usuarioDuplaChecagem = user[0]
              ? user[0].nome
              : null;
          }
          this.currentMaterialModel.usuario_dupla_checagem &&
            delete this.currentMaterialModel.usuario_dupla_checagem;
          this.currentMaterialModel.senha_dupla_checagem &&
            delete this.currentMaterialModel.senha_dupla_checagem;

          return true;
        } catch (e) {
          return false;
        }
      } else {
        return true;
      }
    },
  },
  watch: {
    currentPage: {
      immediate: true,
      async handler(newPage, oldPage) {
        if (newPage === 2 && this.hasProtocoloRecebimento) {
          const { numProtocolosRecebimento } = this.model;

          await this.getMateriaisByProtocolo(numProtocolosRecebimento, {
            processoCheck: "preparo",
            validateFluxo: true,
            sub_setor: this.model.sub_setor,
            ignoreValidationResults: true,
            ignoreValidationProtocolo: true,
            id_unidade: this.model.id_unidade,   
          });
        }
      },
    },
  },
};
</script>
