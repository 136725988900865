<template>
  <div>
    <div v-if="currentPage === 2">
      <UnidadeSetorOrigem
        :model="model"
        :isBusy="isBusy"
        :unidadeOrigemSelf="currentUnidade.unidadeFilho.length === 0"
        needResponsavel
        :config="config"
      />
      <UnidadeSetorDestino
        :model="model"
        destinoSetorCme
        destinoCentral
      />
      <LeituraMateriais
        :isBusy="isBusy"
        :model="model"
        detalheMaterial
        sendToModel
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import MaterialDetectionPage from '../../Components/MaterialDetectionPage';
import UnidadeSetorOrigem from '../../Components/UnidadeSetorOrigem';
import UnidadeSetorDestino from '../../Components/UnidadeSetorDestino';
import LeituraMateriais from '../../Components/LeituraMateriais';
import config from './config'

export default {
  data() {
    return {
      config
    }
  },
  inject: [
    'parentValidator',
  ],
  created() {
    this.$validator = this.parentValidator;
  },
  components: {
    UnidadeSetorOrigem,
    MaterialDetectionPage,
    LeituraMateriais,
    UnidadeSetorDestino,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    numberOfPages: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapState(['currentUnidade']),
  },
};
</script>
