<template>
  <div class="modal-body">
    <div>
      <b-row class="input-p">
        <b-col sm="12" md="12" lg="12" xl="12">
          <span>Quantidade de peças cadastradas: {{ editingMaterial.quantidade_itens }}</span>
        </b-col>
      </b-row>
      <br>
      <b-row class="input-p">
        <b-col sm="12" md="12" lg="12" xl="12">
          <span>Quantidade de peças recebidas: {{ editingMaterial.quantidade_pecas }}</span>
        </b-col>
      </b-row>
      <br>
      <div v-if="editingMaterial.quantidade_pecas != editingMaterial.quantidade_itens
        && editingMaterial.quantidade_pecas != null"
      >
        <b-row class="input-p">
          <b-col sm="12" md="12" lg="12" xl="12">
            <span>Justificativa: {{ editingMaterial.justificativa }}</span>
          </b-col>
        </b-row>
        <br>
        <b-row class="input-p">
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form inline>
              <label class="mr-sm-2" for="inline-form-custom-select-pref">Reposição de peças faltantes:</label>
                <b-form-input
                  id="quantidade_pecas_faltantes_repostas"
                  type="number"
                  :value="editingMaterial.quantidade_pecas_faltantes_repostas"
                  autocomplete="off"
                  class="invision-input-smaller"
                  name="quantidade_pecas_faltantes_repostas"
                  min="0"
                  :disabled="isBusy"
                  v-validate={lessThan:[editingMaterial.quantidade_itens-editingMaterial.quantidade_pecas]}
                  :state="validateState('quantidade_pecas_faltantes_repostas')"
                  @input="
                    (v) => $emit('update-editing-material', 'quantidade_pecas_faltantes_repostas', v)
                  "
                ></b-form-input>
              peças
            </b-form>
          </b-col>
        </b-row>
        <br>
        <b-row v-if="editingMaterial.quantidade_pecas_faltantes_repostas > 0">
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group label="Observação*" label-for="observacao_pecas_transferidas">
              <b-form-input
                name="observacao_pecas_transferidas"
                :value="editingMaterial.observacao_pecas_transferidas"
                autocomplete="off"
                class="invision-input"
                :disabled="isBusy"
                v-validate="{ required: true }"
                :state="validateState('observacao_pecas_transferidas')"
                @input="
                  (v) => $emit('update-editing-material', 'observacao_pecas_transferidas', v)
                "
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </div>
    <hr>
    <div v-if="editingMaterial.materiais_defeituosos">
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12">
          <span>Quantidade de peças danificadas: {{ editingMaterial.quantidade_materiais_defeituosos }}</span>
        </b-col>
      </b-row>
      <br>
      <!--form interno - materiais defeituosos-->

      <div class="sub-form" v-if="editingMaterial.materiais_defeituosos">
        <b-row>
          <b-col sm="24" md="12" lg="12" xl="12">
            <!-- nivela o tamanho dos inputs para que sejam iguais-->
              Descrição do(s) defeito(s):
              <span
                v-for="(item, index) in editingMaterial.ids_defeitos"
                :key="index"
              > {{ allDefeitos[item] || item }};
              </span>
          </b-col>
        </b-row>
      </div>
      <br>
      <b-row class="input-p">
        <b-col sm="12" md="12" lg="12" xl="12">
          <b-form inline>
            <label class="mr-sm-2" for="inline-form-custom-select-pref">Reposição de peças danificadas:</label>
              <b-form-input
                id="quantidade_pecas_danificadas_repostas"
                type="number"
                :value="editingMaterial.quantidade_pecas_danificadas_repostas"
                autocomplete="off"
                class="invision-input-smaller"
                name="quantidade_pecas_danificadas_repostas"
                min="0"
                :disabled="isBusy"
                v-validate="{ lessThan:[editingMaterial.quantidade_materiais_defeituosos] }"
                :state="validateState('quantidade_pecas_danificadas_repostas')"
                @input="
                  (v) => $emit('update-editing-material', 'quantidade_pecas_danificadas_repostas', v)
                "
              ></b-form-input>
            peças
          </b-form>
        </b-col>
      </b-row>
      <br>
      <b-row v-if="editingMaterial.quantidade_pecas_danificadas_repostas > 0">
        <b-col sm="12" md="12" lg="12" xl="12">
          <b-form-group label="Observação*" label-for="observacao">
            <b-form-input
              name="observacao"
              :value="editingMaterial.observacao_pecas_danificadas"
              autocomplete="off"
              class="invision-input"
              :disabled="isBusy"
              :state="validateState('observacao')"
              @input="
                (v) => $emit('update-editing-material', 'observacao_pecas_danificadas', v)
              "
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <b-row align-h="end" class="pt-5">
      <b-col lg="2" xl="3">
        <Button
          text="Confirmar"
          @click="handleConfirmModal"
          :disabled="isBusy"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Button from '../../../../../front-end/src/components/Utils/Button';

export default {
  inject: ['parentValidator', 'validateState', 'updateModel'],
  created() {
    this.$validator = this.parentValidator;
  },
  components: {
    Button,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    editingMaterial: {
      type: Object,
      required: true,
    },
    allDefeitos: {
      required: false,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    async handleConfirmModal() {
      if (this.editingMaterial.quantidade_pecas !== this.editingMaterial.quantidade_itens
        && this.editingMaterial.quantidade_pecas != null
        && this.editingMaterial.quantidade_pecas_faltantes_repostas
          <= this.editingMaterial.quantidade_itens - this.editingMaterial.quantidade_pecas
      ) {
        const qtd_pecas = parseInt(this.editingMaterial.quantidade_pecas, 10)
          + parseInt(this.editingMaterial.quantidade_pecas_faltantes_repostas, 10);
        this.$emit('update-editing-material', 'quantidade_pecas', qtd_pecas);
      }
      const { index, ...newMaterialData } = this.editingMaterial;
      if (newMaterialData.quantidade_pecas === newMaterialData.quantidade_itens) {
        newMaterialData.justificativa = '';
      }

      const result = await this.$validator.validateAll();
      if (result) {
        this.updateModel('materiais', [
          ...this.model.materiais.slice(0, index),
          { ...this.model.materiais[index], ...newMaterialData, isEdited: true },
          ...this.model.materiais.slice(index + 1),
        ]);
        this.$emit('hide-modal');
      }
    },
  },
};
</script>

<style scoped>
  .modal-body {
    padding: 1.5rem;
  }
  .invision-input-smaller {
    max-width: 6rem;
    margin-right: 0.5rem;
  }
</style>
