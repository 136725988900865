<template>
  <div>
    <div v-if="currentPage == 2">
      <b-container v-if="!model.numProtocolosRecebimento">
        <UnidadeSetorOrigem
          :model="model"
          :isBusy="isBusy"
          origemSetorCme
        />
        <LeituraMateriais
          :isBusy="isBusy"
          :model="model"
          detalheMaterial
        />
      </b-container>
      <TabelaMateriaisTransferencia 
        v-else 
        :isBusy="isBusy"
        :materiaisByProtocolos="materiaisByProtocolos"
        :model="model"
        :currentMaterialModel="currentMaterialModel"
        typeProt="Protocolo"
        :columns="['ID', 'Tipo Artigo', 'Qtd.']"
        :columnsKeys="['shownId', 'tipoMaterial', 'qt_material_generico']"
        hasOptions
        enableMaterialStatus
      />
      <Buttons 
        v-if="model.numProtocolosRecebimento && model.numProtocolosRecebimento.length"
        :isBusy="isBusy"
        :currentPage="currentPage"
        :numberOfPages="numberOfPages"
      />
    </div>
  </div>
</template>

<script>
import UnidadeSetorOrigem from '../../Components/UnidadeSetorOrigem';
import LeituraMateriais from '../../Components/LeituraMateriais';
import TabelaMateriaisTransferencia from '../../Components/TabelaMateriaisTransferencia'
import Buttons from '../../Components/Buttons'
import config from './config'

export default {
  name: 'RecebimentoArsenal',
  components: {
    UnidadeSetorOrigem,
    LeituraMateriais,
    TabelaMateriaisTransferencia,
    Buttons
  },
  inject: [
    'parentValidator',
    'updateModel',
    'addMaterialToModel',
    'getMateriaisByProtocolo'
  ],
  data() {
    return {
      config,
      id_unidade_origem: null
    }
  },
  async created() {
    this.$validator = this.parentValidator;
    if(this.model.numProtocolosRecebimento) {
      await this.getMateriaisByProtocolo(
        this.model.numProtocolosRecebimento, 
        {
          processoCheck: 'transferenciaExpurgo',
          validateFluxo: true,
          sub_setor: this.model.sub_setor,
          id_unidade: this.model.id_unidade,
          ignoreValidationResults: true,
          sendBackFullMaterialData: true,
        }
      )
    }
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    numberOfPages: {
      type: Number,
      required: true,
    },
    materiaisByProtocolos: {
      type: Array,
      required: true
    }
  },
  watch: {
    materiaisByProtocolos(arr) {
      const { genericData: { unidade } } = this.$store.state
      const unidade_origem_protocolo = arr[0]['unidadeOrigem']
      const setor_origem_protocolo = arr[0]['setorOrigem']

      const unidade_origem = unidade_origem_protocolo && unidade.find(unidade => unidade.nome === unidade_origem_protocolo)
      const setor_origem = unidade_origem && unidade_origem.setor.find(setor => setor.nome.includes(setor_origem_protocolo))

      const id_unidade_origem = unidade_origem ? unidade_origem.id_unidade : null
      const id_setor_origem = setor_origem ? setor_origem.id_setor : null

      if(!this.model.id_unidade_origem) {
        this.model.id_unidade_origem = id_unidade_origem
        this.model.id_setor_origem = id_setor_origem
      }
    }
  }
};
</script>
