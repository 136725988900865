<template>
  <div class="etiqueta pt-3">
    <b-row >
      <b-col sm="5" md="5" lg="5" xl="5">
        <div>
          <img class="pb-3" src="/logo.png" alt="" width="150px"/>
        </div>
        <div id="qrCode" class="text-center pb-4">
          <qrcode-vue :value="etiquetaData.code" level="H" class="qr-code" renderAs="svg"></qrcode-vue>
        </div>
        </b-col>
      <b-col class="pt-5" sm="7" md="7" lg="7" xl="7">
      <div v-if="etiquetaData">
        Setor: <b>{{ etiquetaData.setor }}</b>
        <br />
        Descrição: <b>{{ etiquetaData.descricao }}</b>
        <br />
        Peças: <b>{{ etiquetaData.pecas }}</b>
        <br />
        Data/Hora: {{ etiquetaData.data }}
        <br />
        Responsável: {{ etiquetaData.responsavel }}
        <br />
      </div>
      <div v-else>
        Ocorreu um problema ao gerar a etiqueta
      </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';

export default {
  props: {
    relatorioData: {
      type: Object,
      default() {
        return {};
      },
    },
    indexMaterial: {
      type: Number,
      default: 0,
    },
  },

  components: {
    QrcodeVue,
  },

  computed: {
    etiquetaData() {
      return this.relatorioData.materiais[this.indexMaterial];
    },
  },
};
</script>

<style scoped>
.etiqueta {
  border-top: 10px solid #209F85;
  margin-left: 1cm !important;
}
</style>
