import { render, staticRenderFns } from "./SaidaSetor.vue?vue&type=template&id=53b748c0&scoped=true&"
import script from "./SaidaSetor.vue?vue&type=script&lang=js&"
export * from "./SaidaSetor.vue?vue&type=script&lang=js&"
import style0 from "./SaidaSetor.vue?vue&type=style&index=0&id=53b748c0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../front-end/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53b748c0",
  null
  
)

export default component.exports