var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',{staticClass:"input-p"},[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label":"Quantidade de peças*","label-for":"quantidade_pecas"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({betweenFiftyPorcentLessAndGreater:[_vm.currentMaterialModel.pecas]}),expression:"{betweenFiftyPorcentLessAndGreater:[currentMaterialModel.pecas]}"}],staticClass:"invision-input",attrs:{"id":"quantidade_pecas","type":"number","value":_vm.currentMaterialModel.quantidade_pecas,"autocomplete":"off","name":"quantidade_pecas","min":"1","disabled":_vm.isBusy,"state":_vm.validateState('quantidade_pecas')},on:{"input":_vm.handleChangeQtdPecas}})],1)],1)],1),(
      _vm.currentMaterialModel.quantidade_pecas != _vm.currentMaterialModel.pecas
      && _vm.currentMaterialModel.quantidade_pecas != null
    )?_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label":"Justificativa*","label-for":"justificativa"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],staticClass:"invision-input",attrs:{"name":"justificativa","id":"justificativa","value":_vm.currentMaterialModel.justificativa,"autocomplete":"off","disabled":_vm.isBusy,"state":_vm.validateState('justificativa')},on:{"input":_vm.handleChangeJustificativa}})],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('YesOrNoRadio',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"name":"pecas_defeituosas","id":"pecas_defeituosas","label":"Presença de peças danificadas","value":_vm.currentMaterialModel.pecas_defeituosas,"disabled":_vm.isBusy,"state":_vm.validateState('pecas_defeituosas')},on:{"input":function (v) { return _vm.updateCurrentMaterialModel('pecas_defeituosas', v); }}})],1),(_vm.currentMaterialModel.pecas_defeituosas)?_c('b-container',{staticClass:"sub-form"},[_c('b-row',[(
            _vm.currentMaterialModel.pecas_defeituosas
          )?_c('b-col',{attrs:{"sm":"24","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label":"Quantidade de peças danificadas*","label-for":"qtd_pecas_defeituosas"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:(
                ("required|between:1," + (_vm.currentMaterialModel.pecas))
              ),expression:"\n                `required|between:1,${currentMaterialModel.pecas}`\n              "}],staticClass:"invision-input",attrs:{"id":"qtd_pecas_defeituosas","type":"number","value":_vm.currentMaterialModel.qtd_pecas_defeituosas,"autocomplete":"off","name":"qtd_pecas_defeituosas","disabled":_vm.isBusy,"min":"1","state":_vm.validateState('qtd_pecas_defeituosas')},on:{"input":_vm.handleChangeQtdPecasDefeituosas}})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"sm":"24","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label":_vm.currentMaterialModel.qtd_pecas_defeituosas > 1
                ? 'Descrição*'
                : 'Descrição*',"label-for":"descricao_materiais_defeituosos"}},[_vm._l((_vm.currentMaterialModel.ids_defeitos),function(item,index){return _c('GenericSelect',{directives:[{name:"validate",rawName:"v-validate",value:({
                required: _vm.currentMaterialModel.pecas_defeituosas
                  ? true
                  : false,
              }),expression:"{\n                required: currentMaterialModel.pecas_defeituosas\n                  ? true\n                  : false,\n              }"}],key:index,staticClass:"invision-select mt-1",attrs:{"name":("descricao_pecas_defeituosas-" + index),"autocomplete":"off","maxlength":"500","labelKey":"nome","value":_vm.currentMaterialModel.ids_defeitos[index],"route":"tipoDefeitoMaterial","disabled":_vm.isBusy,"state":_vm.validateState(("descricao_pecas_defeituosas-" + index))},on:{"input":function (v) { return _vm.inputCurrentMaterialArray('ids_defeitos', index, v); }}})}),(
                _vm.currentMaterialModel.qtd_pecas_defeituosas > 1 &&
                _vm.currentMaterialModel.ids_defeitos.length <
                _vm.currentMaterialModel.qtd_pecas_defeituosas
              )?_c('Button',{staticClass:"mt-3",attrs:{"text":"Adicionar Descrição","variant":"secondary","disabled":_vm.isBusy},on:{"click":_vm.addDescricaoDefeitos}}):_vm._e()],2)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }