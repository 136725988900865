var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.currentPage === 2)?_c('div',[_c('UnidadeSetorDestino',{attrs:{"model":_vm.model,"unidadeDestinoSelf":_vm.currentUnidade.unidadeFilho.length === 0,"needResponsavel":"","config":_vm.config,"isBusy":_vm.isBusy}}),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-checkbox',{attrs:{"disabled":_vm.isBusy || !_vm.model.id_setor_destino},on:{"change":_vm.updateHandleKeyUpEnter},model:{value:(_vm.usingVoucherRecebimento),callback:function ($$v) {_vm.usingVoucherRecebimento=$$v},expression:"usingVoucherRecebimento"}},[_vm._v(" Liberar materiais utilizando o protocolo de recebimento entregue para o setor no recebimento do expurgo (voucher). ")]),(_vm.usingVoucherRecebimento)?_c('b-form-checkbox',{staticClass:"manually-checkbox",model:{value:(_vm.enableInputTags),callback:function ($$v) {_vm.enableInputTags=$$v},expression:"enableInputTags"}},[_vm._v(" Habilitar campo para preenchimento manual. ")]):_vm._e()],1)],1),_c('br'),(_vm.usingVoucherRecebimento)?_c('b-row',[(_vm.isBusy)?_c('b-spinner',{staticClass:"spinner"}):_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[(_vm.model.numProtocolosPendentes && _vm.enableSelectInput)?_c('div',[_c('h6',[_vm._v("Lista de vouchers de recebimento")]),_c('b-form-select',{attrs:{"value":_vm.selected,"options":_vm.model.numProtocolosPendentes.map(function (voucher) { return ({
                value: voucher,
                text: voucher,
              }); })},on:{"input":_vm.handleSelectChange}})],1):(!_vm.enableSelectInput && _vm.enableInputTags)?_c('div',[_c('InputTags',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],ref:"inputTagsVouchers",attrs:{"label":"Número do(s) voucher(s) de recebimento","id":"inputTagsVouchers","name":"inputTagsVouchers","placeholder":"Inserir voucher...","value":_vm.model.numProtocolosRecebimento,"isBusy":_vm.isBusy,"state":_vm.validateState('inputTagsVouchers')},on:{"input":_vm.handleVouchersChange},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('div',[_vm._v(" O voucher inserido deve começar com 'ER', que sinaliza recebimento. As teclas ENTER, vírgula e ESPAÇO inserem um novo voucher. Você pode inserir quantos quiser, desde que sejam válidos. ")])]},proxy:true}],null,false,1009771607)})],1):_vm._e()])],1):_vm._e()],1):(_vm.currentPage === 3)?_c('div',[_c('LeituraMateriais',{attrs:{"model":_vm.model,"isBusy":_vm.isBusy,"withoutTitle":"","detalheMaterial":"","config":_vm.config,"disableInitFocus":false,"enableItemTooltip":""},scopedSlots:_vm._u([(
          this.usingVoucherRecebimento &&
          this.model.numProtocolosRecebimento &&
          this.model.numProtocolosRecebimento.length
        )?{key:"tabela",fn:function(){return [_c('TabelaMateriaisProtocolo',{attrs:{"model":_vm.model,"currentMaterialModel":_vm.currentMaterialModel,"columns":['Descrição', 'ID', 'Peças', 'Qtd'],"columnsKeys":[
            'descricao',
            'shownId',
            'pecas',
            'qt_material_generico' ],"isBusy":_vm.isBusy,"materiaisByProtocolos":_vm.materiaisByProtocolos,"config":_vm.config,"typeProt":"Voucher","enableItemTooltip":""}})]},proxy:true}:null],null,true)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }