<template>
    <b-container>
      <b-row>
        <b-col>
          <p class="text-color">Material em processamento</p>
        </b-col>
      </b-row>
      <section id="material-section" class="mt-1">
        <b-row>
          <b-col md="12">
            <p>{{ currentMaterialModel.descricao }}</p>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col md="4">
            <span class="info-key">
              ID
            </span>
          </b-col>
          <b-col md="4">
            <span class="info-key">
              N° PEÇAS
            </span>
          </b-col>
          <b-col md="4">
            <span class="info-key">
              TIPO
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <span id="id-material" class="data-field">
              {{ currentMaterialModel.shownId }}
            </span>
          </b-col>
          <b-col md="4">
            <span class="data-field">
              {{ currentMaterialModel.quantidade_itens || currentMaterialModel.pecas }}
            </span>
          </b-col>
          <b-col md="4">
            <span class="data-field">
              {{
                (currentMaterialModel.tipoMaterial && currentMaterialModel.tipoMaterial.nome)
                || (currentMaterialModel.tipoMaterialGenerico && currentMaterialModel.tipoMaterialGenerico.nome)
              }}
            </span>
          </b-col>
        </b-row>
      </section>
    </b-container>
</template>

<script>
export default {
  name: 'InformacoesMaterial',
  props: {
    currentMaterialModel: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
  #material-section {
    background: #f7f7fa;
    border: 1px dashed #afb4d6;
    padding: 25px;
    border-radius: 5px;
  }
  .text-color {
    color: #35384D;
  }
  .info-key {
    color: #7E829F;
  }
  #id-material {
    color: #21CCA9;
  }
</style>
