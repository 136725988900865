export default {
  enableMaterialRede: true,
  customButtonPages: [2],
  showBannerPages: [],
  underListPages: [],
  tabelaMateriaisPage: null,
  ensureCurrentItemQuantity: true,
  modelProcesso: {
    id_motivo_retorno: null,
  },
  currentMaterialModelProcesso: {},
  pageList: [
    {
      title: 'Leitura dos materiais',
      subItems: [
        { title: 'Responsável retirada' },
      ],
    },
  ],
};
