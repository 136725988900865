<template>
  <div id="section">
    <div id="content">
      <div class="tela">
        <div class="header-rastreabilidade">
          <h1>Rastreabilidade de Material</h1>
        </div>
        <b-row>
          <b-col sm="12" md="12" lg="9" xl="9" v-if="loading">
            <b-col>
              <b-row class="mt-4 mr-4">
                <b-col cols="5" class="barra-skeleton">
                  <tb-skeleton
                    shape="rect"
                    style="background-color: #dcdcdc; height: 2rem; width: 100%"
                    theme="opacity"
                  ></tb-skeleton>
                </b-col>
              </b-row>
              <div class="barra-skeleton">
                <b-row class="mr-4" v-for="i in 3" :key="i">
                  <b-col>
                    <tb-skeleton
                      shape="rect"
                      style="
                        background-color: #dcdcdc;
                        height: 2rem;
                        width: 100%;
                      "
                      theme="opacity"
                    ></tb-skeleton>
                  </b-col>
                </b-row>
              </div>
              <div class="barra-skeleton">
                <b-row class="mr-4" v-for="j in 3" :key="j">
                  <b-col>
                    <tb-skeleton
                      shape="rect"
                      style="
                        background-color: #dcdcdc;
                        height: 2rem;
                        width: 100%;
                      "
                      theme="opacity"
                    ></tb-skeleton>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-col>
          <b-col sm="12" md="12" lg="9" xl="9" v-else>
            <b-row
              v-if="material.limiteEsterilizacaoesExcedido"
              class="mt-4 mr-4"
            >
              <b-col>
                <b-alert show variant="danger" dismissible>
                  <b-row>
                    <AlertCircleIcon class="mr-3" />
                    <span
                      >Este material atingiu o limite de esterilizações que
                      podem ser realizadas.</span
                    >
                  </b-row>
                </b-alert>
              </b-col>
            </b-row>
            <b-row v-if="showInAnotherMaterial" class="mt-4 mr-4">
              <b-col>
                <div class="card-in-another-material">
                  <span style="align-self: center">
                    Este material faz parte de um grupo de materiais existentes
                  </span>
                  <div>
                    <Button
                      v-if="!isBusy"
                      type="outline"
                      text="Fechar"
                      @click="closeInAnotherMaterial"
                    />
                    <Button
                      class="ml-2"
                      text="Visualizar"
                      @click="goToMaterial"
                      :loading="isBusy"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-3 mr-4">
              <b-col cols="6">
                <Button
                  v-if="
                    !currentCiclo.id_lote_saida &&
                    currentCiclo &&
                    currentCiclo.id_ciclo &&
                    canForceCiclo
                  "
                  variant="danger"
                  @click="showForceEndModal"
                  text="Forçar Encerramento de Ciclo"
                />
              </b-col>
              <b-col cols="6" offset="6" class="right-buttons mt-4">
                <b-button
                  @click="buscarAlteracoes"
                  type="button"
                  variant="none"
                  class="buttonAlt"
                  v-b-tooltip.hover.top="'Alterações do material'"
                  v-b-modal.modal-xl
                >
                  <b-row>
                    <b-col cols="2"
                      ><list-icon class="iconAlt"></list-icon
                    ></b-col>
                    Registro de Alterações
                    <b-col
                      ><chevron-right-icon
                        cols="2"
                        class="iconAlt"
                      ></chevron-right-icon
                    ></b-col>
                  </b-row>
                </b-button>
                <Button
                  text="Alterar empréstimo"
                  type="button"
                  variant="none"
                  @click="editEmprestimo"
                  class="ml-2 alterarEmprestimo iconAlt"
                  v-if="cicloCanChangeEmprestimo"
                />
                <b-col class="refresh-cols">
                  <b-button
                    type="button"
                    variant="white"
                    class="buttonAlt"
                    id="refresh-button"
                    v-b-tooltip.hover.top="'Atualizar tabela de processos'"
                    @click="refreshProcessTable"
                  >
                    <refresh-cw-icon class="w20px" />
                  </b-button>
                </b-col>
                <div v-if="numberOfCiclos > 0 && !isBusy" class="buttonAlt">
                  <!-- Botão imprimir -->
                  <b-dropdown
                    right
                    variant="grayscale"
                    toggle-class="custom-toggle-rastreabilidade"
                    no-caret
                  >
                    <b-dropdown-item
                      @click="getRelatorioCiclosByPrintOptions('all')"
                      >Todos</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="getRelatorioCiclosByPrintOptions('lastCiclo')"
                      >Último Ciclo</b-dropdown-item
                    >
                    <b-dropdown-item @click="showRangeDatePicker"
                      >Período</b-dropdown-item
                    >
                    <template #button-content>
                      <printer-icon
                        v-b-tooltip.hover.top="'Opções de impressão'"
                        id="print-button"
                        class="w30px mouse align-middle"
                      />
                    </template>
                  </b-dropdown>
                  <RangeDatePicker
                    :initDate="initDate"
                    :endDate="endDate"
                    :isBusy="isBusy"
                    @selected="rangeDateSelected"
                    :hideHeader="false"
                    :showButton="true"
                    :showInput="false"
                    ref="rangeDatePicker"
                    class="RangeDatePicker-in-graph"
                    inputClass="small"
                    @confirm-range-date="
                      getRelatorioCiclosByPrintOptions('period')
                    "
                  />
                </div>
              </b-col>
            </b-row>
            <b-row class="mr-4">
              <b-col>
                <Protocolos
                  :currentCiclo="currentCiclo"
                  :currentOrder="currentOrder"
                  :numberOfCiclos="numberOfCiclos"
                  :material="material"
                  @change-ciclo="changeCiclo"
                />
              </b-col>
            </b-row>
            <b-row class="mr-4">
              <b-col>
                <TabelaProcessos
                  :currentCiclo="currentCiclo"
                  :id_material="+$route.params.id"
                  @esterilizacaoDesinf="editEsterilizacaoDesinf"
                  @emprestimo="editEmprestimo"
                  :idProcessoCicloBusy="idProcessoCicloBusy"
                />
              </b-col>
            </b-row>
          </b-col>

          <b-col
            sm="12"
            md="12"
            lg="9"
            xl="9"
            v-if="!currentCiclo.id_ciclo && !loading"
          >
            <div class="info-ciclo-card card invision-card borderless p-card">
              <h5 class="d-flex">
                Este material ainda não iniciou nenhum ciclo
              </h5>
            </div>
          </b-col>
          <b-col sm="12" md="12" lg="3" xl="3" class="qrcode">
            <QrCodePrinter id="qrCodePrinter" :material="material" />
          </b-col>
        </b-row>
        <Modal
          id="modal-xl"
          size="xl"
          title="Registro de Alterações do Material"
          centered
          scrollable
          hide-backdrop
        >
          <h5 v-if="!modelAlteracoes.length">
            Não existem alterações registradas para esse material
          </h5>
          <b-row v-else-if="isBusy" class="mt-4 mr-4">
            <b-col cols="5" class="barra-skeleton">
              <tb-skeleton
                shape="rect"
                style="background-color: #dcdcdc; height: 2rem; width: 100%"
                theme="opacity"
              ></tb-skeleton>
            </b-col>
          </b-row>
          <b-table
            v-else
            :items="modelAlteracoes"
            bordered
            outlined
            hover
            responsive
          ></b-table>
        </Modal>
      </div>
      <Modal
        id="modalRelatorio"
        ref="modalRelatorio"
        name="modalRelatorio"
        key="modalRelatorio"
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        :size="tipoRelatorio == 'Relatorio' ? 'lg' : 'md'"
        @hidden="handleHideModal"
      >
        <h5 v-if="tipoRelatorio != 'Relatorio'">
          {{ relatorioData.siclaProtocolo }}
        </h5>
        <component
          v-bind:is="tipoRelatorio"
          buttonName="IMPRIMIR"
          hideSection="section"
          hideContent="content"
          hideId="qrCodePrinter"
          fixedContent
          :stringZebra="
            relatorioData &&
            relatorioData.materiais &&
            relatorioData.materiais[0] &&
            relatorioData.materiais[0].stringZebra
          "
        >
          <template #printContent class="w">
            <component
              v-bind:is="currentComponentToReport[tipoRelatorio]"
              :relatorioData="relatorioData"
            />
          </template>
        </component>
      </Modal>
      <Modal
        id="modalEsterilizacaoDesinf"
        ref="modalEsterilizacaoDesinf"
        name="modalEsterilizacaoDesinf"
        no-close-on-esc
        no-close-on-backdrop
        size="md"
        title="Editar Esterilização"
      >
        <b-row class="justify-content-md-center">
          <b-col>
            <b-form-group label="Equipamento*" label-for="equipamento">
              <GenericSelect
                ref="equipamento"
                name="equipamento"
                id="equipamento"
                labelKey="nome"
                :value="modelEsterilizacaoDesinf.id_equipamento"
                :customFilters="equipamentoEsterilizacaoFilter"
                :state="validateState('equipamento')"
                v-validate="{ required: true }"
                route="equipamento"
                :disabled="isBusy"
                @input="
                  (idEquipamento) =>
                    (modelEsterilizacaoDesinf.id_equipamento = idEquipamento)
                "
              ></GenericSelect>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <b-form-group label="Lote*" label-for="lote">
              <b-form-input
                id="lote"
                name="lote"
                ref="lote"
                v-model="modelEsterilizacaoDesinf.lote"
                :state="validateState('lote')"
                v-validate="{ required: true }"
                autocomplete="off"
                class="invision-input"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <b-form-checkbox
              id="editAllSameLote"
              name="editAllSameLote"
              v-model="modelEsterilizacaoDesinf.editAllSameLote"
              :state="validateState('editAllSameLote')"
            >
              Editar TODOS de mesmo lote
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col>
            <b-button
              type="button"
              variant="none"
              :disabled="isBusy"
              class="text-white primary-contained-button"
              @click="saveEsterilizacaoDesinf"
            >
              <span v-if="!isBusy">Confirmar</span>
              <div v-else>
                <b-spinner small></b-spinner>
                Aguarde...
              </div>
            </b-button>
          </b-col>
        </b-row>
      </Modal>
      <Modal
        ref="modalEncerramentoCiclo"
        title="Forçar encerramento do ciclo"
        @hidden="hidden"
        no-close-on-esc
        no-close-on-backdrop
        size="lg"
        centered
      >
        <b-container v-if="wantsToForceEndCicle">
          <div class="text-confirmation text-center">
            <div>
              Deseja realmente
              <span class="span-text">forçar o encerramento</span>
            </div>
            <div>
              <span class="span-text"> do ciclo do material a seguir?</span>
            </div>
          </div>
          <br />
          <div class="main-content">
            <b-row>
              <b-col>
                <b-row> {{ descricaoMaterial }} </b-row>
                <b-row v-if="id"> ID </b-row>
                <b-row v-if="id" class="span-text"> {{ id }} </b-row>
              </b-col>
              <b-col>
                <b-row>
                  <label class="mr-2">Última área:</label>
                  <div>
                    {{ this.labelsEncerramento.ultimaArea }}
                  </div>
                </b-row>
                <b-row>
                  <label class="mr-2">Último processo:</label>
                  <div>
                    {{ this.labelsEncerramento.ultimoProcesso }}
                  </div>
                </b-row>
                <b-row>
                  <label class="mr-2">Data:</label>
                  <div>
                    {{ this.labelsEncerramento.data }}
                  </div>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <br />
          <br />
          <div>
            <b-form-group label="Justificativa*">
              <b-form-input
                id="justificativa"
                v-model="justificativa"
                type="text"
                class="invision-input complement-input"
                autocomplete="off"
                name="justificativa"
                :state="justificativaState"
              />
            </b-form-group>
            <div class="text-right">
              <b-button
                @click="endCiclo"
                type="button"
                class="primary-contained-button"
                >Confirmar</b-button
              >
            </div>
          </div>
        </b-container>
      </Modal>
      <Modal
        id="modalRelatorioByPrintOptions"
        ref="modalRelatorioByPrintOptions"
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        size="lg"
      >
        <PrinterRelatorio
          buttonName="IMPRIMIR"
          hideSection="section"
          hideContent="content"
        >
          <template #printContent class="w">
            <RelatorioGeral :cicloData="cicloData" />
          </template>
        </PrinterRelatorio>
      </Modal>
      <ModalUnidadeEmprestimo
        ref="modalEmprestimo"
        :id_unidade_emprestimo="modelEmprestimo.id_unidade_emprestimo"
        :currentCiclo="currentCiclo"
        :currentProcessoCiclo="currentProcessoCiclo"
        @update-unidade-emprestimo="updateUnidadeEmprestimo"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import { ListIcon, ChevronRightIcon, ChevronDownIcon } from 'vue-feather-icons';
import GenericApi from '@/services/genericRequest';
import unidadeService from '@/services/unidade';

import Printer from '@/components/Utils/Printer';
import PrinterRelatorio from '@/components/Utils/PrinterRelatorio';
import Modal from '@/components/Utils/Modal';
import Button from '@/components/Utils/Button';
import RangeDatePicker from '@/components/Form/RangeDatePicker';

import { firstUpperCase } from '@/helpers/common';

import GenericSelect from '../../../components/Form/GenericSelect';
import TemplateBase from '../../../templates/Base';
import TwoCards from '../../../templates/TwoCards';
import QrCodePrinter from './QrCodePrinter';
import Protocolos from './Protocolos';
import TabelaProcessos from './TabelaProcessos';
import ModalUnidadeEmprestimo from './ModalUnidadeEmprestimo';
import RelatorioGeral from './Relatorio';
import loginService from '@/services/login';

// TODO Remover hardcoded
// A deve-se obter o tipo de material e o tipo processo
// entao obter o processo do GerenciadorProcessos e chamar o metodo correto para se obter a etiqueta
// eslint-disable-next-line import/no-dynamic-require
const TiposProcessos = require(
  `../../../../../projects/${process.env.VUE_APP_PROJECT}/front-end/Processamento/TiposProcessos`,
);

export default {
  components: {
    TwoCards,
    QrCodePrinter,
    GenericSelect,
    TemplateBase,
    Protocolos,
    TabelaProcessos,
    Etiqueta: Printer,
    Relatorio: PrinterRelatorio,
    RelatorioGeral,
    PrinterRelatorio,
    Modal,
    Button,
    RangeDatePicker,
    ModalUnidadeEmprestimo,
    ListIcon,
    ChevronRightIcon,
    ChevronDownIcon,
  },

  inject: ['parentValidator'],
  created() {
    this.$validator = this.parentValidator;
  },

  computed: {
    justificativaState() {
      return Boolean(this.justificativa);
    },
    descricaoMaterial() {
      if (this) {
        return this.material.descricao;
      }

      return '';
    },

    id() {
      if (this) {
        return this.material.id || `T${this.material.id_material}`;
      }

      return '';
    },
    currentComponentToReport() {
      return TiposProcessos.default[firstUpperCase(this.tipoProcesso)];
    },
    cicloCanChangeEmprestimo() {
      if (this.currentCiclo.id_lote_saida) return false;
      if (this.parametroDestino) return true;
      return false;
    },
    parametroDestino() {
      if (!this.currentCiclo || !this.currentCiclo.parametrosCiclo) return null;
      return this.currentCiclo.parametrosCiclo.find(
        (parametro) => parametro.chave === 'id_unidade_destino_final',
      );
    },
  },

  data() {
    return {
      cicloData: {},
      labelsEncerramento: {},
      material: {},
      wantsToForceEndCicle: false,
      justificativa: '',
      id_material: this.$route.params.id,
      uuid: '',
      labelQr: '',
      ciclos: [],
      numberOfCiclos: 0,
      currentOrder: 1,
      currentCiclo: {},
      currentProcessoCiclo: {},
      relatorioData: {},
      tipoProcesso: '',
      tipoRelatorio: 'Relatorio', // relatorio ou etiqueta
      idFormularioEsterilizacaoDesinf: null,
      modelEsterilizacaoDesinf: {
        lote: '',
        id_equipamento: null,
        editAllSameLote: false,
      },
      modelEmprestimo: {
        id_unidade_emprestimo: null,
      },
      equipamentoEsterilizacaoFilter: {},
      showInAnotherMaterial: false,
      isBusy: false,
      idProcessoCicloBusy: null,
      initDate: moment().startOf('month'),
      endDate: moment().subtract(1, 'day').endOf('day'),
      loading: true,
      historicoMaterialRoute: `material/rastreabilidade/alteracoes/${this.$route.params.id}`,
      modelAlteracoes: [],
      canForceCiclo: loginService.verifyPermissions(['rd_encerrar_ciclo']),
    };
  },
  mounted() {
    this.initData();
  },

  watch: {
    currentComponentToReport() {
      this.tipoRelatorio = this.currentComponentToReport.Relatorio
        ? 'Relatorio'
        : 'Etiqueta';
    },
    '$route.params.id': {
      handler(v) {
        this.id_material = v;
        this.initData();
      },
    },
  },

  methods: {
    async buscarAlteracoes() {
      this.isBusy = true;
      this.historicoMaterialRoute = `material/rastreabilidade/alteracoes/${this.material.id_material}`;
      const alteracoes = await GenericApi.getWithoutPagination(
        {},
        this.historicoMaterialRoute,
      );
      this.modelAlteracoes = alteracoes;
      this.isBusy = false;
      return alteracoes;
    },
    refreshProcessTable() {
      this.initData();
    },

    async initData() {
      await this.updateMaterial();
      await this.getIdCiclos();

      this.numberOfCiclos = this.ciclos.length;
      if (this.numberOfCiclos) {
        await this.getProcessosByOrder();
        this.labelsEncerramento.ultimaArea = this.currentCiclo.processoCiclo[
          this.currentCiclo.processoCiclo.length - 1
        ].subSetor.nome;
        this.labelsEncerramento.ultimoProcesso = this.currentCiclo.processoCiclo[
          this.currentCiclo.processoCiclo.length - 1
        ].tipoProcesso.nome;
        this.labelsEncerramento.data = `${moment(this.currentCiclo.processoCiclo[
          this.currentCiclo.processoCiclo.length - 1
        ].updatedAt).format('DD/MM/YY à\\s HH:mm:ss')}`;
      } else {
        this.loading = false;
      }
    },

    showRangeDatePicker() {
      this.$refs.rangeDatePicker.open();
    },

    rangeDateSelected(initDate, endDate) {
      this.initDate = initDate;
      this.endDate = endDate;
    },

    async getRelatorioCiclosByPrintOptions(state) {
      try {
        this.isBusy = true;
        const route = 'ciclo/rastreabilidade/getCiclosByOptions';
        const params = {
          option: state,
          initDate: this.initDate,
          endDate: this.endDate,
          id_material: this.material.id_material,
          id_tipo_material: this.material.id_tipo_material,
        };
        const result = await GenericApi.getAllWithoutPagination(params, route);
        this.isBusy = false;
        this.cicloData.body = result;
        this.cicloData.header = {
          descricao: this.material.descricao,
          id: this.material.id || '',
          tipoMaterial: this.material.tipoMaterial
            ? this.material.tipoMaterial.nome
            : '-',
          nCiclos: result.length,
          unidade: await unidadeService.getNomeUnidade(this.material),
          setor: this.material.setor ? this.material.setor.nome : '-',
        };
        this.$refs.modalRelatorioByPrintOptions.show();
      } catch (error) {
        this.isBusy = false;
        let errorMessage;
        if (
          error.response
          && error.response.data
          && error.response.data.error
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }
        swal({
          title: 'Oops!',
          text:
            errorMessage
            || 'Ocorreu um problema ao recuperar os ciclos do material',
          icon: 'warning',
          button: 'Continuar',
        });
      }
    },

    async updateMaterial() {
      this.isBusy = true;
      this.clearHistoryMaterial();
      try {
        this.material = await GenericApi.getOne(this.id_material, 'material');
        if (
          this.material.itemAvulso
          && this.material.itemAvulso.id_material !== this.material.id_material
        ) {
          this.showInAnotherMaterial = true;
        } else {
          this.showInAnotherMaterial = false;
        }
      } catch (error) {
        let errorMessage = error.message;
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }
        swal({
          icon: 'error',
          title: 'Erro',
          text: errorMessage,
          buttons: { confirm: 'Fechar' },
        });
      } finally {
        this.isBusy = false;
      }
    },

    handleHideModal() {
      // quando o é impresso o modal de relatorio o modal de qrCode é escondido pois estava sobrepondo o relatorio
      // esta função apenas retorna o modal do qrCode
      document.getElementById('qrCodePrinter').style.display = 'inline-block';
    },

    async getIdCiclos() {
      const routeRastreabilidade = `material/rastreabilidade/${this.id_material}`;
      this.loading = true;
      this.ciclos = await GenericApi.getWithoutPagination(
        {},
        routeRastreabilidade,
      );
    },
    async getProcessosByOrder() {
      try {
        const routeProcessos = `ciclo/processosByCiclo/${this.id_material}`;
        this.currentCiclo = await GenericApi.getWithoutPagination(
          { filters: { order: this.currentOrder } },
          routeProcessos,
        );
        this.loading = false;
      } catch (erro) {
        console.log(erro);
        this.loading = true;
      }
    },
    async changeCiclo(addOrder) {
      const newCiclo = this.currentOrder + addOrder;
      if (newCiclo > this.numberOfCiclos || newCiclo <= 0) {
        return;
      }
      this.currentOrder = newCiclo;
      await this.getProcessosByOrder();
    },

    showRelatorio() {
      this.$refs.modalRelatorio.show();
    },
    async editEsterilizacaoDesinf(item) {
      this.idFormularioEsterilizacaoDesinf = item.formulario[0].id_formulario;
      const formulario = await this.getFormularioData(item.id_processo_ciclo);

      this.modelEsterilizacaoDesinf.lote = formulario.respostas.lote;
      this.modelEsterilizacaoDesinf.id_equipamento = formulario.respostas.id_equipamento;
      this.equipamentoEsterilizacaoFilter = {
        id_unidade: item.unidade && item.unidade.id_unidade,
        esterilizacao: true,
        id_tipo_esterilizacao: formulario.respostas.id_tipo_esterilizacao,
      };
      this.$refs.modalEsterilizacaoDesinf.show();
    },
    getFormularioData(id_processo_ciclo) {
      const routeRelatorio = `formularios/${id_processo_ciclo}/processoCiclo`;
      return GenericApi.getWithoutPagination({}, routeRelatorio);
    },

    async saveEsterilizacaoDesinf() {
      try {
        const validateEquipamento = await this.$validator.validate(
          'equipamento',
        );
        const validateLote = await this.$validator.validate('lote');
        if (validateEquipamento && validateLote) {
          this.isBusy = true;
          await GenericApi.update(
            this.idFormularioEsterilizacaoDesinf,
            this.modelEsterilizacaoDesinf,
            'formularios/esterilizacaoDesinfeccao',
          );

          this.$refs.modalEsterilizacaoDesinf.hide();
        }
      } catch (e) {
        swal({
          title: 'Erro',
          text: 'Erro ao tentar editar lote e/ou equipamento \n Contactar suporte',
          icon: 'error',
          buttons: { confirm: 'Ok' },
        });
      } finally {
        this.isBusy = false;
      }
    },

    async getLabelProcessos(param) {
      const routeProcessos = `ciclo/processosByCiclo/${this.id_material}`;
      const currentCiclo = await GenericApi.getWithoutPagination(
        { filters: { order: this.currentOrder } },
        routeProcessos,
      );

      if (param === 'ultimaArea') {
        return currentCiclo.processoCiclo[currentCiclo.processoCiclo.length - 1]
          .subSetor.nome;
      }
      if (param === 'ultimoProcesso') {
        return currentCiclo.processoCiclo[currentCiclo.processoCiclo.length - 1]
          .tipoProcesso.nome;
      }
      if (param === 'data') {
        return `${
          moment(currentCiclo.processoCiclo[currentCiclo.processoCiclo.length - 1].updatedAt).format('DD/MM/YY')
        } às ${moment(currentCiclo.processoCiclo[currentCiclo.processoCiclo.length - 1].updatedAt).format('HH:mm:ss')}`;
      }

      return '';
    },

    async endCiclo() {
      const { id_material, material, justificativa } = this;
      const {
        lastCiclo,
        id_unidade_patrimonio,
        id_tipo_material,
        kit_temporario,
      } = material;

      if (!this.justificativa) {
        await swal({
          title: 'Erro',
          text: 'Justificativa é obrigatória para encerrar o ciclo!',
          icon: 'error',
          button: 'Continuar...',
        });

        return;
      }

      try {
        const resp = await GenericApi.update(
          lastCiclo.id_ciclo,
          {
            id_material,
            id_unidade_patrimonio,
            id_tipo_material,
            kit_temporario,
            justificativa,
            opts: { clearUnidade: true },
          },
          'forceCiclo/finish',
        );

        await swal({
          title: 'Bom trabalho!',
          text: 'O ciclo foi encerrado com sucesso!',
          icon: 'success',
          button: 'Continuar',
        });
        this.material.lastCiclo.id_lote_saida = resp.id_lote_saida;
        this.currentCiclo.id_lote_saida = resp.id_lote_saida;
        this.hidden();
      } catch (error) {
        let errorMessage;
        if (
          error.response
          && error.response.data
          && error.response.data.error
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        swal({
          title: 'Oops!',
          text:
            errorMessage
            || 'Ocorreu um problema finalizar o ciclo desse material',
          icon: 'error',
          button: 'Continuar',
        });
      }
    },
    showForceEndModal() {
      this.wantsToForceEndCicle = true;
      this.$refs.modalEncerramentoCiclo.show();
    },
    hidden() {
      this.wantsToForceEndCicle = false;
      this.justificativa = '';
      this.$refs.modalEncerramentoCiclo.hide();
    },
    async editEmprestimo(item) {
      let processoCicloEmprestimo = item;
      if (!processoCicloEmprestimo) {
        processoCicloEmprestimo = this.currentCiclo.processoCiclo.find(
          (processo) => processo.tipoProcesso.chave === 'emprestimo',
        );
        // eslint-disable-next-line prefer-destructuring
        if (!processoCicloEmprestimo) {
          [processoCicloEmprestimo] = this.currentCiclo.processoCiclo;
        }
        processoCicloEmprestimo.parametroDestino = this.parametroDestino;
      }
      const { respostas } = processoCicloEmprestimo.formulario[0];
      this.currentProcessoCiclo = processoCicloEmprestimo;
      let { id_unidade_destino } = respostas;
      if (!id_unidade_destino) {
        id_unidade_destino = Number(
          processoCicloEmprestimo.parametroDestino.valor,
        );
      }
      this.modelEmprestimo.id_unidade_emprestimo = id_unidade_destino;
      this.$refs.modalEmprestimo.showModal();
    },
    updateUnidadeEmprestimo(id_unidade_destino) {
      const currentProcessoCicloIndex = this.currentCiclo.processoCiclo.findIndex(
        (processo) => this.currentProcessoCiclo.id_processo_ciclo === processo.id_processo_ciclo,
      );

      if (currentProcessoCicloIndex) {
        this.currentCiclo.processoCiclo[
          currentProcessoCicloIndex
        ].formulario[0].respostas.id_unidade_destino = id_unidade_destino;
      }

      const parametroDestinoIndex = this.currentCiclo.parametrosCiclo.findIndex(
        (parametro) => parametro.chave === 'id_unidade_destino_final',
      );

      if (parametroDestinoIndex !== -1) {
        this.currentCiclo.parametrosCiclo[parametroDestinoIndex].valor = id_unidade_destino;
      }
    },
    validateState(ref) {
      if (!this.$refs[ref]) return null;
      if (this.$refs[ref] && this.$refs[ref].value) return true;
      return false;
    },
    closeInAnotherMaterial() {
      this.showInAnotherMaterial = false;
    },
    async goToMaterial() {
      this.$router.push(
        `/material/rastrear/${this.material.itemAvulso.id_material}`,
      );
    },
    clearHistoryMaterial() {
      this.cicloData = {};
      this.labelsEncerramento = {};
      this.wantsToForceEndCicle = false;
      this.justificativa = '';
      this.uuid = '';
      this.labelQr = '';
      this.ciclos = [];
      this.numberOfCiclos = 0;
      this.currentOrder = 1;
      this.currentCiclo = {};
      this.currentProcessoCiclo = {};
      this.relatorioData = {};
      this.tipoProcesso = '';
    },
  },
};
</script>

<style lang="scss" scoped>
$neutral-color-80: #35384d;

.spinner {
  display: flex;
  margin: auto;
}

.header-rastreabilidade {
  width: 72%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-print-content button {
  margin-top: 2rem;
  float: right;
}

.RangeDatePicker-in-graph {
  position: absolute !important;
  box-shadow: 1px 2px 8px rgba(50, 50, 50, 0.77);
}

.form-control:not() {
  display: block;
  width: 100%;
}

#print-button {
  margin: 0;
  color: #5e627a;
}

#refresh-button {
  color: #5e627a;
}

#print-button:hover {
  color: #5e627a;
  background-color: #f7f7fa;
  border-color: #f7f7fa;
}

#drop-down-button {
  padding: 0;
  padding-left: 0.5rem;
}

.p-right-stepsList {
  padding: 0 !important;
}

.qrcode {
  background-color: white;
  position: fixed;
  right: 0px;
  top: 0;
  bottom: 0px;
  padding: 24px;
}

.tela {
  width: 95% !important;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 4000px) {
  .container {
    max-width: 1750px !important;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1550px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1350px;
  }
}

.status-row {
  padding-top: 20px;
}
@media (min-width: 1500px) {
  .container {
    max-width: 1480px;
  }
}

.status-tag {
  background-color: rgba(46, 155, 229, 0.3);
  color: #0093e5;
  font-weight: 500;
  border-radius: 5px;
  padding: 2px;
  margin-top: 2px;
  text-align: center;
}

.status-tag-warning {
  background-color: rgba(228, 13, 31, 0.719);
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
  width: 80%;
  padding: 2px;
  margin-top: 2px;
}

.font-style-medium {
  font-weight: 600;
  margin-top: 4px;
}

.qr-code-label {
  margin: auto;
  font-style: italic;
  color: #8f8f8f;
}

.printOnly {
  display: none;
}

.p-card {
  padding: 36px;
  margin-top: 20px;
}

.card.invision-card.invision-top {
  border-top: 0 !important;
}

h5 {
  // titulo cabeçalho modal
  font-size: 18px;
  color: $neutral-color-80;
}

button {
  margin: auto;
}

.w-52 {
  width: 95%;
}
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

.tabela {
  background-color: white;
}

.main-content {
  width: 40em;
  border: 2px dashed rgb(178, 176, 215);
  border-radius: 5px;
  padding: 5%;
}

.span-text {
  color: #00daae;
}

.card-in-another-material {
  display: flex;
  justify-content: space-between;
  padding: 21px;
  background: #fff;
  box-shadow: 2px 10px 30px 1px rgba(94, 98, 122, 0.05);
  border-radius: 10px;
  width: 100%;
}
.right-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.textAlt {
  font-size: 60%;
}

.iconAlt {
  width: 100%;
  height: 90%;
}

.buttonAlt {
  background: #fff;
  color: #5e627a;
}

.collapseAlt {
  border: none;
  background: #fff;
  width: 100%;
}

.collapseText {
  color: black;
  font-size: 70%;
  text-align: start;
}

.iconCollap {
  width: 80%;
  color: black;
}
.custom-toggle-rastreabilidade {
  color: #5e627a;
  background-color: #fff;
  height: 40px;
}
.custom-toggle-rastreabilidade:hover {
  color: #35384d;
  background-color: #fff;
}
.refresh-cols {
  display: flex;
  justify-content: flex-end;
}

.barra-skeleton {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.registro {
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  min-width: 65%;
}

.alterarEmprestimo {
  color: #5e627a;
  background-color: #fff;
  padding: 1%;
  min-width: 40%;
}
</style>
