<template>
  <b-container>
    <b-row class="input-p">
      <b-col sm="12" md="12" lg="12" xl="12">
        <b-form-group
          label="Quantidade de peças*"
          label-for="quantidade_pecas"
        >
          <b-form-input
            id="quantidade_pecas"
            type="number"
            :value="currentMaterialModel.quantidade_pecas"
            autocomplete="off"
            class="invision-input"
            name="quantidade_pecas"
            min="1"
            :disabled="isBusy"
            v-validate={betweenFiftyPorcentLessAndGreater:[currentMaterialModel.pecas]}
            :state="validateState('quantidade_pecas')"
            @input="handleChangeQtdPecas"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="
        currentMaterialModel.quantidade_pecas != currentMaterialModel.pecas
        && currentMaterialModel.quantidade_pecas != null
      "
    >
      <b-col sm="12" md="12" lg="12" xl="12">
        <b-form-group label="Justificativa*" label-for="justificativa">
          <b-form-input
            name="justificativa"
            id="justificativa"
            :value="currentMaterialModel.justificativa"
            autocomplete="off"
            class="invision-input"
            :disabled="isBusy"
            v-validate="{ required: true }"
            :state="validateState('justificativa')"
            @input="handleChangeJustificativa"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="12" lg="12" xl="12">
        <YesOrNoRadio
          name="pecas_defeituosas"
          id="pecas_defeituosas"
          label="Presença de peças danificadas"
          :value="currentMaterialModel.pecas_defeituosas"
          :disabled="isBusy"
          v-validate="{ required: true }"
          :state="validateState('pecas_defeituosas')"
          @input="v => updateCurrentMaterialModel('pecas_defeituosas', v)"
        />
      </b-col>
      <b-container class="sub-form" v-if="currentMaterialModel.pecas_defeituosas">
        <b-row>
          <b-col
            v-if="
              currentMaterialModel.pecas_defeituosas
            "
            sm="24"
            md="12"
            lg="12"
            xl="12"
          >
            <b-form-group
              label="Quantidade de peças danificadas*"
              label-for="qtd_pecas_defeituosas"
            >
              <b-form-input
                id="qtd_pecas_defeituosas"
                type="number"
                :value="currentMaterialModel.qtd_pecas_defeituosas"
                autocomplete="off"
                class="invision-input"
                name="qtd_pecas_defeituosas"
                :disabled="isBusy"
                min="1"
                v-validate="
                  `required|between:1,${currentMaterialModel.pecas}`
                "
                :state="validateState('qtd_pecas_defeituosas')"
                @input="handleChangeQtdPecasDefeituosas"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="24" md="12" lg="12" xl="12">
            <!-- nivela o tamanho dos inputs para que sejam iguais-->
            <b-form-group
              :label="
                currentMaterialModel.qtd_pecas_defeituosas > 1
                  ? 'Descrição*'
                  : 'Descrição*'
              "
              label-for="descricao_materiais_defeituosos"
            >
              <GenericSelect
                v-for="(item, index) in currentMaterialModel.ids_defeitos"
                :key="index"
                class="invision-select mt-1"
                :name="`descricao_pecas_defeituosas-${index}`"
                autocomplete="off"
                maxlength="500"
                labelKey="nome"
                :value="currentMaterialModel.ids_defeitos[index]"
                route="tipoDefeitoMaterial"
                :disabled="isBusy"
                v-validate="{
                  required: currentMaterialModel.pecas_defeituosas
                    ? true
                    : false,
                }"
                :state="validateState(`descricao_pecas_defeituosas-${index}`)"
                @input="
                  (v) => inputCurrentMaterialArray('ids_defeitos', index, v)
                "
              />
              <Button
                v-if="
                  currentMaterialModel.qtd_pecas_defeituosas > 1 &&
                  currentMaterialModel.ids_defeitos.length <
                  currentMaterialModel.qtd_pecas_defeituosas
                "
                text="Adicionar Descrição"
                variant="secondary"
                class="mt-3"
                @click="addDescricaoDefeitos"
                :disabled="isBusy"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
  </b-container>
</template>

<script>
import YesOrNoRadio from '../../../../../front-end/src/components/Utils/YesOrNoRadio';
import GenericSelect from '../../../../../front-end/src/components/Form/GenericSelect';
import Button from '../../../../../front-end/src/components/Utils/Button';

export default {
  name: "IntegridadeMaterialGenerico",
  inject: ['updateCurrentMaterialModel', 'parentValidator'],
  components: {
    YesOrNoRadio,
    GenericSelect,
    Button
  },
  data() {
    return {}
  },
  props: {
    currentMaterialModel: {
      type: Object,
      required: true
    },
    isBusy: {
      type: Boolean,
      required: true
    },
    validateState: {
      type: Function,
      required: true
    }
  },
  created() {
    this.$validator = this.parentValidator
  },
  methods: {
    handleChangeQtdPecasDefeituosas(value) {
      if (value < this.currentMaterialModel.qtd_pecas_defeituosas) {
        this.updateCurrentMaterialModel(
          'ids_defeitos',
          value <= 1
            ? [null]
            : this.currentMaterialModel.ids_defeitos.slice(0, value),
        );
      }
      this.updateCurrentMaterialModel(
        'qtd_pecas_defeituosas',
        value,
      );
    },
    addDescricaoDefeitos() {
      this.updateCurrentMaterialModel('ids_defeitos', [
        ...this.currentMaterialModel.ids_defeitos,
        null,
      ]);
      // this.currentMaterialModel.ids_defeitos.push(null);
      // this.$forceUpdate();
    },
    inputCurrentMaterialArray(prop, index, value) {
      this.updateCurrentMaterialModel(prop, [
        ...this.currentMaterialModel[prop].slice(0, index),
        value,
        ...this.currentMaterialModel[prop].slice(index + 1),
      ]);
    },
    handleChangeJustificativa(value) {
      this.updateCurrentMaterialModel('justificativa', value)
    },
    handleChangeQtdPecas(value) {
      this.updateCurrentMaterialModel('quantidade_pecas', value)
      if(this.currentMaterialModel.quantidade_pecas === Number(value))  {
        this.updateCurrentMaterialModel('justificativa', '')
      }
    }
  },
  watch: {}
}
</script>

<style scoped>

</style>
