<template>
    <div class="container">
        <slot name="header" />
        <b-row class="">
            <b-col
              :sm="sm"
              :md="md"
              :lg="lg"
              :xl="xl"
              class="pl-3 p-right-stepsList"
            > <!--pl-3 alinha o container do StepsList com o titulo-->
                <slot name="one" />
            </b-col>
            <b-col class="px-0"
                :sm="sm == 12 ? 12 : 12 - sm"
                :md="md == 12 ? 12 : 12 - md"
                :lg="lg == 12 ? 12 : 12 - lg"
                :xl="xl == 12 ? 12 : 12 - xl"
            >
                <slot name="two" />
            </b-col>
        </b-row>
    </div>
</template>
<script>

export default {
  props: {
    sm: {
      type: String,
      default: '12',
    },
    md: {
      type: String,
      default: '6',
    },
    lg: {
      type: String,
      default: '6',
    },
    xl: {
      type: String,
      default: '6',
    },
  },
};
</script>
<style scoped>
main {
    transition: all ease .3s;
}

.p-right-stepsList {
    padding-right: 36px;
    padding-left: inherit !important;

}
</style>
