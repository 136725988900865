export default {
  enableMaterialRede: true,
  customButtonPages: [2],
  showBannerPages: [],
  underListPages: [],
  tabelaMateriaisPage: null,
  needProtocoloRecebimento: true,
  ensureCurrentItemQuantity: true,
  tipoProtocolo: 'transferencia',
  modelProcesso: {
    id_unidade_origem: null,
    id_setor_origem: null,
  },
  currentMaterialModelProcesso: {},
  pageList: [
    {
      title: 'Leitura do material',
      subItems: [
        { title: 'Validação dos materiais' },
      ],
    },
  ],
};
