<template>
  <main class="container padding">
    <div class="header">
      <label class="title">Protocolo de recebimento/transferência de materiais no expurgo</label>
      <img src="/logo.png" alt="" class="modal-logo" width="150px"/>
    </div>
    <div class="w">
      <b-row>
        <b-col>
          <label>N° Protocolo:</label>
          <br />
          <div class="info">
            {{ relatorioData.siclaProtocolo || "-" }}
          </div>
          <br />

          <label>Unidade:</label>
          <br />
          <div class="info">
            {{ relatorioData ? relatorioData.unidade.nome : "-" }}
          </div>
          <br />

          <label>Unidade Destino:</label>
          <br />
          <div class="info">
            {{ relatorioData ? relatorioData.unidadeDestino : "-" }}
          </div>
          <br />
        </b-col>

        <b-col>
          <label>Data/Hora:</label>
          <div class="info">
            {{ this.getDateTime(relatorioData.dateTime) }}
          </div>
          <br />

          <label>Setor de Origem:</label>
          <div class="info">
            {{ relatorioData ? relatorioData.setorOrigem : "-" }}
          </div>
          <br />

          <label>Setor Destino:</label>
          <div class="info">
            CME
          </div>
          <br />
        </b-col>

        <b-col >
          <label>Responsável CME:</label>
          <div class="info">
            {{ relatorioData ? relatorioData.usuario.nome : "-" }}
          </div>
          <br />
          <label v-if="relatorioData.responsavel_setor">Responsável Setor:</label>
          <div v-if="relatorioData.responsavel_setor" class="info">
            {{ relatorioData ? relatorioData.responsavel_setor : "-" }}
          </div>
          <br />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="div-assinaturas">
            <span>Colaborador CME: </span>
            <div class="underline"></div>
          </div>
          <br />
        </b-col>
      </b-row>
    </div>
    <div>
      <table class="table">
        <thead>
          <tr>
            <th scope="col" style="font-size: 14px">#</th>
            <th scope="col" style="font-size: 14px">Tipo de artigo</th>
            <th scope="col" style="font-size: 14px">ID</th>
            <th scope="col" style="font-size: 14px">Descrição</th>
            <th scope="col" style="font-size: 14px">Qde peças cadastradas</th>
            <th scope="col" style="font-size: 14px">Pertence a</th>
            <th v-if="hasMaterialGenerico" scope="col" style="font-size: 14px">Qtd</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in relatorioData.materiais" v-bind:key="index">
            <th scope="row">{{ index + 1}}</th>
            <td>{{ item.tipoMaterial || item.tipoMaterialGenerico || '-' }}</td>
            <td>{{ item.shownId || '-' }}</td>
            <td>{{ item.descricao || '-' }}</td>
            <td>{{ item.quantidade_processada_itens || item.quantidade_atual_itens || item.quantidade_itens || item.pecas || '-' }}</td>
            <td>{{ item.pertenceA }}</td>
            <td v-if="hasMaterialGenerico" >{{ item.qt_material_generico || '-' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </main>
</template>

<script>
import moment from 'moment';

export default {
  name: 'RelatorioRecebimentoTransferencia',
  components: {},
  props: {
    relatorioData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    hasMaterialGenerico() {
      return this.relatorioData.materiais.some((mat) => mat.qt_material_generico);
    },
  },
  methods: {
    getDateTime(dateTime) {
      return moment(dateTime).local().format('DD/MM/YYYY HH:mm:ss');
    },
  },
};
</script>

<style scoped>
* {
  font-weight: 400;
  font-size: 16px;
}

.padding {
  padding: 36px;
  font-weight: 400;
}

label {
  margin-bottom: 3px;
}

.info {
  margin-bottom: 12px;
}

.underline {
  flex-grow: 1;
  /* width: 100%; */
  border-bottom: 1px solid black;
  margin-left: 5px;
  margin: 0 10px 4px 5px;
}

.div-assinaturas {
  display: flex;
  margin-bottom: 10px;
}

.w h2 {
  font-size: 16px;
  font-weight: 600;
}

.modal-header {
  border-bottom: 0;
}

.header .modal-logo {
  position: absolute;
  right: 20px;
  top: 20px;
  margin-top: 10px;
}

.header .title {
  font-weight: 500;
  font-size: 18px;
  margin-top: -30px;
  margin-bottom: 40px;
  margin-left: -10px;
}

</style>
