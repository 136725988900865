export default {
  customButtonPages: [3],
  showBannerPages: [],
  bannerType: 'preparo',
  underListPages: [],
  tabelaMateriaisPage: null,
  needProtocoloRecebimento: true,
  materiaisMustHaveSameQuantityPecas: true,
  materiaisMustBeSameTipo: true,
  allowFetchMateriaisWithoutProtocolo: true,
  tipoProtocolo: 'recebimento',
  modelProcesso: {
    id_tipo_embalagem: null,
    integrador_quimico: null,
    quantidade_pecas: null,
    lavagem_automatizada: null,
    numProtocolosRecebimento: []
  },
  currentMaterialModelProcesso: {},
  showCard: true,
  pageToShowCard: 3,
  cardTitle: 'Limpeza automatizada',
  pageList: [
    {
      title: 'Preparo',
      subItems: [
        { title: 'Informações do preparo' },
        { title: 'Leitura do material' },
      ],
    },
  ],
};
