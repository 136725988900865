<template>
  <div class="mb-4">
    <b-row>
      <b-col sm="6" md="6" lg="6" xl="6">
        <b-form-group>
          <YesOrNoRadio
            name="limpeza_automatizada"
            id="limpeza_automatizada"
            label="Limpeza Automatizada"
            :value="dataModel.lavagem_automatizada"
            :disabled="isBusy"
            v-validate="{ required: true }"
            :state="validateState('limpeza_automatizada')"
            @input="handleChangeLavagemAutomatizada"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row
      class="sub-form"
      v-if="dataModel.lavagem_automatizada"
    >
      <b-col sm="12" md="6" lg="6" xl="6">
        <b-form-group
          label="Nome do Colaborador*"
          label-for="colaborador_lavagem_automatizada"
        >
          <br v-if="!saveDataInModel" />
          <b-form-input
            id="colaborador_lavagem_automatizada"
            :value="dataModel.colaborador_lavagem_automatizada"
            autocomplete="off"
            class="invision-input"
            name="colaborador_lavagem_automatizada"
            :disabled="isBusy"
            v-validate="{ required: true }"
            :state="validateState('colaborador_lavagem_automatizada')"
            @input="v => handleChangeField('colaborador_lavagem_automatizada', v)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" lg="6" xl="6">
        <b-form-group
          label="Lote do detergente enzimático*"
          label-for="lote_detergente_lavagem_automatizada"
        >
          <b-form-input
            id="lote_detergente_lavagem_automatizada"
            :value="dataModel.lote_detergente_lavagem_automatizada"
            autocomplete="off"
            class="invision-input"
            name="lote_detergente_lavagem_automatizada"
            :disabled="true"
            placeholder="Selecione o equipamento"
            v-validate="{ required: true }"
            :state="validateState('lote_detergente_lavagem_automatizada')"
            @input="v => handleChangeField('lote_detergente_lavagem_automatizada', v)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="12" lg="12" xl="12">
        <b-form-group
          label="Equipamento de lavagem automatizada*"
          label-for="metodo_lavagem_automatizada"
        >
          <GenericSelect
            name="metodo_lavagem_automatizada"
            id="metodo_lavagem_automatizada"
            ref="metodo_lavagem_automatizada"
            labelKey="nome"
            :value="dataModel.id_equipamento_lavagem_automatizada"
            :customFilters="metodoLavagemAutomatizadaFilters"
            route="equipamento"
            :disabled="isBusy"
            :state="validateState('metodo_lavagem_automatizada')"
            v-validate="{ required: true }"
            @input="updateEquipamentoAndLote"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="dataModel.lavagem_automatizada === false">
      <b-col>
        <b-form-checkbox
          :checked="dataModel.naoFazerAutomatizada"
          @input="v => handleChangeField('naoFazerAutomatizada', v)"
          :state="dataModel.naoFazerAutomatizada"
          v-validate="'hasToBeTrue'"
        >
          Desejo finalizar o processo sem registro de limpeza automatizada
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import GenericSelect from '../../../../../front-end/src/components/Form/GenericSelect';
import YesOrNoRadio from '../../../../../front-end/src/components/Utils/YesOrNoRadio';

export default {
  components: {
    GenericSelect,
    YesOrNoRadio,
  },
  inject: ['parentValidator', 'validateState', 'updateCurrentMaterialModel', 'updateModel'],
  created() {
    this.$validator = this.parentValidator;
  },
  props: {
    currentMaterialModel: {
      type: Object,
    },
    model: {
      type: Object,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState(['currentUnidade']),
    metodoLavagemAutomatizadaFilters() {
      return { id_unidade: this.currentUnidade.id_unidade, lavagem_automatizada: true };
    },
    saveDataInModel() {
      if (this.model) return true;
      return false;
    },
    dataModel() {
      if (this.saveDataInModel) {
        return this.model;
      }
      return this.currentMaterialModel;
    },
  },
  methods: {
    handleChangeLavagemAutomatizada(val) {
      this.handleChangeField(
        ['lavagem_automatizada', 'naoFazerAutomatizada'],
        [val, false],
      );
    },
    updateEquipamentoAndLote(id_equipamento) {
      const equipamento = this.$refs.metodo_lavagem_automatizada.apiResponse.find(
        (item) => item.id_equipamento === id_equipamento,
      );
      let loteDetergente = '';
      if (equipamento) {
        const { lote_detergente } = equipamento.loteDetergente;
        loteDetergente = lote_detergente;
      }
      this.handleChangeField(
        ['id_equipamento_lavagem_automatizada', 'lote_detergente_lavagem_automatizada', 'nomeEquipamento'],
        [id_equipamento, loteDetergente, equipamento.nome],
      );
    },
    handleChangeField(field, value) {
      if (this.saveDataInModel) {
        this.updateModel(field, value);
      } else {
        this.updateCurrentMaterialModel(field, value);
      }
    },
  },
};
</script>
