export default {
  customButtonPages: [2],
  showBannerPages: [],
  underListPages: [],
  tabelaMateriaisPage: null,
  modelProcesso: {
    id_unidade_destino: null,
    id_setor_destino: null,
  },
  currentMaterialModelProcesso: {},
  pageList: [
    {
      title: 'Informações da saída',
      subItems: [
        { title: 'Destino dos materiais' },
      ],
    },
  ],
};
