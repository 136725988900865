<template>
  <div>
    <div v-if="currentPage === 2">
      <UnidadeSetorDestino
        :model="model"
        destinoSetorCme
      />
      <LeituraMateriais
        :isBusy="isBusy"
        :model="model"
      />
    </div>
  </div>
</template>

<script>
import UnidadeSetorDestino from '../../Components/UnidadeSetorDestino';
import LeituraMateriais from '../../Components/LeituraMateriais';

export default {
  name: 'RetornoRecebimento',
  components: {
    UnidadeSetorDestino,
    LeituraMateriais,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    numberOfPages: {
      type: Number,
      required: true,
    },
  },
};
</script>
