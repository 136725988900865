export default {
  enableMaterialRede: true,
  addOrigemDataOnReading: true,
  tipoProtocolo: 'transferência',
  manyProtocolosRecebimento: false,
  customButtonPages: [3],
  showPicsAfterRead: true,
  ensureCurrentItemQuantity: true,
  validLengthInputText: (function () {
    const min = 3
    return {
      min,
      typeValidation: 'alpha_spaces',
      message: `Deve inserir o nome do responsável (deve ter mínimo ${min} letras, não deve começar com caracteres especiais nem espaços ou números)`
    }
  })(),
  modelProcesso: {
    id_unidade_origem: null,
    id_setor_origem: null,
    responsavel_setor: '',
    voucherTransferencia: '',
    numProtocolosTransferencia: []
  },
  currentMaterialModelProcesso: {
    materiais_defeituosos: false,
    ids_defeitos: [null],
    perfuro_cortante: false,
    ids_perfuros_cortantes: [null],
    desincrustante: true,
    quantidade_pecas_recebimento_anterior: null,
    justificativa: '',
    quantidade_pecas: null,
    observacao_reposicao_pecas: null,
    quantidade_pecas_repostas: null,
    lastComentario: null,
    usuarioComentario: null,
    unidadeComentario: null,
  },
  pageList: [
    {
      title: 'Leitura dos materiais',
      subItems: [
        { title: 'Origem do material' },
        { title: 'Validar QR Code' },
      ],
    },
  ],
};
