<template>
  <div>
    <div v-if="currentPage === 2">
      <UnidadeSetorOrigem
        :model="model"
        :isBusy="isBusy"
        :unidadeOrigemSelf="currentUnidade.unidadeFilho.length === 0"
        needResponsavel
        :config="config"
      />
      <InputProtocolos
        v-if="model.id_unidade_origem && unidadeOrigemDifUnidade"
        :model="model"
        :currentComponent="{data: {...config}}"
        :multi="false"
        modelKeyProtocolo="numProtocolosTransferencia"
      />
    </div>
    <div v-else-if="currentPage === 3">
      <LeituraMateriais
        :model="model"
        :isBusy="isBusy"
        sendToModel
        withoutTitle
        detalheMaterial
        hasIntegridade
        hasOptions
        :config="config"
        enableReimprimirEtiqueta
        :disableInitFocus="false"
        :setIsIntegridade="setIsIntegridade"
        :setIsGeraProcedimento="setIsGeraProcedimento"
        :beforeFinish="[
          {
            condition: materialNeedItemVerification,
            fn: checkAllCheckboxesBeforeFinish,
          },
        ]"
      >
        <template #tabela v-if="model.numProtocolosTransferencia && model.numProtocolosTransferencia.length">
          <TabelaMateriaisProtocolo
            :model="model"
            :currentMaterialModel="currentMaterialModel"
            :columns="['Descrição', 'ID', 'Peças', 'Qtd']"
            :columnsKeys="['descricao', 'shownId', 'pecas', 'qt_material_generico']"
            :isBusy="isBusy"
            :materiaisByProtocolos="materiaisByProtocolos"
            enableReimprimirEtiqueta
            :enableEditInformacoes="false"
            :config="config"
            :enableIntegridade="true"
          />
        </template>
        <template #modal>
          <ModalProcesso
            :title="modalTitle"
            :currentMaterialModel="currentMaterialModel"
            :config="config"
            :validationFields="['justificativa']"
            :setIsIntegridade="setIsIntegridade"
            :setIsGeraProcedimento="setIsGeraProcedimento"
            :beforeConfirm="[
              { condition: currentMaterialModel.conferencia_pecas, fn: () => {
                const { current } = verifyAllChecked()
                return current
              }},
              { condition: currentMaterialModel && geraProcedimento && !isIntegridade, fn: validateQtdeMaterialProcedimento}              
            ]"
          >
            <template #processo >
              <Procedimento
                v-if="currentMaterialModel && geraProcedimento && !isIntegridade"
                :model="model"
                :currentMaterialModel="currentMaterialModel"
                :isBusy="isBusy"
                :validateState="validateState"
              />
              <Integridade
                v-else-if="currentMaterialModel
                  &&
                  Object.keys(currentMaterialModel).length
                  &&
                  !currentMaterialModel.hasOwnProperty('tipoMaterialGenerico')
                  "
                :currentMaterialModel="currentMaterialModel"
                :isBusy="isBusy"
                :validateState="validateState"
                :isFromTransferencia="Boolean(model.protocoloTransferencia)"
                :hideDesincrustante="hideModalField"
                :hidePerfuroCortante="hideModalField"
                :hideQuantidadePecas="hideModalField"
              >
                <template #pecas
                  v-if="currentMaterialModel.pecas"
                >
                  <ItemCheck 
                    marginBottom="20"
                    :validateState="validateState"
                    :currentMaterialModel="currentMaterialModel"
                    :model="model"
                  />
                </template>
              </Integridade>
              <IntegridadeMaterialGenerico
                v-else
                :isBusy="isBusy"
                :validateState="validateState"
                :currentMaterialModel="currentMaterialModel"
              />
            </template>
          </ModalProcesso>
        </template>
      </LeituraMateriais>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Integridade from '../../Components/Integridade';
import IntegridadeMaterialGenerico from '../../Components/IntegridadeMaterialGenerico';
import UnidadeSetorOrigem from '../../Components/UnidadeSetorOrigem';
import NaoConformidadeInox from '../../Components/NaoConformidadeInox';
import LeituraMateriais from '../../Components/LeituraMateriais'
import TabelaMateriaisProtocolo from '../../Components/TabelaMateriaisProtocolo'
import config from './config';
import ModalProcesso from '../../Components/ModalProcesso'
import Procedimento from '../../Components/Procedimento'
import InputProtocolos from '../../Components/InputProtocolos'
import ItemCheck from '../../Components/ItemCheck'

export default {
  inject: [
    'materialNeedItemVerification',
    'verifyAllChecked',
    'parentValidator',
    'updateModel',
    'setCurrentMaterialFromModel',
    'getMateriaisByProtocolo',
    'validateState',
    'preserveCurrentMaterialModel',
  ],
  created() {
    this.$validator = this.parentValidator;
  },
  components: {
    ItemCheck,
    UnidadeSetorOrigem,
    Integridade,
    IntegridadeMaterialGenerico,
    NaoConformidadeInox,
    Procedimento,
    LeituraMateriais,
    TabelaMateriaisProtocolo,
    ModalProcesso,
    InputProtocolos
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    currentMaterialModel: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    numberOfPages: {
      type: Number,
      required: true,
    },
    materiaisByProtocolos: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      config,
      voucher: '',
      materiaisFromVoucher: [],
      selectedVoucherMaterial: {},
      id_tipo_processo: 1,
      lastGeneratedVoucher: {},
      modalTitle: '',
      isIntegridade: false,
      hideModalField: false,
      geraProcedimento: false,
    };
  },

  computed: {
    ...mapState(['currentUnidade']),
    anyMaterialWithItemVerification() {
      return this.model.materiais.some(material => material.conferencia_pecas)
    },
    materiaisLength() {
      return this.model.materiais.length;
    },
    unidadeOrigemDifUnidade() {
      return this.model.id_unidade !== this.model.id_unidade_origem;
    },
    hasFieldNaoConformidades() {
      return this.currentMaterialModel.id_tipo_material === 7;
    },
    nextPageAfterConfirm() {
      if (this.currentMaterialModel.needProcedimento) return 6;
      return 7;
    },
  },

  watch: {
    verifiedItems(verifiedItems) {
      const existsAndHasLength = verifiedItems && verifiedItems.length
      if(existsAndHasLength) {
        this.currentMaterialModel.verified_items = verifiedItems
      }
    },
    currentMaterialModel(currentMaterialModel) {
      if(currentMaterialModel.conferencia_pecas) {
        return this.hideModalField = true
      } 
      this.hideModalField = false
    },
    isIntegridade(value) {
      if(value)
        this.modalTitle = 'Integridade do Material'
      else
        this.modalTitle = 'Informações do procedimento'
    },
    async currentPage(newPage) {
      if (newPage === 3) {
        if (this.model.numProtocolosTransferencia && this.model.numProtocolosTransferencia.length) {
          await this.getMateriaisByProtocolo(this.model.numProtocolosTransferencia, {
            processoCheck: 'transferenciaExpurgo',
            ignoreValidationProtocolo: true,
            validateFluxo: true,
            sub_setor: this.model.sub_setor,
            ignoreValidationResults: true,  
            id_unidade: this.model.id_unidade, 
          });
        }
      }
      if(newPage < 3) {
        this.preserveCurrentMaterialModel({})
      }
    },
  },

  methods: {
    async validateQtdeMaterialProcedimento(){
      const valid = await this.$validator.validate('numero_pecas')
      return valid
    },
    checkAllCheckboxesBeforeFinish() {
      const { all } = this.verifyAllChecked()
      if (all) {
        return true;
      }
      swal({
        title: 'Checagem de peças pendente',
        icon: "error",
        text: "Por favor faça a checagem de peças para poder continuar.",
        button: { confirm: "Ok..." },
      });
      return false
    },
    setAllCurrentChecked(allCurrentChecked) {
      this.allCurrentChecked = allCurrentChecked
    },
    setIsIntegridade(value) {
      this.isIntegridade = value
    },

    setIsGeraProcedimento(value) {
      this.geraProcedimento = value;      
    },

    customOnDetection(newMaterial) {
      const setorOrigem = this.currentUnidade.setor.find(setor => setor.id_setor === this.model.id_setor_origem)
      this.geraProcedimento = false;
            console.log('newMaterial: ', newMaterial, ' SetorOrigem: ', setorOrigem)
      if (newMaterial.tipoMaterial.gera_procedimento && setorOrigem && setorOrigem.gera_procedimento) {
        this.geraProcedimento = true;
        newMaterial.needProcedimento = true;
      }
      if(!newMaterial.conferencia_pecas) {
        newMaterial.quantidade_pecas = newMaterial.quantidade_itens;
      }
    },
    customChooseMaterialGenerico(newMaterialGenerico) {
      newMaterialGenerico.quantidade_pecas = newMaterialGenerico.pecas;
      return;
    },
    goToIntegridade(index) {
      if (typeof index === 'number') {
        this.setCurrentMaterialFromModel(index);
      }
    },
  },
};
</script>
