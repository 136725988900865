export default {
  enableMaterialRede: true,
  customButtonPages: [2],
  showBannerPages: [],
  underListPages: [],
  tabelaMateriaisPage: null,
  needProtocoloRecebimento: true,
  manyProtocolosRecebimento: true,
  tipoProtocolo: 'recebimento',
  allowFetchMateriaisWithoutProtocolo: true,
  sendProcessoCicloRecebimento: true,
  modelProcesso: {
    numProtocolosRecebimento: [],
    id_tipo_esterilizacao: null,
    tipoEsterilizacao: '',
    id_equipamento: null,
    lote: '',
    id_tipo_teste: null,
    colaborador_termodesinfeccao: '',
    validade: '',
    lote_esterilizante: '',
    validade_esterilizante: '',
    ciclo_urgencia: false,
  },
  currentMaterialModelProcesso: {},
  pageList: [
    {
      title: 'Informações Esterilização',
      subItems: [{ title: 'Informações da Esterilização / Desinfecção' }],
    },
  ],
};
